/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC),
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

import { BrowserCacheLocation } from '@azure/msal-browser'

/* This file is used to configure Authentication configuration detail like - clientId, authority, redirectURL, cache and scope. This will be used in MsalAuthenticationTemplate
 */
export const msalConfig = {
  auth: {
    clientId: '',
    signInPolicy: '',
    scopes: ['openid'],
    authority: '',
    redirectUri: '',
    validateAuthority: false,
    knownAuthorities: [''],
  },
  cache: {
    cacheLocation: BrowserCacheLocation?.localStorage, // This configures where our cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if we are having issues on IE11 or Edge
  },
  system: {
    iframeHashTimeout: 60000,
  },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['openid'], //['User.ReadWrite'],
  loginHint: '',
}
