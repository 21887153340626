/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  This component is used to pass/show loading screen when need to show loader from any component/page
  This component will show the circular loader always open
*/
// This component will be shown from index.js once any route path rendered this will be hidden. Till that this single loader will be shown.
import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { LoaderOnIndexJsAtom } from '../../recoil/atoms/atoms'
import './LoadingComponent.css'

//props, ref
function LoadingComponent() {
  const LoaderOnIndexJs = useRecoilValue(LoaderOnIndexJsAtom)
  return (
    <Backdrop
      id="LoaderOnIndexJS"
      className="loadingControl"
      open={LoaderOnIndexJs}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
export default LoadingComponent
