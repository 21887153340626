/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  This validator function is used to validate the condition from parent component
*/

import './Validator.css'

const validator = {
  isEmail: function isEmail(email) {
    // const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    // const regex = /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/

    if (email === '') {
      return 'EmailAddress is a mandatory please enter the value'
    }
    if (!regex.test(email)) {
      return 'Enter Valid EmailAddress'
    }
    return ''
  },
  isPhonenumber: function isPhonenumber(Phonenumber) {
    const regex = /^[0-9]+$/
    if (Phonenumber === '') {
      return 'Phonenumber is a mandatory please enter the value'
    }
    if (!regex.test(Phonenumber)) {
      return 'PhoneNumber must contain only Numeric characters'
    }
    return ''
  },
  isAlphanumeric: function isAlphanumeric(alphanumeric) {
    //const regex = /^[(A-Za-z0-9)]+$/
    let isNumber = false
    let isAlpha = false
    let isOthers = false
    let charcode = null
    for (let i = 0; i < alphanumeric?.length; i++) {
      charcode = alphanumeric.charCodeAt(i)
      if (charcode > 47 && charcode < 58) {
        isNumber = true
      } else if (
        (charcode > 64 && charcode < 91) ||
        (charcode > 96 && charcode < 123)
      ) {
        isAlpha = true
      } else {
        isOthers = true
      }
    }

    if (
      (isNumber ||
        (isNumber && isAlpha) ||
        (isAlpha && alphanumeric?.length <= 3)) &&
      !isOthers
    ) {
      return true
    } else {
      return false
    }
  },
  isDecimal: function isDecimal(decimalvalue) {
    const regex = /^\d+(\.\d{0,2})?$/
    if (regex.test(decimalvalue)) {
      return true
    } else {
      return false
    }
  },
  extendValidityDays: function extendValidityDays(days) {
    const regex = /^\d+$/
    if (regex.test(days) && days >= 1 && days <= 14) {
      return true
    } else {
      return false
    }
  },
  ishoursminutesformat: function ishoursminutesformat(hooursminutesvalue) {
    const regex = /^(?:[01]\d|2[0123]):(?:[012345]\d)$/
    if (regex.test(hooursminutesvalue)) {
      return true
    } else {
      return false
    }
  },
}

function getValidator(Fieldtype) {
  return validator[Fieldtype]
}

export default getValidator
