/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/*
  This Toast component is used to show any messages with color indication based on status - error, success, warning
  #toastMessage - The atom which will have the text and status of the message, which will be updated by components or pages
  */

// System defined variables
import React, { useState } from 'react' //forwardRef, useImperativeHandle
import { useRecoilValue } from 'recoil'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import parse from 'html-react-parser'
import WarningIcon from '@mui/icons-material/Warning'
import CheckCircle from '@mui/icons-material/CheckCircle'

// Custom defined variables
import { toastMessage } from '../../recoil/atoms/atoms'
import './Toast.css'
import utils from '../../../../utils/utils'

function Toast() {
  const toastContent = useRecoilValue(toastMessage)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(false)
  const [hideIcon, setHideIcon] = useState(false)
  const [actionRequired, setActionRequired] = useState(false)
  const [statusMessage, setStatusMessage] = useState('success')

  //event, reason
  const handleClose = (e, autoclose = null) => {
    /* Commented to follow auto hide snackbar */
    if (
      statusMessage?.toString()?.toLowerCase() === 'success' &&
      !actionRequired
    ) {
      setOpen(false)
    } else {
      if (autoclose) {
        setOpen(false)
      } else {
        return
      }
    }
  }

  React.useEffect(() => {
    if (toastContent?.message) {
      // generate message based on message content in toastMessage atom
      setOpen(true)
      let toastMessageText = ''
      let errStatus = toastContent?.status
      let hideToastIcon = toastContent?.hideIcon
      let toastActionRequired = toastContent?.actionRequired
      // generate message with break tag beased on type of toastMessage atom
      if (
        toastContent?.message &&
        typeof toastContent?.message === 'object' &&
        Array.isArray(toastContent.message)
      ) {
        const messageGenerated = generateMessage(
          toastContent?.message,
          toastContent?.status
        )
        toastMessageText = messageGenerated.toastMessageText
        errStatus = messageGenerated.errStatus
      } else if (
        toastContent?.message &&
        typeof toastContent?.message === 'object'
      ) {
        const errorMessageObject = Object.entries(toastContent?.message)
        errorMessageObject.forEach((err) => {
          toastMessageText +=
            err[1] === null ||
            err[1] === undefined ||
            err[1]?.toString()?.toLowerCase() === 'undefined'
              ? ''
              : `<span>${err[1]}</span><br />`
        })
      } else {
        toastMessageText = toastContent?.message
      }
      setStatusMessage(errStatus)
      setMessage(toastMessageText)
      setHideIcon(hideToastIcon)
      setActionRequired(toastActionRequired)
    }
  }, [toastContent])

  // generate message content with br tag to render array type of messages
  const generateMessage = (messageList, status) => {
    let toastMessageText = ''
    const errStatus = status

    messageList?.forEach((item, index) => {
      toastMessageText +=
        item === null ||
        item === undefined ||
        item?.toString()?.toLowerCase() === 'undefined'
          ? ''
          : `<span>${item}</span><br />`
      if (index !== messageList?.length - 1) toastMessageText += `<br/>`
    })
    return { toastMessageText, errStatus }
  }

  return (
    <>
      {message ? (
        <Snackbar
          id="Mui_Alert"
          data-testid="test_toast"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          className={`toast-root ${statusMessage}`}
          open={open}
          autoHideDuration={
            statusMessage?.toString()?.toLowerCase() === 'success' &&
            !actionRequired
              ? utils?.toastAutoHideDuration
              : null
          }
          onClose={(e) => handleClose(e)}
        >
          {statusMessage?.toString()?.toLowerCase() === 'success' &&
          !actionRequired ? (
            <Alert
              elevation={6}
              className={`alert ${statusMessage}`}
              variant="outlined"
              severity={statusMessage}
              iconMapping={{
                error: hideIcon ? <></> : <WarningIcon />,
                success: hideIcon ? <></> : <CheckCircle />,
              }}
            >
              {message && parse(message)}
            </Alert>
          ) : (
            <Alert
              onClose={(e) => handleClose(e, true)}
              elevation={6}
              className={`alert ${statusMessage}`}
              variant="outlined"
              severity={statusMessage}
              iconMapping={{
                error: hideIcon ? <></> : <WarningIcon />,
                success: hideIcon ? <></> : <CheckCircle />,
              }}
            >
              {message && parse(message)}
            </Alert>
          )}
        </Snackbar>
      ) : (
        <></>
      )}
    </>
  )
}

export default Toast
