/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

// This component is used to get application active/inactive status.
//This will be called from App.js once successfully logged in

import React, { useRef, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { userWentInactiveAtom } from '../../recoil/atoms/atoms'

//props, ref
function IdleStateDetector({ delay, onIdle, onActive }) {
  const setUserWentInactive = useSetRecoilState(userWentInactiveAtom)
  const timeoutId = useRef()
  useEffect(() => {
    setup()

    return () => {
      cleanUp()
    }
  }, [])

  const setup = () => {
    document.addEventListener('mousemove', resetTimer, false)
    document.addEventListener('mousedown', resetTimer, false)
    document.addEventListener('keypress', resetTimer, false)
    document.addEventListener('DOMMouseScroll', resetTimer, false)
    document.addEventListener('mousewheel', resetTimer, false)
    document.addEventListener('touchmove', resetTimer, false)
    document.addEventListener('MSPointerMove', resetTimer, false)

    //edge case
    //if tab is changed or is out of focus
    window.addEventListener('blur', startTimer, false)
    window.addEventListener('focus', resetTimer, false)

    //start the timer
    startTimer()
  }

  const cleanUp = () => {
    document.removeEventListener('mousemove', resetTimer)
    document.removeEventListener('mousedown', resetTimer)
    document.removeEventListener('keypress', resetTimer)
    document.removeEventListener('DOMMouseScroll', resetTimer)
    document.removeEventListener('mousewheel', resetTimer)
    document.removeEventListener('touchmove', resetTimer)
    document.removeEventListener('MSPointerMove', resetTimer)

    //edge case
    //if tab is changed or is out of focus
    window.removeEventListener('blur', startTimer)
    window.removeEventListener('focus', resetTimer)

    // memory leak
    clearTimeout(timeoutId.current)
  }

  const startTimer = () => {
    // wait till delay time before calling wentInactive
    timeoutId.current = setTimeout(wentInactive, delay)
  }

  const resetTimer = () => {
    // reset the counter and make user go active
    clearTimeout(timeoutId.current)
    itIsActive()
  }

  const wentInactive = () => {
    setUserWentInactive(true)
    onIdle && onIdle()

    //optional if you want to start the idle detector again
    //resetTimer()
  }

  const itIsActive = () => {
    // do something
    setUserWentInactive(false)
    onActive && onActive()
    startTimer()
  }

  return null
}
export default IdleStateDetector
