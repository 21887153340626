/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/*
  This page is the splash screen, which will be loaded as the initial screen of application
  In this Screen only the MSAL Provider will be loaded as parent of all components
  props : appConfig - contains data of application configuration like BackOfficeAPIURL, BackOfficeUIURL, Tenant and client
        : msalConfig - contains data of replaced client, tenant, redirectURL, and AD-Login URL
*/

// System defined variables
import React, { useState, useEffect } from 'react'
import { MsalProvider } from '@azure/msal-react'
import { useRecoilValue, useRecoilState } from 'recoil'

// Custom defined variables
import { fetchDataSelector } from '../views/components/recoil/selectors/selectors'
import APIEndPoints from '../models/api/apiEndpoints'
import App from '../app/App'
import { invalidDomainAtom } from '../views/components/recoil/atoms/atoms'
import { msalPolicyNameAtom } from '../views/components/recoil/atoms/atomsWithLocalStorage'

import utils from '../utils/utils'
import InvalidDomainPage from './InvalidDomainPage'

function GetUserFlow({ userName, msalInstance }) {
  const [msalPolicyName, setMsalPolicyName] = useRecoilState(msalPolicyNameAtom)
  const getCoreData = useRecoilValue(fetchDataSelector)
  const [invalidDomain, setInvalidDomain] = useRecoilState(invalidDomainAtom)
  const [domainValidated, setDomainValidated] = useState(false)

  const getErrorText = (errorResponse) => {
    if (
      errorResponse?.data?.validationErrors &&
      errorResponse?.data?.validationErrors?.length > 0
    ) {
      setInvalidDomain(true)
    }
  }
  useEffect(() => {
    const fetchUserFlow = async () => {
      await getCoreData(
        APIEndPoints?.userFlow?.method,
        `${APIEndPoints?.userFlow?.url}`,
        { emailAddress: userName },
        null,
        null,
        true,
        utils?.apiDomainName?.userPermission
      )?.then((response) => {
        setDomainValidated(true)
        if (response?.status === utils?.apiResponseCode?.successCode) {
          setMsalPolicyName(response?.data?.userFlowName)
        } else {
          getErrorText(response)
        }
      })
    }

    if (
      getCoreData?.toString() !== utils?.emptyFunction?.toString() &&
      userName &&
      !msalPolicyName
    ) {
      fetchUserFlow()
    }
  }, [getCoreData, userName])

  return msalInstance ? (
    <MsalProvider instance={msalInstance}>
      <App msalInstance={msalInstance} loginHintUserName={userName} />
    </MsalProvider>
  ) : invalidDomain && domainValidated ? (
    <InvalidDomainPage />
  ) : (
    <></>
  )
}
export default GetUserFlow
