/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/* // This will render the object which is to passed to MuiUseConfirmPopup - we have params,
title - node/text custom as per requirement
message - node/text custom as per requirement
buttonYesText - text custom as per requirement
buttonNoText - text custom as per requirement  */

/* istanbul ignore file */

// System defined variables
import React from 'react'
// Custom defined variables
import Button from '../../base/button/Button'
import './ConfirmPopupRenderer.css'

export const ConfirmPopupRendererObject = (
  title,
  message,
  buttonYesText,
  buttonNoText,
  allowClose,
  customDescribedBy
) => {
  return {
    dialogProps: {
      'aria-describedby': title
        ? ''
        : customDescribedBy
        ? customDescribedBy
        : `noTitle_CenterAligned`,
    },
    title: title ? <div className="confirmPopupTitle">{title}</div> : null,
    description: <div className="confirmPopupContent">{message}</div>,
    confirmationText: (
      <Button
        Field={{
          fieldValue: 'Yes',
          fieldLabel: `${buttonYesText || 'Yes'}`,
          variant: 'primary',
        }}
        dataTestId="dialog_dataSaveWarningYes"
        className="saveButton"
        onClickHandler={() => {}}
      />
    ),
    cancellationText: (
      <Button
        Field={{
          fieldValue: 'No',
          fieldLabel: `${buttonNoText || 'No'}`,
          variant: 'secondary',
        }}
        data-testId="dialog_dataSaveWarningNo"
        className="saveButton"
        onClickHandler={() => {}}
      />
    ),
    allowClose: allowClose || false,
  }
}
