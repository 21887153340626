// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
.checkboxControl .MuiFormControlLabel-root {
  display: flex;
  flex-direction: row;
}

/* .checkboxControl .MuiSvgIcon-root {
    color: #1F3C88;
} */

.checkboxControl .MuiFormControlLabel-label {
  font-family: var(--label-FontName);
  font-size: var(--label-FontSize);
  font-weight: var(--label-FontWeight);
  color: var(--label-FontColour);
  line-height: var(--text-line-height);
}

.checkboxSpanControl {
  align-items: center;
  display: flex;
  /* gap: 6px; */
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/checkboxControl/CheckboxControl.css"],"names":[],"mappings":"AAAA;;;CAGC;AACD;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;;GAEG;;AAEH;EACE,kCAAkC;EAClC,gCAAgC;EAChC,oCAAoC;EACpC,8BAA8B;EAC9B,oCAAoC;AACtC;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,cAAc;AAChB","sourcesContent":["/*\nThis computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), \nis the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.\n*/\n.checkboxControl .MuiFormControlLabel-root {\n  display: flex;\n  flex-direction: row;\n}\n\n/* .checkboxControl .MuiSvgIcon-root {\n    color: #1F3C88;\n} */\n\n.checkboxControl .MuiFormControlLabel-label {\n  font-family: var(--label-FontName);\n  font-size: var(--label-FontSize);\n  font-weight: var(--label-FontWeight);\n  color: var(--label-FontColour);\n  line-height: var(--text-line-height);\n}\n\n.checkboxSpanControl {\n  align-items: center;\n  display: flex;\n  /* gap: 6px; */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
