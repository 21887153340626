/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/*
  This page is the splash screen, which will be loaded as the initial screen of application after successfull authentication
*/
import React, { useEffect } from 'react' //Suspense
import { useSetRecoilState } from 'recoil'
import SplashComponent from '../../components/custom/splashComponent/SplashComponent'
import { showSplashScreen } from '../../components/recoil/atoms/atoms'

function SplashScreen() {
  const setShowSplashScreen = useSetRecoilState(showSplashScreen)

  useEffect(() => {
    setTimeout(() => {
      setShowSplashScreen(false)
    }, [3200])
  }, [])

  return <SplashComponent />
}
export default SplashScreen
