/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
 In this we get the detials and show with Grid
 Close (X) button provided to close the modal popup.
*/

/* istanbul ignore file */

import React, { useState, useEffect, useMemo } from 'react'
import Grid from '../grid/Grid'
import { customDateRenderer, customTextRenderer } from '../grid/CustomRenderer'
import './ReactModal.css'
import { Backdrop } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { usePromiseTracker } from 'react-promise-tracker'
import CloseIcon from '@mui/icons-material/Close'
import ZoomIn from '@mui/icons-material/ZoomIn'
import ZoomOut from '@mui/icons-material/ZoomOut'
import { Add } from '@mui/icons-material'
import DownloadIcon from '@mui/icons-material/SaveAltSharp'
import DeleteIconFill from '../../../../assets/images/DeleteIconFill.svg'
import { ReactSVG } from 'react-svg'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { fetchDataSelector } from '../../../components/recoil/selectors/selectors'
import APIEndPoints from '../../../../models/api/apiEndpoints'
import {
  selectedCaseDetails,
  contactAddressstore,
  madeChanges,
  additionalOptionsDisabled,
  contactAddressPage,
  toastMessage,
  selfRenderring,
  selectedContactAddress,
  selectedContractDetails,
  fetchCurrentApprovalList,
  timezoneAtom,
} from '../../../components/recoil/atoms/atoms'
import utils from '../../../../utils/utils'
import Multiline from '../../../components/base/multilineText/MultilineText'
import AutoComplete from '../../../components/base/autoComplete/AutoComplete'
import Button from '../../../components/base/button/Button'
import TextBox from '../../../components/base/textBox/TextBox'
import RadioGroupControl from '../../../components/base/radioGroupControl/RadioGroupControl'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { DialogActions } from '@mui/material'
import { DialogTitle } from '@material-ui/core'
import { Document, Page, pdfjs } from 'react-pdf'
import { ConfirmPopupRendererObject } from '../../../components/custom/confirmPopupRenderer/ConfirmPopupRenderer'

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
// making this version strict due to dynamic version gets creating mismatch errors for wokrker.js
// referred : https://cdnjs.com/libraries/pdf.js/4.4.168
pdfjs.GlobalWorkerOptions.workerSrc =
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.mjs'
// Disable eval to prevent execution of unrestricted attacker-controlled JavaScript
pdfjs.GlobalWorkerOptions.options = { isEvalSupported: false }
const ReactModal = (props) => {
  const {
    searchData,
    modalOpen,
    setmodalOpen,
    modaltype,
    showmodal,
    selectTab,
    templateId,
    addressDataSource,
    checkedAddressRadioId,
    setCheckedAddressRadioId,
    selectedAddressRadioId,
    setSelectedAddressRadioId,
    setEditedFlag,
    methodName,
    setSelfRenderringUpdate,
    deptwarrantProcessedData,
    setPopUpAddDomainURL,
    setPopUpAddURL,
    setModalType,
    isPreview,
    setIsPreview,
  } = props

  const confirmAlert = useConfirm()
  const { promiseInProgress } = usePromiseTracker()
  const navigate = useNavigate()
  const setAddContactDetails = useSetRecoilState(contactAddressstore)
  const [open, setOpen] = useState(false)
  const [datasource, setDatasource] = useState({})
  const setSelectedcaseDetails = useSetRecoilState(selectedCaseDetails)
  const setSelectedContactAddress = useSetRecoilState(selectedContactAddress)
  const setChangesMade = useSetRecoilState(madeChanges)
  const selectedcasedata = useRecoilValue(selectedCaseDetails)
  const selfRenderringUpdate = useSetRecoilState(selfRenderring)
  const selfRenderringvalue = useRecoilValue(selfRenderring)
  const selectedContractData = useRecoilValue(selectedContractDetails)
  const setCurrentApprovalListFlag = useSetRecoilState(fetchCurrentApprovalList)
  const timezoneData = useRecoilValue(timezoneAtom)

  // const [pdfData, setPdfData] = useState(searchData)
  const [numPages, setNumPages] = useState(null)
  const initialScale = modaltype === 'approvalQueueList' ? 1.3 : 1.0
  const [scale, setScale] = useState(initialScale)

  const setAdditionalOptionsDisabled = useSetRecoilState(
    additionalOptionsDisabled
  )
  const getCoreData = useRecoilValue(fetchDataSelector)
  const showToastMessage = useSetRecoilState(toastMessage)
  const [dataSaveWarning, setDataSaveWarning] = useState(false)
  const [warrantSaveWarning, setWarrantSaveWarning] = useState(false)
  const [warrantProcessSaveWarning, setWarrantProcessSaveWarning] =
    useState(false)
  const [hideWarrantProcessPopup, setHideWarrantProcessPopup] = useState(false)
  const [templateReject, setTemplateReject] = useState(false)
  const [rejectedReason, setRejectedReason] = useState('')
  const [disableTemplateButton, setDisableTemplateButton] = useState(true)
  const [rejectError, setRejectError] = useState('')
  const [divDisable, setDivDisable] = useState(false)

  const [addnotesError, setAddnotesError] = useState('')
  const [disable, setDisable] = useState(true)
  const [disableNotes, setDisableNotes] = useState(true)

  const [zoneNameError, setZoneNameError] = useState('')
  const [zoneCodeError, setZoneCodeError] = useState('')
  const [zoneOrganisationError, setZoneOrganisationError] = useState('')
  const [isClose, setIsClose] = useState(false)

  const [orgDataSource, setOrgDataSource] = useState([])

  const initialState = {
    note: null,
  }
  const [values, setValues] = useState(initialState)
  const isDisabled = useRecoilValue(contactAddressPage)
  const [isDisabledDelete, setIsDisabledDelete] = useState(false)

  const initialDataStateZone = {
    organisation: searchData?.organisationId ? searchData?.organisationId : '',
    contract: searchData?.contractId ? searchData?.contractId : '',
    zoneName: searchData?.locationZoneName ? searchData?.locationZoneName : '',
    zoneCode: searchData?.locationZoneCode ? searchData?.locationZoneCode : '',
    id: searchData?.id ? searchData?.id : '',
  }
  const initialWarrantState = {
    searchCase: '',
  }

  const initialBrandingURL = {
    addURL: '',
  }

  const [formValues, setFormValues] = useState({
    ...initialDataStateZone,
  })
  const [formValuesWarrant, setFormValuesWarrant] = useState({
    ...initialWarrantState,
  })
  const [formValueBrandingURL, setFormValueBrandingURL] = useState({
    ...initialBrandingURL,
  })
  const [messageType, setMessageType] = useState('type')
  const [contractsByOrganisation, setContractsByOrganisation] = useState([])
  const [isSomeRejectedVisible, setIsSomeRejectedVisible] = useState(false)

  const setAllAccepted = 'All Accepted'
  const setAllRejected = 'All Rejected'
  const setSomeRejected = 'Some Rejected'
  const setAllAcceptedId = 'AllAccepted'
  const setAllRejectedId = 'AllRejected'
  const setSomeRejectedId = 'SomeRejected'

  const [messageDataSource, setMessageDataSource] = useState([
    { name: setAllAccepted, id: setAllAcceptedId, optionSelected: false },
    { name: setAllRejected, id: setAllRejectedId, optionSelected: false },
    { name: setSomeRejected, id: setSomeRejectedId, optionSelected: false },
  ])
  const [searchCaseData, setSearchCaseData] = useState(null)
  const [searchCaseAddList, setSearchCaseAddList] = useState([])

  const staticCode = {
    organisation: 'Organisation',
    contract: 'Contract',
    message: 'Message',
  }

  const onChangeMessageType = (event, params) => {
    setMessageType(event?.target?.value)
    if (event?.target?.value === 'SomeRejected') {
      setIsSomeRejectedVisible(true)
      setMessageType('')
      setIsDisabledDelete(true)
    } else {
      if (searchCaseAddList.length > 0) {
        setIsDisabledDelete(false)
        setWarrantSaveWarning(true)
        setIsSomeRejectedVisible(true)
      } else {
        setIsDisabledDelete(true)
        setWarrantSaveWarning(false)
        setIsSomeRejectedVisible(false)
        onSearchCaseReset()
      }
    }
    const messageDataSourceTemmp = messageDataSource?.map((messageData) => {
      let tempData = { ...messageData }
      if (
        tempData?.id?.toString()?.toLowerCase() ===
        params?.value?.toString()?.toLowerCase()
      ) {
        tempData = { ...tempData, optionSelected: true }
      } else {
        tempData = { ...tempData, optionSelected: false }
      }
      return tempData
    })
    setMessageDataSource(messageDataSourceTemmp)
  }

  useEffect(() => {
    if (messageType?.toString()?.toLowerCase() === 'somerejected') {
      const messageDataSourceTemmp = messageDataSource?.map((messageData) => {
        let tempData = { ...messageData }
        if (tempData?.id?.toString()?.toLowerCase() === 'somerejected') {
          tempData = { ...tempData, optionSelected: true }
        } else {
          tempData = { ...tempData, optionSelected: false }
        }
        return tempData
      })
      setMessageDataSource(messageDataSourceTemmp)
    }
  }, [messageType])

  const handleClose = () => {
    if (modaltype === 'notestabAdd') {
      if (JSON.stringify(initialState) !== JSON.stringify(values)) {
        setDataSaveWarning(true)
      } else {
        setOpen(false)
        setmodalOpen()
      }
    } else if (
      modaltype === 'approvalQueueList' ||
      (modaltype === 'TemplateList' && isPreview === true)
    ) {
      setOpen(false)
      setmodalOpen()
      setTemplateReject(false)
      if (setIsPreview) setIsPreview(false)
    } else if (modaltype === 'addZone') {
      onAddZoneCancel()
      setIsClose(true)
    } else if (
      modaltype === 'WarrantExecutedPopup' ||
      modaltype === 'DebtExecutedPopup'
    ) {
      onWarrantExecutedPopupCancel()
      setIsClose(true)
    } else {
      if (isDisabled === true) {
        // setAddContactDetails(true)
        // setComponentModified(true)
        // setAdditionalOptionsDisabled(false)
      } else {
        setAddContactDetails(false)
        setChangesMade(false)
        setAdditionalOptionsDisabled(true)
      }
      setOpen(false)
      setmodalOpen()
    }
  }

  const handleRejectClose = async () => {
    if (rejectedReason?.toString()?.trim() !== '') {
      //setDataSaveWarning(true)
      if (await getCancelConfirmation()) {
        handleClose()
      }
    } else {
      setOpen(false)
      setmodalOpen()
      setTemplateReject(false)
    }
  }
  const handleInputChange = (event, params) => {
    const { value } = params
    setRejectedReason(value)
  }
  const handleInputFocusAddZone = (event, params) => {
    if (formValues?.organisation === '') {
      setZoneOrganisationError('Please select organisation')
    } else {
      setZoneOrganisationError('')
    }
  }

  const handleInputChangeAddZone = (event, params) => {
    if (params?.id?.toString()?.toLowerCase() === 'organisation') {
      setFormValues({
        ...formValues,
        [params?.id]: params?.value,
        contract: '',
      })
      setZoneOrganisationError('')
    } else if (params?.id?.toString()?.toLowerCase() === 'contract') {
      setFormValues({
        ...formValues,
        [params?.id]: params?.value,
      })
    } else if (params?.id?.toString()?.toLowerCase() === 'zonename') {
      if (params?.value?.toString()?.trim()?.length > 50) {
        setZoneNameError('Maximum character limit reached')
        if (!formValues[params?.id]?.toString()?.trim()?.length > 0) {
          let newValue = params?.value?.toString()?.trim()?.slice(0, 50)
          setFormValues((previousState) => {
            return {
              ...previousState,
              [params?.id]: newValue,
            }
          })
        }
      } else {
        setFormValues((previousState) => {
          return {
            ...previousState,
            [params?.id]: params?.value,
          }
        })
        setZoneNameError('')
      }
    } else if (params?.id?.toString()?.toLowerCase() === 'zonecode') {
      // Allowing only alpha numeric characters, not special characters and space characters
      const filteredAlphaNumericValue = params?.value
        ?.toString()
        ?.replace(/[^a-zA-Z0-9]/g, '')
        ?.replace(/\s/g, '')
      if (filteredAlphaNumericValue.length > 10) {
        setZoneCodeError('Maximum character limit reached')
        if (!formValues[params?.id]?.toString()?.trim()?.length > 0) {
          let newValue = filteredAlphaNumericValue.slice(0, 10)
          setFormValues((previousState) => {
            return {
              ...previousState,
              [params?.id]: newValue,
            }
          })
        }
      } else {
        setFormValues({
          ...formValues,
          [params?.id]: filteredAlphaNumericValue,
        })
        setZoneCodeError('')
      }
    } else if (params?.id?.toString()?.toLowerCase() === 'searchcase') {
      setFormValuesWarrant({
        ...formValuesWarrant,
        [params?.id]: params?.value,
      })
    }
  }

  const getDropDownListDataSource = () => {
    Promise.all([
      getCoreData(
        APIEndPoints?.editOrganisationData?.method,
        `${APIEndPoints?.editOrganisationData?.url}` // need to pass userID once userRole implementation is done
      ),
    ])
      .then(([OrgLookUpData]) => {
        setOrgDataSource(OrgLookUpData?.data?.data)
      })
      .catch((err) => {
        showToastMessage({
          message: err?.data?.validationErrors[0]?.errorMessage,
          status: 'error',
        })
      })
  }

  const onAddZoneCancel = () => {
    if (JSON.stringify(initialDataStateZone) !== JSON.stringify(formValues)) {
      setDataSaveWarning(true)
    } else {
      setOpen(false)
      setmodalOpen()
    }
  }

  const generatePostData = (methodName) => {
    if (methodName === 'PUT')
      return {
        contractId: formValues?.contract,
        locationZoneName: formValues?.zoneName?.trim(),
        locationZoneCode: formValues?.zoneCode?.trim(),
        id: formValues?.id,
      }
    else
      return {
        contractId: formValues?.contract,
        locationZoneName: formValues?.zoneName?.trim(),
        locationZoneCode: formValues.zoneCode?.trim(),
      }
  }

  const postZone = async (postData) => {
    await getCoreData(
      APIEndPoints?.Zone?.postZone.method,
      `${APIEndPoints?.Zone.postZone.url}`,
      postData
    )
      .then((response) => {
        if (response?.status === utils?.apiResponseCode?.successCode) {
          showToastMessage({
            message: 'Zone has been added successfully',
            status: 'success',
          })
          setOpen(false)
          setmodalOpen()
          setSelfRenderringUpdate(true)
        } else {
          if (response?.data?.validationErrors[0]?.errorMessage) {
            showToastMessage({
              message: response?.data?.validationErrors[0]?.errorMessage,
              status: 'error',
            })
          } else if (response?.data) {
            showToastMessage({
              message: response?.data?.errors,
              status: 'error',
            })
          }
          setDisable(false)
        }
      })
      .catch((error) => {
        if (error?.message) {
          showToastMessage({
            message: error?.data?.message,
            status: 'error',
          })
        } else if (error?.data) {
          showToastMessage({
            message: error?.data,
            status: 'error',
          })
        }
        setDisable(false)
      })
  }
  const putZone = async (postData) => {
    await getCoreData(
      APIEndPoints?.Zone?.putZone.method,
      `${APIEndPoints?.Zone.putZone.url}`,
      postData
    )
      .then((response) => {
        if (response?.status === utils?.apiResponseCode?.successCode) {
          showToastMessage({
            message: 'Zone has been updated successfully',
            status: 'success',
          })
          setOpen(false)
          setmodalOpen()
          setSelfRenderringUpdate(true)
        } else {
          if (response?.data?.validationErrors[0]?.errorMessage) {
            showToastMessage({
              message: response?.data?.validationErrors[0]?.errorMessage,
              status: 'error',
            })
          } else if (response?.data) {
            showToastMessage({
              message: response?.data?.errors,
              status: 'error',
            })
          }
          setDisable(false)
        }
      })
      .catch((error) => {
        if (error?.message) {
          showToastMessage({
            message: error?.data?.message,
            status: 'error',
          })
        } else if (error?.data) {
          showToastMessage({
            message: error?.data,
            status: 'error',
          })
        }
        setDisable(false)
      })
  }
  const onAddZone = async () => {
    setDisable(true)
    let postData = generatePostData(methodName)
    if (methodName === 'POST') {
      postZone(postData)
    } else if (methodName === 'PUT') {
      putZone(postData)
    }
  }

  useEffect(() => {
    if (modaltype === 'addZone') {
      getDropDownListDataSource()
    }
  }, [])

  useEffect(() => {
    // Effect to get contract dataSource based on Organisation, whenever the organisation value changes
    if (formValues?.organisation) {
      getContractDataSource()
    } else {
      setContractsByOrganisation([])
    }
  }, [formValues?.organisation])

  const getContractDataSource = () => {
    Promise.all([
      getCoreData(
        APIEndPoints?.Contract?.ContractsByOrganisation?.method,
        APIEndPoints?.Contract?.ContractsByOrganisation?.url,
        {
          organisationId: [formValues?.organisation],
        }
      ),
    ])
      .then(([response]) => {
        if (response?.status === utils?.apiResponseCode?.successCode) {
          setContractsByOrganisation([...response?.data])
        } else {
          if (response?.status === utils?.apiResponseCode?.badRequest) {
            showToastMessage({
              message: response.statusText,
              status: 'error',
            })
          }
          setContractsByOrganisation([])
        }
      })
      .catch((err) => {
        showToastMessage({
          message: err?.data?.validationErrors[0]?.errorMessage,
          status: 'error',
        })
      })
  }

  /* istanbul ignore next */
  useEffect(() => {
    return setDatasource({
      columnDefs: [
        {
          headerName: 'Organization Name',
          field: 'organisation',
          filter: 'agTextColumnFilter',
          filterParams: utils?.gridFilterOptions?.commonFilterParams,
          cellRenderer: customTextRenderer,
        },
        {
          headerName: 'Contract Name',
          field: 'contract',
          filter: 'agTextColumnFilter',
          filterParams: utils?.gridFilterOptions?.commonFilterParams,
          cellRenderer: customTextRenderer,
        },
        {
          headerName: 'PCN number',
          field: 'caseNumber',
          valueGetter: 'data?.caseNumber',
          filter: 'agTextColumnFilter',
          filterParams: utils?.gridFilterOptions?.commonFilterParams,
          cellRenderer: CustomCellRenderer,
        },
        {
          headerName: 'PCN created date',
          field: 'createdOn',
          filter: 'agDateColumnFilter',
          filterParams: utils?.gridFilterOptions?.dateFilterParams,
          valueGetter: utils?.dateAndTimeConversion,
          cellRenderer: (params) => customDateRenderer(params, 'default'),
        },
        {
          headerName: 'Stage',
          field: 'stage',
          filter: 'agTextColumnFilter',
          filterParams: utils?.gridFilterOptions?.commonFilterParams,
          cellRenderer: customTextRenderer,
        },
        {
          headerName: 'Status',
          field: 'status',
          filter: 'agTextColumnFilter',
          filterParams: utils?.gridFilterOptions?.commonFilterParams,
          cellRenderer: customTextRenderer,
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      rowData: searchData?.dataSource,
    })
  }, [searchData])

  useEffect(() => {
    setOpen(modalOpen)
  }, [modalOpen])

  function updateSelfRendering() {
    selfRenderringUpdate(!selfRenderringvalue)
  }

  function clickdata(props) {
    setAddContactDetails(false)
    setChangesMade(false)
    setAdditionalOptionsDisabled(true)
    setSelectedcaseDetails(props)
    setmodalOpen()
    navigate('/pcnpage')
  }
  /* istanbul ignore next */
  function CustomCellRenderer(params) {
    return (
      <div className="gridCellFlexBox">
        <a
          className="gridLinkclass gridCellDisplayData"
          onClick={() => clickdata(params?.data)}
          title={params?.data?.caseNumber}
        >
          {params?.data?.caseNumber}
        </a>
      </div>
    )
  }
  /* istanbul ignore next */
  const onChangeHandler = (event, params) => {
    if (params?.id?.toString()?.toLowerCase() === 'note') {
      if (params?.value?.toString()?.trim()?.length > 0) {
        setDisableNotes(false)
      } else {
        setDisableNotes(true)
      }

      if (params?.value?.toString()?.trim()?.length > utils?.maxNotesLength) {
        setAddnotesError('Maximum character limit reached')
        if (!values[params?.id]?.toString()?.trim()?.length > 0) {
          const newValue = params?.value
            ?.toString()
            ?.trim()
            ?.slice(0, utils?.maxNotesLength)
          setValues((previousState) => {
            return {
              ...previousState,
              [params?.id]: newValue,
            }
          })
        }

        // } else if (
        //   params?.value?.toString()?.trim().length === undefined &&
        //   params?.id?.toString()?.toLowerCase() === 'note'
        // ) {
        //   setDisable(true)
        //   setValues((previousState) => {
        //     return {
        //       ...previousState,
        //       [params?.id]: '',
        //     }
        //   })
      } else {
        setDisable(false)
        setValues({ ...values, [params?.id]: params?.value })
        setAddnotesError(null)
      }
    } else {
      if (params?.value?.toString()?.trim()?.length > 0) {
        setDisable(false)
      } else {
        setDisable(true)
      }
    }
  }

  /** Here check when clicking on the cancel button, if any update on input controls and compare the initial value and current value. After comparing the values are differ the alert popup will show(Yes/No).
   * The message is "Your work is not saved. Are you sure to cancel?"
   * Yes - The popup will discard
   * No - Back to privious screen
   */
  const onCancelAddNotes = () => {
    if (JSON.stringify(initialState) !== JSON.stringify(values)) {
      setDataSaveWarning(true)
    } else {
      setOpen(false)
      setmodalOpen()
    }
  }
  const getCancelConfirmation = async () => {
    let returnValue = true
    if (rejectedReason) {
      returnValue = await confirmAlert({
        ...ConfirmPopupRendererObject(null, utils?.dataSaveWarnMessage),
      })
        .then(() => {
          return true
        })
        .catch(() => {
          return false
        })
    }
    return returnValue
  }
  // Ignoring this due to using confirm mui popup
  /* istanbul ignore next */
  const onRejectReasonCancel = async () => {
    if (await getCancelConfirmation()) {
      handleClose()
    }
  }
  /* istanbul ignore next */
  const onCancelSendCorrespondence = () => {
    setOpen(false)
    setmodalOpen()
    setSelectedContactAddress('')

    setCheckedAddressRadioId(selectedAddressRadioId)
  }
  /* istanbul ignore next */
  const onSelectSendCorrespondenceAddress = () => {
    setOpen(false)
    setmodalOpen()
    setSelectedAddressRadioId(checkedAddressRadioId)
    setEditedFlag(true)
  }

  const onSaveAddNotes = async () => {
    setDisable(true)
    let contractId = searchData?.contractId
    let postData = { ...values, contractId }

    await getCoreData(
      APIEndPoints?.postCaseData?.method,
      `${APIEndPoints?.postCaseData?.url}/${selectedcasedata?.id}/Note`,
      postData
    )
      .then((response) => {
        if (response?.status === utils?.apiResponseCode?.successCode) {
          showToastMessage({
            message: 'Note has been added successfully',
            status: 'success',
          })
          setOpen(false)
          selectTab()
          setmodalOpen()
          updateSelfRendering()
        } else {
          if (response?.data?.message) {
            showToastMessage({
              message: response?.data?.message,
              status: 'error',
            })
          } else if (response?.data) {
            showToastMessage({
              message: response?.data?.errors,
              status: 'error',
            })
          }
          setDisable(false)
        }
      })
      .catch((error) => {
        if (error?.message) {
          showToastMessage({
            message: error?.data?.message,
            status: 'error',
          })
        } else if (error?.data) {
          showToastMessage({
            message: error?.data,
            status: 'error',
          })
        }
        setDisable(false)
      })
  }

  /** Template functionalities -- Start */
  const handleZoomIn = () => {
    setTimeout(() => {
      setDivDisable(true)
      setScale(scale + 0.1)
      setDivDisable(false)
    }, 1000)
  }

  const handleZoomOut = () => {
    setTimeout(() => {
      setDivDisable(true)
      setScale(scale - 0.1)
      setDivDisable(false)
    }, 1000)
  }
  const onReject = () => {
    setTemplateReject(true)
    setOpen(false)
  }
  function CustomLoading() {
    return <div>{''}</div>
  }
  /* istanbul ignore next */
  const onAccept = async () => {
    setOpen(false)
    setmodalOpen()
    const postData = {
      correspondenceId: templateId,
      status: 'approved',
      rejectReason: '',
    }
    await getCoreData(
      APIEndPoints?.Correspondence?.postCorrespondenceApprove?.method,
      `${APIEndPoints?.Correspondence?.postCorrespondenceApprove.url}/Contract/${selectedContractData?.contractId}`,
      postData
    )
      .then((response) => {
        if (response?.status === utils?.apiResponseCode?.successCode) {
          // setOpen(false)
          setDataSaveWarning(false)
          // setmodalOpen()
          showToastMessage({
            message: 'Letter approved successfully',
            status: 'success',
          })
          setCurrentApprovalListFlag(true)
        } else {
          if (response?.data?.exceptionMessage) {
            showToastMessage({
              message: response?.data?.message,
              status: 'error',
            })
          }
          params.successCallback([], 0)
        }
      })
      .catch(() => {
        params.successCallback([], 0)
      })
  }
  /* istanbul ignore next */
  const handleInputChangeReject = (event, params) => {
    if (params?.value?.toString().trim()?.length > 0)
      setDisableTemplateButton(false)
    else setDisableTemplateButton(true)

    if (params?.id?.toString()?.toLowerCase() === 'reject') {
      if (params?.value?.toString()?.trim()?.length > 50000) {
        setRejectError('Maximum character limit reached')
        if (!rejectedReason?.toString()?.trim()?.length > 0) {
          let newValue = params?.value?.toString()?.trim()?.slice(0, 50000)
          setRejectedReason(newValue)
        }
      } else {
        setRejectedReason(params?.value)
        setRejectError(null)
      }
    }
  }

  /* istanbul ignore next */
  const onRejectReasonSave = async () => {
    setOpen(false)
    setmodalOpen()
    const postData = {
      correspondenceId: templateId,
      status: 'rejected',
      rejectReason: rejectedReason,
    }
    await getCoreData(
      APIEndPoints?.Correspondence?.postCorrespondenceApprove?.method,
      `${APIEndPoints?.Correspondence?.postCorrespondenceApprove.url}/Contract/${selectedContractData?.contractId}`,
      postData
    )
      .then((response) => {
        if (response?.status === utils?.apiResponseCode?.successCode) {
          showToastMessage({
            message: 'Reason is recorded and the letter is rejected',
            status: 'success',
          })
          // setOpen(false)
          setDataSaveWarning(false)
          // setmodalOpen()
          setCurrentApprovalListFlag(true)
        } else {
          if (response?.data?.exceptionMessage) {
            showToastMessage({
              message: response?.data?.message,
              status: 'error',
            })
          }
          params.successCallback([], 0)
        }
      })
      .catch(() => {
        params.successCallback([], 0)
      })
  }
  /** Template functionalities -- End */

  const getLabel = (label, mandatory, disabled) => {
    return (
      <div
        className={
          disabled ? 'contract_InputLabel_Disabled' : 'contract_InputLabel'
        }
      >
        <span>
          {label}
          {mandatory ? <span className="mandatorySymbol">{` *`}</span> : null}
        </span>
      </div>
    )
  }
  const disableSaveButton = () => {
    if (modaltype === 'addZone') {
      if (methodName?.toString().toLowerCase() === 'post') {
        if (
          formValues?.organisation !== '' &&
          formValues?.contract !== '' &&
          formValues?.zoneName.toString().trim() !== ''
        ) {
          return false
        } else {
          return true
        }
      } else {
        if (
          JSON.stringify(initialDataStateZone) !== JSON.stringify(formValues)
        ) {
          return false
        } else {
          return true
        }
      }
    } else if (
      modaltype === 'WarrantExecutedPopup' ||
      modaltype === 'DebtExecutedPopup'
    ) {
      if (formValuesWarrant?.searchCase !== '') {
        let foundObject = false
        searchCaseAddList.forEach((item) => {
          if (
            item?.caseNumber?.trim() === formValuesWarrant?.searchCase?.trim()
          ) {
            foundObject = true
          }
        })

        if (foundObject === true) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
  }
  const disableSearchResultAddButton = () => {
    if (searchCaseData && searchCaseData?.caseId > 0) {
      return false
    } else {
      return true
    }
  }

  const disableWarrantProcessButton = () => {
    if (messageType !== 'type') {
      if (messageType === '') {
        if (searchCaseAddList.length > 0) {
          return false
        }
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }
  const handleInputChangeAddURL = (event, params) => {
    setFormValueBrandingURL({ ...formValues, addURL: params?.value })
  }

  function isValidHttpUrl(string) {
    /* var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    )
    return res !== null */
    // Regular expression for matching URLs
    const regex =
      /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/i
    return regex.test(string)
  }
  const disableAddURLSaveButton = () => {
    const urlValidateion = isValidHttpUrl(formValueBrandingURL?.addURL)
    if (urlValidateion)
      if (formValueBrandingURL?.addURL !== '') {
        return false
      }
    return true
  }

  const disableCancelButton = () => {
    if (
      formValues?.organisation !== '' ||
      formValues?.contract !== '' ||
      formValues?.zoneName.toString().trim() !== ''
    ) {
      return false
    } else {
      return true
    }
  }

  const onWarrantExecutedPopupCancel = () => {
    if (messageType?.toString()?.toLowerCase() === '') {
      setWarrantSaveWarning(true)
      setIsClose(false)
    } else if (messageType?.toString()?.toLowerCase() !== 'type') {
      setWarrantSaveWarning(true)
      setIsClose(false)
    } else {
      setWarrantSaveWarning(false)
      setIsSomeRejectedVisible(false)
      setSearchCaseAddList([])
      setmodalOpen(false)
      setWarrantProcessSaveWarning(false)
      setHideWarrantProcessPopup(false)
    }
  }

  const onProcessBatch = () => {
    setWarrantProcessSaveWarning(true)
    setHideWarrantProcessPopup(true)
  }

  const onAddURL = () => {
    setPopUpAddURL(formValueBrandingURL)
    setModalType(modaltype)
    setmodalOpen(false)
  }

  /* istanbul ignore next */
  const onSaveProcessBatch = () => {
    if (
      modaltype === 'WarrantExecutedPopup' ||
      modaltype === 'DebtExecutedPopup'
    ) {
      if (modaltype === 'DebtExecutedPopup') {
        var postData = {}

        if (messageType?.toString()?.toLowerCase() === '') {
          let npsCaseRecId = []
          searchCaseAddList?.forEach((caseAddList) => {
            npsCaseRecId.push({ npsCaseId: caseAddList?.caseId })
          })
          postData = {
            debtRegistrationId: searchData?.debtRegistrationId,
            batchType: 3,
            caseData: npsCaseRecId,
          }
        } else if (messageType?.toString()?.toLowerCase() !== 'type') {
          postData = {
            debtRegistrationId: searchData?.debtRegistrationId,
            batchType:
              messageType?.toString()?.toLowerCase() === 'allaccepted' ? 1 : 2,
          }
        }

        getCoreData(
          APIEndPoints?.Debt?.updateDebtProcess?.method,
          APIEndPoints?.Debt?.updateDebtProcess?.url?.replace(
            '__contractId__',
            selectedContractData?.contractId
          ),
          postData
        )
          .then((response) => {
            if (response?.status === utils?.apiResponseCode?.successCode) {
              showToastMessage({
                message: `Batch ${searchData?.batchReference} has been processed successfully !`,
                status: 'success',
              })
              setSelfRenderringUpdate(true)
            } else {
              showToastMessage({
                message: `Batch ${searchData?.batchReference} Not Processed`,
                status: 'error',
              })
            }
          })
          .catch(() => {
            showToastMessage({
              message: 'Batch Not Processed',
              status: 'error',
            })
          })
      } else if (modaltype === 'WarrantExecutedPopup') {
        var postData = {}

        if (messageType?.toString()?.toLowerCase() === '') {
          let npsCaseRecId = []
          searchCaseAddList?.forEach((caseAddList) => {
            npsCaseRecId.push({ npsCaseId: caseAddList?.caseId })
          })
          postData = {
            warrantRequestId: searchData?.warrantRequestId,
            batchType: 3,
            caseData: npsCaseRecId,
          }
        } else if (messageType?.toString()?.toLowerCase() !== 'type') {
          postData = {
            warrantRequestId: searchData?.warrantRequestId,
            batchType:
              messageType?.toString()?.toLowerCase() === 'allaccepted' ? 1 : 2,
          }
        }

        getCoreData(
          APIEndPoints?.Warrant?.updateWarrantProcess?.method,
          APIEndPoints?.Warrant?.updateWarrantProcess?.url?.replace(
            '__contractId__',
            selectedContractData?.contractId
          ),
          postData
        )
          .then((response) => {
            if (response?.status === utils?.apiResponseCode?.successCode) {
              showToastMessage({
                message: `Batch ${searchData?.batchReference} has been processed successfully !`,
                status: 'success',
              })
              setSelfRenderringUpdate(true)
            } else {
              showToastMessage({
                message: `Batch ${searchData?.batchReference} Not Processed`,
                status: 'error',
              })
            }
          })
          .catch(() => {
            showToastMessage({
              message: 'Batch Not Processed',
              status: 'error',
            })
          })
      } else {
        setmodalOpen(false)
      }
    }
  }

  const onSearchCaseReset = () => {
    setFormValuesWarrant({
      ...formValuesWarrant,
      searchCase: '',
    })
    setSearchCaseData(null)
  }
  const onSearchCase = async () => {
    var postData = {}
    var getURL = {}
    var searchDataResponse = null

    if (modaltype === 'DebtExecutedPopup') {
      postData = {
        debtRegistrationId: searchData?.debtRegistrationId,
        caseNumber: formValuesWarrant?.searchCase,
      }
      getURL = APIEndPoints?.Debt?.getDebtCaseSearch?.url.replace(
        '__contractId__',
        selectedContractData?.contractId
      )
      searchDataResponse = await getCoreData(
        APIEndPoints?.Debt?.getDebtCaseSearch?.method,
        getURL,
        postData
      )
    } else if (modaltype === 'WarrantExecutedPopup') {
      postData = {
        warrantRequestId: searchData?.warrantRequestId,
        caseNumber: formValuesWarrant?.searchCase,
      }
      getURL = APIEndPoints?.Warrant?.getWarrantCaseSearch?.url.replace(
        '__contractId__',
        selectedContractData?.contractId
      )
      searchDataResponse = await getCoreData(
        APIEndPoints?.Warrant?.getWarrantCaseSearch?.method,
        getURL,
        postData
      )
    }
    if (searchDataResponse?.status === utils?.apiResponseCode?.successCode) {
      setSearchCaseData(searchDataResponse?.data)
    } else if (
      searchDataResponse?.status === utils?.apiResponseCode?.internalServerError
    ) {
      setSearchCaseData(null)
      showToastMessage({
        message:
          (searchDataResponse?.data?.validationErrors?.length > 0 &&
            searchDataResponse?.data?.validationErrors[0]?.errorMessage) ||
          searchDataResponse?.data?.message,
        status: 'error',
      })
    } else if (
      searchDataResponse?.status === utils?.apiResponseCode.badRequest
    ) {
      setSearchCaseData(null)
      showToastMessage({
        message:
          (searchDataResponse?.data?.validationErrors?.length > 0 &&
            searchDataResponse?.data?.validationErrors[0]?.errorMessage) ||
          searchDataResponse?.data?.message,
        status: 'error',
      })
    } else {
      setSearchCaseData(null)
      showToastMessage({
        message:
          searchDataResponse?.data?.validationErrors?.length > 0 &&
          searchDataResponse?.data?.validationErrors[0]?.errorMessage,
        status: 'error',
      })
    }
  }

  const onAddSearchResult = () => {
    if (searchCaseData !== null) {
      setSearchCaseAddList((prevList) => [...prevList, searchCaseData])
      onSearchCaseReset()
    }
  }

  const deleteRejectItem = (index) => {
    setSearchCaseAddList((prevList) => prevList.filter((_, i) => i !== index))
  }
  const downloadBatchfile = async () => {
    try {
      const fileresponse = await fetch(searchData?.fileLocation)
      const blob = await fileresponse.blob()

      // Create a temporary URL for the blob
      const url = URL.createObjectURL(blob)

      // Create a link element and click it programmatically to start the download
      const link = document.createElement('a')
      link.href = url
      link.download = `${searchData?.batchReference}.zip`
      link.click()

      // Cleanup the temporary URL
      URL.revokeObjectURL(url)
    } catch (error) {
      showToastMessage({ message: error, status: 'error' })
    }
  }

  /** Domain URL - Start */
  const initialDomainURL = {
    addDomainURL: '',
  }
  const [formValueDomainURL, setFormValueDomainURL] = useState({
    ...initialDomainURL,
  })

  const handleInputChangeAddDomainURL = (event, params) => {
    setFormValueDomainURL({ ...formValues, addDomainURL: params?.value })
  }

  const options = useMemo(
    () => ({
      removeInvalidCharacters: true,
    }),
    []
  )
  const file = useMemo(() => ({ data: searchData }), [searchData])

  function isValidHttpUrl(string) {
    /*  var res = string.match(
      /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g
    )

    return res !== null */
    // Regular expression for matching URLs
    const regex =
      /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/i
    return regex.test(string)

    // Test the string against the regex
    return regex.test(string)
  }
  const disableAddDomainURLSaveButton = () => {
    const urlValidateion = isValidHttpUrl(formValueDomainURL?.addDomainURL)
    if (urlValidateion)
      if (formValueDomainURL?.addDomainURL !== '') {
        return false
      }
    return true
  }
  const onAddDomainURL = () => {
    setPopUpAddDomainURL(formValueDomainURL)
    setmodalOpen(false)
  }
  /** Domain URL - End */
  return (
    <Backdrop
      className={`backdrop ${
        modaltype === 'addZone' ? 'addZoneBackdrop' : ''
      } ${
        modaltype === 'WarrantExecutedPopup'
          ? 'WarrantExecutedPopupBackdrop'
          : ''
      } ${
        modaltype === 'DebtExecutedPopup' ? 'WarrantExecutedPopupBackdrop' : ''
      } ${hideWarrantProcessPopup ? 'hideMainPopup' : ''}`}
      open={promiseInProgress === true || open}
    >
      <>
        {modaltype === 'approvalQueueList' ||
        (modaltype === 'TemplateList' && isPreview === true) ? (
          !templateReject ? (
            <div className="modalmainpage_approvalQueueList">
              <div className="searchmadaltitlebar">
                <div className="modaltitle">
                  {(() => {
                    if (
                      modaltype === 'approvalQueueList' ||
                      (modaltype === 'TemplateList' && isPreview === true)
                    ) {
                      return `${selectTab}`
                    }
                  })()}
                </div>
                <div className="headericonright">
                  <div className="butZoom">
                    <div
                      className="zoomInicon"
                      onClick={handleZoomIn}
                      disabled={divDisable}
                    >
                      <ZoomIn
                        sx={{
                          width: '25px',
                          height: '25px',
                          color: '#A8A8A8',
                        }}
                      />
                    </div>
                    <div
                      className="zoomOuticon"
                      onClick={handleZoomOut}
                      disabled={divDisable}
                    >
                      <ZoomOut
                        sx={{
                          width: '25px',
                          height: '25px',
                          color: '#A8A8A8',
                        }}
                      />
                    </div>
                  </div>
                  <div className="closeicon" onClick={handleClose}>
                    <CloseIcon
                      sx={{
                        width: '18px',
                        height: '18px',
                        color: '#000000',
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`${
                  modaltype === 'approvalQueueList' ||
                  (modaltype === 'TemplateList' && isPreview === true)
                    ? 'modalbodyApprovalQueue'
                    : 'modalbody'
                }`}
              >
                {(() => {
                  if (
                    (modaltype === 'approvalQueueList' ||
                      (modaltype === 'TemplateList' && isPreview === true)) &&
                    !templateReject
                  ) {
                    return (
                      <div className="previewContainer_main">
                        {searchData ? (
                          <div className="previewContainer">
                            <Document
                              file={file}
                              onLoadSuccess={({ numPages }) =>
                                setNumPages(numPages)
                              }
                              options={options}
                              loading={<CustomLoading />}
                            >
                              {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                  size="A4"
                                  key={`page_${index + 1}`}
                                  pageNumber={index + 1}
                                  renderTextLayer={false}
                                  scale={scale}
                                  renderAnnotationLayer={false}
                                  wrap
                                ></Page>
                              ))}
                            </Document>
                          </div>
                        ) : (
                          <div className="previewContainer">
                            <div id="container_preview" />
                          </div>
                        )}
                      </div>
                    )
                  } else {
                    return ''
                  }
                })()}
              </div>
              {modaltype === 'approvalQueueList' ? (
                <div className="sendCorrespondenceSaveCancel">
                  <Button
                    Field={{
                      fieldValue: 'Reject',
                      fieldLabel: 'Reject',
                      variant: 'secondary',
                      //disabled: disableSendCorrespondenceButton,
                    }}
                    className="btnReject"
                    onClickHandler={() => onReject()}
                  ></Button>
                  <Button
                    Field={{
                      fieldValue: 'Approve',
                      fieldLabel: 'Approve',
                      variant: 'primary',
                      //disabled: disableSendCorrespondenceButton,
                    }}
                    className="btnSave"
                    onClickHandler={() => onAccept()}
                  ></Button>
                </div>
              ) : null}
            </div>
          ) : null
        ) : modaltype !== 'TemplateList' && isPreview !== true ? (
          <div className={`modalmainpage  ${modaltype}  ${isPreview}`}>
            <div className="searchmadaltitlebar">
              <div className="modaltitle">
                {(() => {
                  if (modaltype === 'notestab' || modaltype === 'notestabAdd') {
                    return `${selectedcasedata?.caseNumber}`
                  } else if (modaltype === 'sendCorrespondenceAddress') {
                    return `Select Address`
                  } else if (
                    modaltype === 'approvalQueueList' ||
                    (modaltype === 'TemplateList' && isPreview === true)
                  ) {
                    return `${searchData?.template}`
                  } else if (modaltype === 'bellNotification') {
                    return searchData?.notificationType
                      ?.toString()
                      .toLowerCase() === 'ceotask'
                      ? searchData?.notificationTitle
                      : 'Notification'
                  } else if (modaltype === 'addZone') {
                    return `${
                      methodName?.toString().toLowerCase() === 'post'
                        ? 'Add'
                        : 'Edit'
                    } Zone`
                  } else if (
                    modaltype === 'WarrantExecutedPopup' ||
                    modaltype === 'DebtExecutedPopup'
                  ) {
                    return `TEC Registration Processing - ${searchData?.batchReference}`
                  } else if (
                    modaltype === 'WarrantProcessedPopup' ||
                    modaltype === 'DebtProcessedPopup'
                  ) {
                    return `Registration Batch - ${searchData?.batchReference}`
                  } else if (modaltype === 'SearchBar') {
                    return `${searchData?.searchtext}-Search results`
                  } else if (
                    modaltype?.toLowerCase() === 'addbrandingurl' ||
                    modaltype?.toLowerCase() === 'addbrandingpaymenturl'
                  ) {
                    return `Add URL`
                  } else if (modaltype?.toLowerCase() === 'adddomainurl') {
                    return `Add Domain`
                  } else {
                    return ''
                  }
                })()}
                {/* {modaltype === 'notestab' || modaltype === 'notestabAdd'
                ? `${selectedcasedata?.caseNumber}`
                : `${searchData?.searchtext}-Search results`} */}
              </div>
              <div className="closeicon" onClick={handleClose}>
                <CloseIcon
                  sx={{
                    width: '18px',
                    height: '18px',
                    color: '#000000',
                  }}
                />
              </div>
            </div>
            <div className="modalbody">
              {(() => {
                if (modaltype === 'notestab') {
                  return (
                    <div className="notesmaindiv">
                      <div className="notessubheaderdiv">Notes</div>
                      <TextBox
                        id="txt_notesdescription"
                        field={{
                          fieldLabel: '',
                          fieldValue: 'notesdescription',
                          className: 'notesdescription',
                          disabled: false,
                          multiline: true,
                          rows: 7,
                        }}
                        dataTestId="contraventionDescription"
                        height="100px"
                        value={showmodal?.modaldata}
                      />
                    </div>
                  )
                } else if (modaltype === 'notestabAdd') {
                  return (
                    <div className="addNotesmaindiv">
                      <div className="addNotessubheaderdiv">Add Notes</div>
                      <Multiline
                        id="txt_note"
                        name="txt_note"
                        placeHolder="Enter Notes"
                        fieldValue="note"
                        value={values?.note}
                        inputLength={utils?.maxNotesLength}
                        isDisablemaxlength={true}
                        minRowsCount="10"
                        maxRowsCount="10"
                        required={true}
                        className={
                          addnotesError && addnotesError?.toString()?.length > 0
                            ? 'outlineerror colWiseControl'
                            : 'colWiseControl'
                        }
                        onChangeHandler={onChangeHandler}
                        showcount={false}
                        helperText={addnotesError}
                        dataTestId="dialog_txtNotes"
                      />
                      <div className="span_Label">
                        <span className="required">* </span>Notes saved cannot
                        be edited or deleted
                      </div>
                      <div className="addNotesButton">
                        <Button
                          Field={{
                            fieldValue: 'Cancel',
                            fieldLabel: 'Cancel',
                            variant: 'secondary',
                          }}
                          className="addNotes_Cancel"
                          onClickHandler={() => {
                            onCancelAddNotes()
                          }}
                          dataTestId="dialog_addNotesCancel"
                        ></Button>
                        <Button
                          Field={{
                            fieldValue: 'Save',
                            fieldLabel: 'Save',
                            variant: 'primary',
                            disabled: disableNotes,
                          }}
                          className="addNotes_Save"
                          onClickHandler={() => {
                            onSaveAddNotes()
                          }}
                          dataTestId="dialog_addNotesSave"
                        ></Button>
                      </div>
                    </div>
                  )
                } else if (modaltype === 'sendCorrespondenceAddress') {
                  return (
                    <div className="sendCorrespondenceAddress">
                      <div className="sendCorrespondenceAddressGrid">
                        <Grid
                          dataSource={addressDataSource}
                          data={addressDataSource?.rowData}
                        />
                      </div>
                      <div className="sendCorrespondenceAddressButton">
                        <Button
                          Field={{
                            fieldValue: 'Cancel',
                            fieldLabel: 'Cancel',
                            variant: 'secondary',
                          }}
                          className="sendCorrespondenceAddress_Cancel"
                          onClickHandler={() => {
                            onCancelSendCorrespondence()
                          }}
                          dataTestId="dialog_sendCorrespondenceAddress"
                        ></Button>
                        <Button
                          Field={{
                            fieldValue: 'Select',
                            fieldLabel: 'Select',
                            variant: 'primary',
                            disabled: !checkedAddressRadioId,
                          }}
                          className="sendCorrespondenceAddress_Select"
                          onClickHandler={() => {
                            onSelectSendCorrespondenceAddress()
                          }}
                          dataTestId="dialog_sendCorrespondenceAddress"
                        ></Button>
                      </div>
                    </div>
                  )
                } else if (modaltype === 'bellNotification') {
                  return (
                    <div
                      className="divMainBellNotificationMessage"
                      data-testid="divMainBellNotificationMessage"
                    >
                      <div className="divBellNotificationMessage">
                        {searchData?.notificationType
                          ?.toString()
                          .toLowerCase() === 'ceotask' ? (
                          <>
                            <div className="divLine"></div>
                            <div className="divOrgContract">
                              <div>
                                <div className="divNotificationTitle">
                                  {staticCode?.organisation}
                                </div>
                                <div>{searchData?.organisationName}</div>
                              </div>
                              <div>
                                <div className="divNotificationTitle">
                                  {staticCode?.contract}
                                </div>
                                <div>{searchData?.contractName}</div>
                              </div>
                            </div>

                            <div className="divLine"></div>
                            <div>
                              <div className="bellNotificationMessageText">
                                <div className="divNotificationTitle">
                                  {staticCode?.message}
                                </div>
                                <div>
                                  {(() => {
                                    const splitString =
                                      searchData?.message?.split('at')
                                    const lastIndex = splitString?.length - 1
                                    const remainingValues = splitString?.slice(
                                      0,
                                      lastIndex
                                    )
                                    const concatenatedValues =
                                      remainingValues?.join('at')

                                    const lastValue =
                                      concatenatedValues +
                                      'at ' +
                                      utils?.convertUtcDateTimeToTimezoneDateTime(
                                        timezoneData?.selectedContract
                                          ?.timezoneId,
                                        searchData?.createdOn
                                      )
                                    return lastValue
                                  })()}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <p className="bellNotificationMessageText">
                            {searchData?.message}
                          </p>
                        )}
                      </div>
                      {searchData?.notificationType
                        ?.toString()
                        ?.toLowerCase() !== 'ceotask' ? (
                        <div className="notificationContentDate">
                          {utils?.convertUtcDateTimeToTimezoneDateTime(
                            timezoneData?.selectedContract?.timezoneId,
                            searchData?.expiryStartDate
                          )}
                        </div>
                      ) : null}
                    </div>
                  )
                } else if (modaltype === 'addZone') {
                  return (
                    <div className="divZoneMainPopup">
                      <div className="divZonePopup">
                        <div className="addZone_field_col">
                          {getLabel('Organisation', true)}
                          <AutoComplete
                            id="org_Id"
                            dataTestId={
                              orgDataSource?.length > 0
                                ? 'zone_org_Id'
                                : 'zone_org_Id_Nodata'
                            }
                            field={{
                              fieldLabel: '',
                              fieldValue: 'organisation',
                              placeholder: 'Select organisation name',
                              dataSource: orgDataSource,
                              valueField: 'id',
                              textField: 'name',
                              isSelect: false,
                              disabled: false,
                              className:
                                zoneOrganisationError &&
                                zoneOrganisationError?.toString()?.length > 0
                                  ? 'outlineError colWiseControl'
                                  : 'colWiseControl',
                              fieldAlert: zoneOrganisationError,
                            }}
                            value={formValues?.organisation}
                            onChangeHandler={handleInputChangeAddZone}
                          />
                        </div>
                        <div className="addZone_field_col">
                          {getLabel('Contract', true)}
                          <AutoComplete
                            dataTestId={
                              contractsByOrganisation?.length > 0
                                ? 'zone_contract_Id'
                                : 'zone_contract_Id_Nodata'
                            }
                            field={{
                              fieldLabel: '',
                              fieldValue: 'contract',
                              placeholder: 'Select contract',
                              dataSource: contractsByOrganisation,
                              valueField: 'id',
                              textField: 'name',
                              isSelect: false,
                              disabled: false,
                            }}
                            value={formValues?.contract}
                            onChangeHandler={handleInputChangeAddZone}
                            onFocus={handleInputFocusAddZone}
                          />
                        </div>
                        <div className="addZone_field_col">
                          {getLabel('Zone Name', true)}
                          <TextBox
                            id="txt_zoneName"
                            field={{
                              fieldValue: 'zoneName',
                              disabled: false,
                              placeholder: 'Enter zone name',
                              className:
                                zoneNameError &&
                                zoneNameError?.toString()?.length > 0
                                  ? 'outlineError colWiseControl'
                                  : 'colWiseControl',
                              fieldAlert: zoneNameError,
                            }}
                            dataTestId="zone_zonename"
                            value={formValues?.zoneName}
                            onChangeHandler={handleInputChangeAddZone}
                          />
                        </div>
                        <div className="addZone_field_col">
                          {getLabel('Zone Code', false)}
                          <TextBox
                            id="txt_zoneCode"
                            field={{
                              fieldValue: 'zoneCode',
                              disabled: false,
                              placeholder: 'Enter zone code',
                              className:
                                zoneCodeError &&
                                zoneCodeError?.toString()?.length > 0
                                  ? 'outlineError colWiseControl'
                                  : 'colWiseControl',
                              fieldAlert: zoneCodeError,
                            }}
                            dataTestId="zone_zonecode"
                            value={formValues?.zoneCode}
                            onChangeHandler={handleInputChangeAddZone}
                          />
                        </div>
                      </div>
                      <div className="divZoneButton">
                        <Button
                          Field={{
                            fieldValue: 'Cancel',
                            fieldLabel: 'Cancel',
                            variant: 'secondary',
                            disabled: disableCancelButton(),
                          }}
                          className="saveButton"
                          onClickHandler={() => {
                            onAddZoneCancel()
                            setIsClose(false)
                          }}
                          dataTestId="zone_btnCancel"
                        />
                        <Button
                          Field={{
                            fieldValue: `${
                              methodName?.toString().toLowerCase() === 'post'
                                ? 'Add'
                                : 'Update'
                            }`,
                            fieldLabel: `${
                              methodName?.toString().toLowerCase() === 'post'
                                ? 'Add'
                                : 'Update'
                            }`,
                            variant: 'primary',
                            disabled: disableSaveButton(),
                          }}
                          className="saveButton"
                          onClickHandler={() => {
                            onAddZone()
                          }}
                          dataTestId="zone_btnAdd"
                        />
                      </div>
                    </div>
                  )
                } else if (
                  modaltype === 'WarrantExecutedPopup' ||
                  modaltype === 'DebtExecutedPopup'
                ) {
                  return (
                    <>
                      <div className="messageModalRadio">
                        <RadioGroupControl
                          field={{
                            fieldValue: 'MessageType',
                            id: 'rgc_MessageType',
                            dataTestId: 'rgc_MessageType',
                            dataSource: messageDataSource,
                            valueField: 'id',
                            textField: 'name',
                          }}
                          onChangeHandler={onChangeMessageType}
                        />
                        {isSomeRejectedVisible && (
                          <div className="divSomeRejected">
                            <div className="divSearchCase">
                              <div className="divtxtSearchCase">
                                <TextBox
                                  id="txt_Search"
                                  field={{
                                    fieldValue: 'searchCase',
                                    placeholder: 'Enter case number',
                                  }}
                                  dataTestId="someRejected_casenumber"
                                  value={formValuesWarrant?.searchCase}
                                  onChangeHandler={handleInputChangeAddZone}
                                />
                              </div>
                              <div className="divbtnSearchCase">
                                <Button
                                  Field={{
                                    fieldValue: 'Search',
                                    fieldLabel: 'Search',
                                    variant: 'primary',
                                    disabled: disableSaveButton(),
                                  }}
                                  className="saveButton"
                                  onClickHandler={() => {
                                    onSearchCase()
                                  }}
                                  dataTestId="warrantExecutedPopup_btnProcessBatch"
                                />
                                <Button
                                  Field={{
                                    fieldValue: 'Reset',
                                    fieldLabel: 'Reset',
                                    variant: 'secondary',
                                  }}
                                  className="saveButton"
                                  onClickHandler={() => {
                                    onSearchCaseReset()
                                  }}
                                  dataTestId="warrantExecutedPopup_btnCancel"
                                />
                              </div>
                            </div>
                            <div className="divSearchResultMain">
                              <div
                                className={`divSearchResult ${
                                  searchCaseData === null
                                    ? 'divSearchResultNoRecords'
                                    : ''
                                }`}
                              >
                                <div className="divSearchResultSub">
                                  <div className="divSearchResultHeading">
                                    PCN
                                  </div>
                                  <div className="divSearchResultData">
                                    {searchCaseData?.caseNumber
                                      ? searchCaseData?.caseNumber
                                      : ' '}
                                  </div>
                                </div>
                                <div className="divSearchResultSub">
                                  <div className="divSearchResultHeading">
                                    Group
                                  </div>
                                  <div className="divSearchResultData">
                                    {searchCaseData?.group
                                      ? searchCaseData?.group
                                      : ' '}
                                  </div>
                                </div>
                                <div className="divSearchResultSub">
                                  <div className="divSearchResultHeading">
                                    Stage
                                  </div>
                                  <div className="divSearchResultData">
                                    {searchCaseData?.stage
                                      ? searchCaseData?.stage
                                      : ' '}
                                  </div>
                                </div>
                                <div className="divSearchResultSub">
                                  <div className="divSearchResultHeading">
                                    Status
                                  </div>
                                  <div className="divSearchResultData">
                                    {searchCaseData?.status
                                      ? searchCaseData?.status
                                      : ' '}
                                  </div>
                                </div>
                              </div>
                              {searchCaseData === null && (
                                <div className="divNoRecords">No records</div>
                              )}
                            </div>
                            <div className="divAddSearchResult">
                              <Button
                                Field={{
                                  fieldValue: 'Add',
                                  fieldLabel: 'Add',
                                  endIcon: <Add className="addSearchResult" />,
                                  disabled: disableSearchResultAddButton(),
                                }}
                                className="addSearchResultButton"
                                onClickHandler={() => {
                                  onAddSearchResult()
                                }}
                                dataTestId="add_SearchResult"
                              />
                            </div>
                            <div className="divRejectMain">
                              <div className="divRejectTitle">Rejected</div>
                              <div className="divRejectSub">
                                <div className="divRejectSubTitle">PCN</div>
                                <div className="divRejectList">
                                  <div className="divRejectListItem">
                                    {searchCaseAddList.length === 0 && (
                                      <div className="divRejectListItemRow divNoRecordsMain">
                                        <div className="divNoRecords">
                                          No records
                                        </div>
                                      </div>
                                    )}
                                    {searchCaseAddList &&
                                      searchCaseAddList.map((item, index) => (
                                        <>
                                          <div className="divRejectListItemRow">
                                            {item?.caseNumber}
                                            <ReactSVG
                                              src={DeleteIconFill}
                                              onClick={() =>
                                                deleteRejectItem(index)
                                              }
                                              className="svg-buttonActive"
                                            />
                                          </div>
                                        </>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="divWarrantExecutedPopupButton">
                          <div className="WarrantExecutedPopupDownload">
                            <Button
                              dataTestId="btn_Download"
                              Field={{
                                fieldValue: 'Download',
                                fieldLabel: 'Download',
                                startIcon: <DownloadIcon />,
                                variant: 'secondary',
                                //disabled: disableUploadDownload(),
                              }}
                              className="btnWarrantExecutedPopupDownload"
                              onClickHandler={() => downloadBatchfile()}
                            ></Button>
                          </div>
                          <div className="WarrantExecutedPopupSaveCancel">
                            <Button
                              Field={{
                                fieldValue: 'Cancel',
                                fieldLabel: 'Cancel',
                                variant: 'secondary',
                                //disabled: disableCancelButton(),
                              }}
                              className="saveButton"
                              onClickHandler={() => {
                                onWarrantExecutedPopupCancel()
                              }}
                              dataTestId="warrantExecutedPopup_btnCancel"
                            />
                            <Button
                              Field={{
                                fieldValue: 'Process Batch',
                                fieldLabel: 'Process Batch',
                                variant: 'primary',
                                disabled: disableWarrantProcessButton(),
                              }}
                              className="saveButton"
                              onClickHandler={() => {
                                onProcessBatch()
                              }}
                              dataTestId="warrantExecutedPopup_btnProcessBatch"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )
                } else if (
                  modaltype === 'WarrantProcessedPopup' ||
                  modaltype === 'DebtProcessedPopup'
                ) {
                  return (
                    <>
                      <div className="divWarrantExecutedMain">
                        <div className="divSearchResult">
                          <div>
                            <div className="divSearchResultHeading">
                              Processed By
                            </div>
                            <div className="divSearchResultData">
                              {deptwarrantProcessedData?.data?.emailAddress}
                            </div>
                          </div>
                          <div>
                            <div className="divSearchResultHeading">
                              Processed On
                            </div>
                            <div className="divSearchResultData">
                              {utils?.convertUtcDateTimeToTimezoneDateTime(
                                timezoneData?.selectedContract?.timezoneId,
                                deptwarrantProcessedData?.data?.processedDate
                              )}
                            </div>
                          </div>
                          <div>
                            <div className="divSearchResultHeading">
                              Accepted
                            </div>
                            <div className="divSearchResultData">
                              {' '}
                              {deptwarrantProcessedData?.data?.accepted}
                            </div>
                          </div>
                          <div>
                            <div className="divSearchResultHeading">
                              Rejected
                            </div>
                            <div className="divSearchResultData">
                              {' '}
                              {deptwarrantProcessedData?.data?.rejected}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="divWarrantExecutedPopupButton">
                            <div className="WarrantExecutedPopupDownload">
                              <Button
                                dataTestId="btn_Download"
                                Field={{
                                  fieldValue: 'Download',
                                  fieldLabel: 'Download',
                                  startIcon: <DownloadIcon />,
                                  variant: 'secondary',
                                  //disabled: disableUploadDownload(),
                                }}
                                className="btnWarrantExecutedPopupDownload"
                                onClickHandler={() => {
                                  downloadBatchfile()
                                }}
                              ></Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                } else if (
                  modaltype?.toLowerCase() === 'addbrandingurl' ||
                  modaltype?.toLowerCase() === 'addbrandingpaymenturl'
                ) {
                  return (
                    <div className="brandingURLDiv">
                      <TextBox
                        id="txt_brandingURL"
                        field={{
                          fieldValue: 'brandingURL',
                          placeholder: 'Enter URL',
                          className: 'brandingURL',
                        }}
                        dataTestId="brandingURL"
                        value={formValueBrandingURL?.addURL}
                        onChangeHandler={handleInputChangeAddURL}
                      />

                      <div className="brandingPopupSaveCancel">
                        <Button
                          Field={{
                            fieldValue: 'Cancel',
                            fieldLabel: 'Cancel',
                            variant: 'secondary',
                            //disabled: disableCancelButton(),
                          }}
                          className="saveButton"
                          onClickHandler={() => {
                            onWarrantExecutedPopupCancel()
                          }}
                          dataTestId="brandingPopupSaveCancel_btnCancel"
                        />
                        <Button
                          Field={{
                            fieldValue: 'Save',
                            fieldLabel: 'Save',
                            variant: 'primary',
                            disabled: disableAddURLSaveButton(),
                          }}
                          className="saveButton"
                          onClickHandler={() => {
                            onAddURL()
                          }}
                          dataTestId="brandingPopupSaveCancel_btnSave"
                        />
                      </div>
                    </div>
                  )
                } else if (modaltype?.toLowerCase() === 'adddomainurl') {
                  return (
                    <div className="addDomainURLDiv">
                      <TextBox
                        id="txt_brandingURL"
                        field={{
                          fieldValue: 'brandingURL',
                          placeholder: 'Enter domain',
                          className: 'brandingURL',
                        }}
                        dataTestId="addDomainURLInput"
                        value={formValueDomainURL?.addDomainURL}
                        onChangeHandler={handleInputChangeAddDomainURL}
                      />

                      <div className="domainURLPopupSaveCancel">
                        <Button
                          Field={{
                            fieldValue: 'Cancel',
                            fieldLabel: 'Cancel',
                            variant: 'secondary',
                          }}
                          className="saveButton"
                          onClickHandler={() => {
                            onWarrantExecutedPopupCancel()
                          }}
                          dataTestId="domainPopupSaveCancel_btnCancel"
                        />
                        <Button
                          Field={{
                            fieldValue: 'Save',
                            fieldLabel: 'Save',
                            variant: 'primary',
                            disabled: disableAddDomainURLSaveButton(),
                          }}
                          className="saveButton"
                          onClickHandler={() => {
                            onAddDomainURL()
                          }}
                          dataTestId="domainPopupSaveCancel_btnSave"
                        />
                      </div>
                    </div>
                  )
                } else if (modaltype === 'SearchBar') {
                  return (
                    <div
                      className={`${
                        modaltype === 'SearchBar'
                          ? 'searchBarModalbody'
                          : 'modalbody'
                      }`}
                    >
                      <Grid
                        dataSource={datasource}
                        data={datasource?.rowData}
                      />
                    </div>
                  )
                }
              })()}
            </div>
          </div>
        ) : null}

        <div className="notes_dialog_dataSaveWarning">
          <Dialog
            id="dialog_dataSaveWarning"
            open={dataSaveWarning}
            className="notes dataSaveWarning noTitle_CenterAlignedClass"
          >
            <DialogContent>{utils?.dataSaveWarnMessage}</DialogContent>
            <DialogActions>
              <Button
                Field={{
                  fieldValue: 'No',
                  fieldLabel: 'No',
                  variant: 'secondary',
                }}
                className="saveButton"
                onClickHandler={() => {
                  setDataSaveWarning(false)
                  //window.history.pushState(null, null, window.location.pathname)
                }}
              />
              <Button
                Field={{
                  fieldValue: 'Yes',
                  fieldLabel: 'Yes',
                  variant: 'primary',
                }}
                className="saveButton"
                onClickHandler={() => {
                  setOpen(false)
                  setDataSaveWarning(false)
                  setmodalOpen()
                }}
              />
            </DialogActions>
          </Dialog>

          <Dialog
            id="dialog_Warrant"
            open={warrantSaveWarning}
            className="notes dataSaveWarning noTitle_CenterAlignedClass"
          >
            {/* <DialogTitle></DialogTitle> */}
            <DialogContent>{utils?.processCancel}</DialogContent>
            <DialogActions>
              <Button
                Field={{
                  fieldValue: 'No',
                  fieldLabel: 'No',
                  variant: 'secondary',
                }}
                className="saveButton"
                onClickHandler={() => {
                  setWarrantSaveWarning(false)
                  //setMessageType('SomeRejected')
                }}
              />
              <Button
                Field={{
                  fieldValue: 'Yes',
                  fieldLabel: 'Yes',
                  variant: 'primary',
                }}
                className="saveButton"
                onClickHandler={() => {
                  setWarrantSaveWarning(false)
                  setIsSomeRejectedVisible(false)
                  setSearchCaseAddList([])
                  setmodalOpen(false)
                  setWarrantProcessSaveWarning(false)
                  setHideWarrantProcessPopup(false)
                }}
              />
            </DialogActions>
          </Dialog>

          <Dialog
            id="dialog_Warrant"
            open={warrantProcessSaveWarning}
            className="notes dataSaveWarning"
          >
            {/* <DialogTitle></DialogTitle> */}
            <DialogContent>{utils?.processMessage}</DialogContent>
            <DialogActions>
              <Button
                Field={{
                  fieldValue: 'No',
                  fieldLabel: 'No',
                  variant: 'secondary',
                }}
                className="saveButton"
                onClickHandler={() => {
                  setWarrantProcessSaveWarning(false)
                  setHideWarrantProcessPopup(false)
                  setmodalOpen(false)
                }}
              />
              <Button
                Field={{
                  fieldValue: 'Yes',
                  fieldLabel: 'Yes',
                  variant: 'primary',
                }}
                className="saveButton"
                onClickHandler={() => {
                  setWarrantProcessSaveWarning(false)
                  setHideWarrantProcessPopup(false)
                  setmodalOpen(false)
                  onSaveProcessBatch()
                }}
              />
            </DialogActions>
          </Dialog>
        </div>

        <div className="div_dialog_templateReject">
          <Dialog
            id="dialog_templateReject"
            open={templateReject}
            className="templateReject"
          >
            <DialogTitle>
              <div className="title_reject">{utils?.rejectTitle}</div>
              <div className="closeicon_reject" onClick={handleRejectClose}>
                <CloseIcon />
              </div>
            </DialogTitle>
            <DialogContent>
              <Multiline
                id="txt_reject"
                name="txt_reject"
                placeHolder=""
                fieldValue="reject"
                value={rejectedReason}
                inputLength="50000"
                isDisablemaxlength={true}
                minRowsCount="10"
                maxRowsCount="10"
                required={true}
                onChangeHandler={handleInputChangeReject}
                showcount={false}
                helperText={rejectError}
                className={
                  rejectError && rejectError?.toString()?.length > 0
                    ? 'outlineerror colWiseControl'
                    : 'colWiseControl'
                }
                dataTestId="dialog_reject"
              />
            </DialogContent>
            <DialogActions>
              <Button
                Field={{
                  fieldValue: 'Cancel',
                  fieldLabel: 'Cancel',
                  variant: 'secondary',
                  disabled: disableTemplateButton,
                }}
                className="cancelButton"
                onClickHandler={onRejectReasonCancel}
              />
              <Button
                Field={{
                  fieldValue: 'Save',
                  fieldLabel: 'Save',
                  variant: 'primary',
                  disabled: disableTemplateButton,
                }}
                className="saveButton"
                onClickHandler={onRejectReasonSave}
              />
            </DialogActions>
          </Dialog>
        </div>
      </>
    </Backdrop>
  )
}

export default ReactModal
