/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/*
  In this component we show the animated logo of nps, this can be used in any component or pages
*/
import React from 'react'
import SplashScreenLogo from '../../../../assets/static/NPS_Splash_Screen.gif'
import './SplashComponent.css'

function SplashComponent() {
  return (
    <div className="splashDiv">
      <img className="splashImage" src={SplashScreenLogo} />
    </div>
  )
}
export default SplashComponent
