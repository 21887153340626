/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  This component was used to display the loading bar and screen blocker when we make asynchronous process.
  This BackdropSpinner implemented with Recoil library
  This Recoil library is used to handle the global state value
*/

import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import './BackdropSpinner.css'
import { usePromiseTracker } from 'react-promise-tracker'
import { useRecoilValue } from 'recoil'
import {
  showLoading,
  showLoadingText,
  LoaderOnIndexJsAtom,
} from '../../recoil/atoms/atoms'

//props, ref
function BackdropSpinner() {
  const { promiseInProgress } = usePromiseTracker({ delay: 2000 })
  const showLoader = useRecoilValue(showLoading)
  const showLoadingTextAtom = useRecoilValue(showLoadingText)
  const LoaderOnIndexJs = useRecoilValue(LoaderOnIndexJsAtom)
  return (
    <Backdrop
      className="backdrop"
      open={(promiseInProgress === true || showLoader) && !LoaderOnIndexJs}
      // open={promiseInProgress === true || showLoader} //when LoaderForm index.js is active then no need to show one more loader
    >
      <div className="spinnerAll">
        <div>
          <CircularProgress color="inherit" />
        </div>
        {showLoadingTextAtom?.isShowLoadingText ? (
          <>
            <div className="spinnerText">This will take a while...</div>
          </>
        ) : (
          <></>
        )}
      </div>
    </Backdrop>
  )
}
export default BackdropSpinner
