/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  In this file, we configure the menus name with URL
*/

// System defined variables
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom' //useNavigate
//import { StyledEngineProvider } from '@mui/material/styles'
import Collapse from '@mui/material/Collapse'
import {
  Drawer as MUIDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItemButton,
} from '@mui/material' //IconButton,
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { useConfirm } from 'material-ui-confirm'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import _ from 'lodash'

// Custom defined variables.
import './MenuLayout.css'
import {
  selectedContractDetails,
  selectedMenuData,
  madeChanges,
  selectOrgNavigationDetails,
  mappedUserRoles,
  selectLinkedCaseDetails,
  searchListFormGridData,
  isSidebarExpanded,
} from '../../recoil/atoms/atoms'
import { fetchDataSelector } from '../../recoil/selectors/selectors'
import utils from '../../../../utils/utils'
import { ConfirmPopupRendererObject } from '../confirmPopupRenderer/ConfirmPopupRenderer'
import APIEndPoints from '../../../../models/api/apiEndpoints'
// import HaloSupportTicket from '../../../pages/home/HaloSupportTicket'
// import SupportMenuIconInActive from '../../../../assets/images/supportMenuIcon.svg'
// import SupportMenuIconActive from '../../../../assets/images/supportMenuIconActive.svg'

function Reducer(state, action) {
  switch (action.type) {
    case 'set_Home':
      return {
        ...state,
        ...action.payload,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

// ref,props = {}
function MenuLayout() {
  const location = useLocation()
  const navigate = useNavigate()
  const getCoreData = useRecoilValue(fetchDataSelector)
  let currentsubmenu = null
  if (location.pathname === '/') {
    localStorage.setItem('navItemId', null)
  }

  const initialState = {
    openDrawer: true,
    openNavGroup: false,
    anchorEl: null,
    selectedNavsecItemId: null,
    menuAnchorEl: null,
  }

  const [state, dispatch] = React.useReducer(Reducer, initialState)
  const [openCollapse, setOpenCollapseSubmenu] = React.useState({})
  const selectedContractData = useRecoilValue(selectedContractDetails)
  const userRolesData = useRecoilValue(mappedUserRoles)
  const setSelectedMenuData = useSetRecoilState(selectedMenuData)
  const getSelectedMenuData = useRecoilValue(selectedMenuData)
  const hasChanges = useRecoilValue(madeChanges)
  const [menuEnableCheck, setMenuEnableCheck] = useState(false)
  const confirmAlert = useConfirm()

  const [menuNavigation, setMenuNavigation] = useState([])
  const [selectNavigationDetails, setSelectNavigationDetails] = useRecoilState(
    selectOrgNavigationDetails
  )
  const [selectLinkedCaseDetailsData, setSelectLinkedCaseDetails] =
    useRecoilState(selectLinkedCaseDetails)
  const [selectSearchListFormGridData, setSearchListFormGridData] =
    useRecoilState(searchListFormGridData)
  const [supportMenuSelected, setSupportMenuSelected] = useState(false)
  const [isMenuBarExpanded, setMenuBarExpanded] =
    useRecoilState(isSidebarExpanded) // used to show confirm dialogue when changes happend in other pages
  const contractType = selectedContractData?.contractType
    ?.toString()
    ?.toLowerCase()
  /* istanbul ignore next */
  const checkAllowNavigation = () => {
    if (hasChanges) {
      return confirmAlert({
        ...ConfirmPopupRendererObject(null, utils?.dataSaveWarnMessage),
      })
        .then(() => {
          return true
        })
        .catch(() => {
          return false
        })
    } else return true
  }
  useEffect(() => {
    document
      .querySelectorAll('.previousmenu')
      ?.forEach((premenu) => premenu?.classList?.remove('previousmenu'))

    document
      .querySelectorAll('.nextmenu')
      ?.forEach((premenu) => premenu?.classList?.remove('nextmenu'))

    document
      .querySelectorAll('.previousmenuindex1')
      ?.forEach((premenu) => premenu?.classList?.remove('previousmenuindex1'))

    const allacnhormenus = document.querySelectorAll('a')

    allacnhormenus?.forEach((ele, idx) => {
      if (
        ele?.classList?.contains('active') ||
        ele?.classList?.contains('activeMenugroupradius')
      ) {
        if (state?.menuItemIdx === 1) {
          allacnhormenus[idx - 1]?.classList?.add('previousmenuindex1')
        } else {
          allacnhormenus[idx - 1]?.classList?.add('previousmenu')
        }
        allacnhormenus[idx + 1]?.classList?.add('nextmenu')
      }
    })
    currentsubmenu = localStorage.getItem('selectedsubmenu')
  }, [state])

  const getMenuBasedOnRole = () => {
    setMenuNavigation(() => [])

    const checkRoleValid = (menuRole, userRole) => {
      let roleValid = false
      for (let roleIndex = 0; roleIndex < userRole?.length; roleIndex++) {
        if (menuRole?.includes(userRole[roleIndex])) roleValid = true
      }
      return roleValid
    }

    for (let menuIndex = 0; menuIndex < utils?.menuList?.length; menuIndex++) {
      for (let roleIndex = 0; roleIndex < userRolesData?.length; roleIndex++) {
        if (
          utils?.menuList[menuIndex]?.roles?.filter(
            (roleName) =>
              roleName?.toString()?.toLowerCase() ===
              userRolesData[roleIndex]?.toString()?.toLowerCase()
          )?.length > 0 &&
          utils?.menuList[menuIndex]?.contractRequired ===
            Boolean(selectedContractData?.contractId) &&
          (utils?.menuList[menuIndex]?.contractType?.includes(
            selectedContractData?.contractType?.toString()?.toLowerCase()
          ) ||
            !selectedContractData?.contractType)
        ) {
          const onlysubmenu = utils?.menuList[menuIndex]?.MenuGroupitem?.filter(
            (submenu) =>
              (submenu?.contractType?.includes(
                selectedContractData?.contractType?.toString()?.toLowerCase()
              ) ||
                !selectedContractData?.contractType) &&
              (checkRoleValid(submenu?.roles, userRolesData) || !submenu?.roles)
          )
          if (
            utils?.menuList[menuIndex]?.isOfficerTracking ||
            utils?.menuList[menuIndex]?.isManualCaseEnabled ||
            utils?.menuList[menuIndex]?.isCandREnabled
          ) {
            if (
              (utils?.menuList[menuIndex]?.isOfficerTracking &&
                selectedContractData?.isOfficerTracking) ||
              (utils?.menuList[menuIndex]?.isManualCaseEnabled &&
                selectedContractData?.isManualCaseEnabled) ||
              (utils?.menuList[menuIndex]?.isCandREnabled &&
                selectedContractData?.isCandREnabled)
            ) {
              if (onlysubmenu) {
                setMenuNavigation((prevMenu) => [
                  ...prevMenu,
                  {
                    ...utils?.menuList[menuIndex],
                    menuItem: utils?.menuList[menuIndex]?.menuItem?.replace(
                      '__SelectedContractName__',
                      selectedContractData?.contractName
                    ),
                    MenuGroupitem: [...onlysubmenu],
                  },
                ])
              } else {
                setMenuNavigation((prevMenu) => [
                  ...prevMenu,
                  {
                    ...utils?.menuList[menuIndex],
                    menuItem: utils?.menuList[menuIndex]?.menuItem?.replace(
                      '__SelectedContractName__',
                      selectedContractData?.contractName
                    ),
                  },
                ])
              }
            }
            break
          } else {
            if (onlysubmenu) {
              setMenuNavigation((prevMenu) => [
                ...prevMenu,
                {
                  ...utils?.menuList[menuIndex],
                  menuItem: utils?.menuList[menuIndex]?.menuItem?.replace(
                    '__SelectedContractName__',
                    selectedContractData?.contractName
                  ),
                  MenuGroupitem: [...onlysubmenu],
                },
              ])
            } else {
              setMenuNavigation((prevMenu) => [
                ...prevMenu,
                {
                  ...utils?.menuList[menuIndex],
                  menuItem: utils?.menuList[menuIndex]?.menuItem?.replace(
                    '__SelectedContractName__',
                    selectedContractData?.contractName
                  ),
                },
              ])
            }
            break
          }
        }
      }
    }
  }

  useEffect(() => {
    getMenuBasedOnRole()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (Object.keys(getSelectedMenuData)?.length > 0) {
        if (
          getSelectedMenuData?.selectedSubMenuId !== '' &&
          getSelectedMenuData?.selectedSubMenuId?.length > 0
        ) {
          dispatch({
            type: 'set_Home',
            payload: {
              menuLoadingIndex: getSelectedMenuData?.menuLoadingIndex,
              anchorEl: null,
              menuAnchorEl: null,
              menuindex: getSelectedMenuData?.menuindex,
              openDrawer: true,
              openNavGroup: false,
              selectedMenuId: getSelectedMenuData?.selectedMenuId,
              selectedNavsecItemId: null,
              selectedSubMenuId: getSelectedMenuData?.selectedSubMenuId,
            },
          })
          setOpenCollapseSubmenu({ [getSelectedMenuData.selectedMenuId]: true })
        } else {
          dispatch({
            type: 'set_Home',
            payload: {
              menuLoadingIndex: getSelectedMenuData?.menuLoadingIndex,
              anchorEl: null,
              menuAnchorEl: null,
              menuindex: getSelectedMenuData?.menuindex,
              openDrawer: true,
              openNavGroup: false,
              selectedMenuId: getSelectedMenuData?.selectedMenuId,
              selectedNavsecItemId: null,
              selectedSubMenuId: '',
            },
          })
          setOpenCollapseSubmenu({ [getSelectedMenuData.selectedMenuId]: true })
        }
      }
    }, 10)
  }, [getSelectedMenuData])

  const checkMenuEnableOrDisable = async () => {
    if (
      selectedContractData?.contractId !== null &&
      selectedContractData?.contractId !== '' &&
      !_.isUndefined(selectedContractData?.contractId)
    ) {
      const caseOverView = await getCoreData(
        APIEndPoints?.Contract?.caseOverView?.method,
        APIEndPoints?.Contract?.caseOverView?.url?.replace(
          '__contractId__',
          selectedContractData?.contractId
        )
      )

      if (
        caseOverView &&
        caseOverView?.status === utils?.apiResponseCode?.successCode
      ) {
        setMenuEnableCheck(caseOverView?.data?.isPreDebtCleanse)
      }
    }
  }

  useEffect(() => {
    checkMenuEnableOrDisable()
    if (!(Object.keys(getSelectedMenuData)?.length > 0)) {
      selectedContractData && selectedContractData?.contractId
        ? dispatch({
            type: 'set_Home',
            payload: {
              menuLoadingIndex: 0,
              selectedMenuId: menuNavigation[0]?.menuItemId,
              selectedNavsecItemId: '',
              menuindex: menuNavigation[0]?.menuindex,
              selectedSubMenuId: '',
            },
          })
        : dispatch({
            type: 'set_Home',
            payload: {
              menuLoadingIndex: 0,
              selectedMenuId: 'Home',
              selectedNavsecItemId: null,
              menuindex: 0,
              selectedSubMenuId: '',
            },
          })
    } else {
      //when click back to home button in 404 page not found page reset selected menu and set the home page
      dispatch({
        type: 'set_Home',
        payload: {
          menuLoadingIndex: 0,
          anchorEl: null,
          menuAnchorEl: null,
          menuindex: 0,
          openDrawer: true,
          openNavGroup: false,
          selectedMenuId: menuNavigation[0]?.menuItemId,
          selectedNavsecItemId: null,
          selectedSubMenuId: '',
        },
      })
      setOpenCollapseSubmenu({})
    }
  }, [menuNavigation])

  useEffect(() => {
    getMenuBasedOnRole()
  }, [selectedContractData])

  // const clickhomebutton = () => {
  //   setSelectedContractDetails({})
  //   //navigate('/')
  // }

  /* if (document.getElementsByClassName('bodypage')[0] !== undefined) {
    // let activeMenuTop = 0
    // if (
    //   document
    //     .getElementsByClassName('activeMenugroupradius')[0]
    //     ?.getBoundingClientRect()
    // ) {
    //   const activeMenu = document
    //     .getElementsByClassName('activeMenugroupradius')[0]
    //     ?.getBoundingClientRect()
    //   activeMenuTop = activeMenu?.top
    // }
    if (
      state?.menuLoadingIndex === 0
      //(activeMenuTop > 0 && activeMenuTop <= 114)
    ) {
      document.getElementsByClassName(
        'bodypage'
      )[0].style.borderTopLeftRadius = 0
    } else if (state?.menuLoadingIndex === 1) {
      document.getElementsByClassName('bodypage')[0].style.borderTopLeftRadius =
        '30px'
    } else {
      document.getElementsByClassName('bodypage')[0].style.borderTopLeftRadius =
        '50px'
    }
  } */

  // Commenting this due to the navigation data getting cleared on page refresh with navigation hierarchy count - For BackArrow story
  /* //below the function used for reset recoil value when change menu item navigation from ogranisation to contract and prefix
  useEffect(() => {
    if (selectNavigationDetails?.length > 0) {
      setSelectNavigationDetails([])
      if (
        getSelectedMenuData?.selectedSubMenuId?.toString()?.toLowerCase() ===
        'organisation'
      ) {
        navigate('/organisationlist')
      }
    }
  }, [getSelectedMenuData]) */
  const checkAndResetNavigationData = () => {
    if (selectNavigationDetails?.length > 0) {
      setSelectNavigationDetails([])
      if (
        getSelectedMenuData?.selectedSubMenuId?.toString()?.toLowerCase() ===
        'organisation'
      ) {
        navigate('/organisationlist')
      }
    }
  }
  //below the useEffect if any select linked case tab store that case in below the state and when direct click menu item will reset atoms value
  useEffect(() => {
    if (location?.pathname !== '/pcnpage') {
      if (selectLinkedCaseDetailsData?.length > 0) {
        setSelectLinkedCaseDetails([])
      }
      //below the useEffect if any select search case list and click the case navigate main tap and showing next and previous case prevision so the when click menu item that time will rest tha atom value
      if (selectSearchListFormGridData?.length > 0) {
        setSearchListFormGridData([])
      }
    }
  }, [getSelectedMenuData])

  function onPopupWindowChangeChandler() {
    setSupportMenuSelected(() => false)
  }

  const isPermittedSubmenu = (submenuAccessRoles) => {
    let permitted = false
    for (let roleIndex = 0; roleIndex < userRolesData?.length; roleIndex++) {
      if (
        submenuAccessRoles?.filter((roleName) => {
          return (
            roleName?.toString()?.toLowerCase() ===
            userRolesData[roleIndex]?.toString()?.toLowerCase()
          )
        })?.length > 0
      )
        permitted = true
    }
    return permitted
  }

  function onPopupWindowChangeChandler() {
    setSupportMenuSelected(() => false)
  }

  return (
    <div className={`drawer drawer_${isMenuBarExpanded ? 'open' : 'close'}`}>
      <MUIDrawer
        variant="permanent"
        className={`muidrawer_${isMenuBarExpanded ? 'open' : 'close'}`}
        id="MUIDrawer_Menu"
      >
        <div className="drawer_  v-scroll-auto">
          <List disablePadding className="drawer__mainmenu menu">
            <div className="StaticItem">
              <div className="StaticItemInner" key="divfirstmenu0">
                <ListItem
                  key="firstmenu0"
                  component={Link}
                  className="item-height drawer__mainmenu_item firstmenu"
                >
                  <ListItemText className="navsectionMain" primary="" />
                </ListItem>
                <div className="expandableIcon">
                  {!isMenuBarExpanded ? (
                    <KeyboardDoubleArrowRightIcon
                      className="expandMoreIcon"
                      onClick={() => {
                        setMenuBarExpanded(() => true)
                        dispatch({
                          type: 'set_Home',
                          payload: {
                            openDrawer: true,
                          },
                        })
                      }}
                    />
                  ) : (
                    <KeyboardDoubleArrowLeftIcon
                      className="expandLessIcon"
                      onClick={() => {
                        setMenuBarExpanded(() => false)
                        dispatch({
                          type: 'set_Home',
                          payload: {
                            openDrawer: false,
                          },
                        })
                      }}
                    />
                  )}
                </div>
                {menuNavigation?.map((menuItem, menuIdx) => {
                  if (
                    menuItem?.MenuGroupitem?.length > 0 &&
                    menuItem?.isHavingSubmenu
                  ) {
                    let renderedSubMenuIndex = -1
                    return (
                      <div
                        className={`outeranchorgroup ${
                          state.selectedMenuId?.toString()?.toLowerCase() ===
                          menuItem?.menuItemId?.toString()?.toLowerCase()
                            ? 'active'
                            : ''
                        } ${
                          state.selectedMenuId?.toString()?.toLowerCase() ===
                            menuItem?.menuItemId?.toString()?.toLowerCase() &&
                          openCollapse[menuItem?.menuItemId]
                            ? 'Menugroupradius'
                            : ''
                        }`}
                      >
                        <ListItem
                          key={menuItem?.menuindex}
                          component={Link}
                          onClick={() => {
                            setMenuBarExpanded(() => true)
                            setOpenCollapseSubmenu(() => ({
                              [menuItem?.menuItemId]:
                                state.selectedMenuId
                                  ?.toString()
                                  ?.toLowerCase() ===
                                  menuItem?.menuItemId
                                    ?.toString()
                                    ?.toLowerCase() &&
                                openCollapse[menuItem?.menuItemId]
                                  ? false
                                  : !openCollapse[menuItem?.menuItemId],
                            }))
                            dispatch({
                              type: 'set_Home',
                              payload: {
                                menuLoadingIndex: menuIdx,
                                selectedMenuId: menuItem?.menuItemId,
                                selectedNavsecItemId: null,
                                menuindex: menuItem.menuindex,
                                selectedSubMenuId:
                                  currentsubmenu
                                    ?.split('-')[1]
                                    ?.toString()
                                    ?.toLowerCase() ===
                                  menuItem?.menuItemId
                                    ?.toString()
                                    ?.toLowerCase()
                                    ? currentsubmenu?.split('-')[0]
                                    : '',
                              },
                            })
                          }}
                          to={menuItem?.redirectURL}
                          className={`item-height drawer__mainmenu_item ${
                            state.selectedMenuId?.toString()?.toLowerCase() ===
                            menuItem?.menuItemId?.toString()?.toLowerCase()
                              ? 'active'
                              : ''
                          }${
                            state.selectedMenuId?.toString()?.toLowerCase() ===
                              menuItem?.menuItemId?.toString()?.toLowerCase() &&
                            openCollapse[menuItem?.menuItemId]
                              ? 'Menugroupradius'
                              : ''
                          }`}
                        >
                          <ListItemIcon
                            className={
                              state.selectedMenuId
                                ?.toString()
                                ?.toLowerCase() ===
                              menuItem?.menuItemId?.toLowerCase()
                                ? 'activemenu'
                                : 'inactivemenu'
                            }
                          >
                            <img
                              className="img-logo"
                              src={
                                state.selectedMenuId
                                  ?.toString()
                                  ?.toLowerCase() ===
                                menuItem?.menuItemId?.toString()?.toLowerCase()
                                  ? menuItem?.imageURLactive
                                  : menuItem?.imageURLinactive
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            className={`Menuitemstyle ${
                              state.selectedMenuId
                                ?.toString()
                                ?.toLowerCase() ===
                              menuItem?.menuItemId?.toLowerCase()
                                ? 'activemenu'
                                : 'inactivemenu'
                            }`}
                            primary={menuItem?.menuItem}
                          />
                          {openCollapse[menuItem?.menuItemId] &&
                          state.selectedMenuId?.toString()?.toLowerCase() ===
                            menuItem?.menuItemId?.toLowerCase() ? (
                            <ExpandLess className="expandlessIcon" />
                          ) : (
                            <ExpandMore className="expandmoreIcon" />
                          )}
                        </ListItem>
                        <Collapse
                          className={'MenuItemgroup'}
                          in={
                            menuItem?.openCollapse === true &&
                            openCollapse[menuItem?.menuItemId] &&
                            state.selectedMenuId?.toString()?.toLowerCase() ===
                              menuItem?.menuItemId?.toString()?.toLowerCase()
                          }
                          timeout="auto"
                          cls
                          unmountOnExit
                        >
                          <List className={'subitem'} component="div">
                            {menuItem?.MenuGroupitem.map(
                              (submenuitems, index) => {
                                if (
                                  ((menuItem?.subMenuSpecified &&
                                    isPermittedSubmenu(submenuitems?.roles)) ||
                                    !menuItem?.subMenuSpecified) &&
                                  (submenuitems?.contractType?.includes(
                                    selectedContractData?.contractType
                                      ?.toString()
                                      ?.toLowerCase()
                                  ) ||
                                    !selectedContractData?.contractType)
                                ) {
                                  let listItemText = submenuitems?.menuItem
                                  if (
                                    contractType ===
                                    utils?.contractTypes?.fpnName
                                  ) {
                                    if (
                                      submenuitems?.menuItem ===
                                      utils?.fpnBasedChanges
                                        ?.pcnOperationalReport
                                    ) {
                                      listItemText =
                                        utils?.fpnBasedChanges
                                          ?.fpnOperationalReport
                                    }
                                  }
                                  renderedSubMenuIndex++
                                  return (submenuitems?.displayBasedOnFlagKey &&
                                    selectedContractData?.hasOwnProperty(
                                      submenuitems?.displayBasedOnFlagKey
                                    ) &&
                                    selectedContractData[
                                      submenuitems?.displayBasedOnFlagKey
                                    ]) ||
                                    !submenuitems?.displayBasedOnFlagKey ? (
                                    <ListItemButton
                                      key={submenuitems?.menuItemId}
                                      className={`subMenuitemstyle ${
                                        state.selectedSubMenuId
                                          ?.toString()
                                          ?.toLowerCase() ===
                                        submenuitems?.menuItemId
                                          ?.toString()
                                          ?.toLowerCase()
                                          ? 'active'
                                          : ''
                                      } subMenuIndex${renderedSubMenuIndex} ${
                                        submenuitems?.menuItemId
                                          ?.toString()
                                          ?.toLowerCase() === 'predebt' &&
                                        !menuEnableCheck
                                          ? 'disableMenu'
                                          : ''
                                      }`}
                                      sx={{ pl: 6, gap: 0 }}
                                      onClick={async () => {
                                        const allow =
                                          await checkAllowNavigation()
                                        if (allow) {
                                          localStorage.setItem(
                                            'selectedsubmenu',
                                            `${submenuitems?.menuItemId}-${menuItem?.menuItemId}`
                                          )
                                          setSelectedMenuData({
                                            menuLoadingIndex: menuIdx,
                                            anchorEl: null,
                                            menuAnchorEl: null,
                                            menuindex: menuItem?.menuindex,
                                            openDrawer: true,
                                            openNavGroup: false,
                                            selectedMenuId:
                                              menuItem?.menuItemId,
                                            selectedNavsecItemId: null,
                                            selectedSubMenuId:
                                              submenuitems?.menuItemId,
                                          })
                                          if (
                                            [
                                              '/prefix',
                                              '/organisationlist',
                                              '/contractlistpage',
                                              '/bailiff',
                                            ].includes(
                                              submenuitems?.redirectURL
                                                ?.toString()
                                                ?.toLowerCase()
                                            )
                                          ) {
                                            checkAndResetNavigationData()
                                          }
                                          navigate(submenuitems?.redirectURL)
                                          dispatch({
                                            type: 'set_Home',
                                            payload: {
                                              selectedSubMenuId:
                                                submenuitems?.menuItemId,
                                            },
                                          })
                                        }
                                      }}
                                    >
                                      <ListItemText
                                        className={'subMenuitemstext'}
                                        primary={listItemText}
                                      />
                                    </ListItemButton>
                                  ) : null
                                }
                              }
                            )}
                          </List>
                        </Collapse>
                      </div>
                    )
                  } else {
                    return (
                      !menuItem?.isHavingSubmenu && (
                        <>
                          {(menuItem?.displayBasedOnFlagKey &&
                            selectedContractData?.hasOwnProperty(
                              menuItem?.displayBasedOnFlagKey
                            ) &&
                            selectedContractData[
                              menuItem?.displayBasedOnFlagKey
                            ]) ||
                          !menuItem?.displayBasedOnFlagKey ? (
                            <div
                              className={`outeranchor ${
                                state.selectedMenuId
                                  ?.toString()
                                  ?.toLowerCase() ===
                                menuItem?.menuItemId?.toString()?.toLowerCase()
                                  ? 'active'
                                  : ''
                              }`}
                              id={menuItem?.menuindex}
                            >
                              <ListItem
                                title={menuItem?.menuItem}
                                dataTestId={menuItem?.menuItemId}
                                key={menuItem?.menuindex}
                                component={Link}
                                onClick={async () => {
                                  const allow = await checkAllowNavigation()
                                  if (allow) {
                                    localStorage.setItem(
                                      'selectedsubmenu',
                                      null
                                    )
                                    setSelectedMenuData({
                                      menuLoadingIndex: menuIdx,
                                      anchorEl: null,
                                      menuAnchorEl: null,
                                      menuindex: menuItem?.menuindex,
                                      openDrawer: true,
                                      openNavGroup: false,
                                      selectedMenuId: menuItem?.menuItemId,
                                      selectedNavsecItemId: null,
                                      selectedSubMenuId: '',
                                    })
                                    setOpenCollapseSubmenu(() => ({
                                      [menuItem?.menuItemId]:
                                        state.selectedMenuId
                                          ?.toString()
                                          ?.toLowerCase() ===
                                          menuItem?.menuItemId
                                            ?.toString()
                                            ?.toLowerCase() &&
                                        openCollapse[menuItem?.menuItemId]
                                          ? false
                                          : !openCollapse[menuItem?.menuItemId],
                                    }))
                                    dispatch({
                                      type: 'set_Home',
                                      payload: {
                                        menuLoadingIndex: menuIdx,
                                        selectedMenuId: menuItem?.menuItemId,
                                        selectedNavsecItemId: null,
                                        menuindex: menuItem?.menuindex,
                                        selectedSubMenuId: '',
                                      },
                                    })
                                    setMenuBarExpanded(true)
                                    navigate(menuItem?.redirectURL)
                                  }
                                }}
                                className={`item-height drawer__mainmenu_item ${
                                  state.selectedMenuId
                                    ?.toString()
                                    ?.toLowerCase() ===
                                  menuItem?.menuItemId?.toLowerCase()
                                    ? 'active'
                                    : ''
                                }`}
                              >
                                <ListItemIcon
                                  className={
                                    state.selectedMenuId
                                      ?.toString()
                                      ?.toLowerCase() ===
                                    menuItem?.menuItemId?.toLowerCase()
                                      ? 'activemenu'
                                      : 'inactivemenu'
                                  }
                                >
                                  <img
                                    className="img-logo"
                                    src={
                                      state.selectedMenuId
                                        ?.toString()
                                        ?.toLowerCase() ===
                                      menuItem?.menuItemId
                                        ?.toString()
                                        ?.toLowerCase()
                                        ? menuItem?.imageURLactive
                                        : menuItem?.imageURLinactive
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  className={`Menuitemstyle ${
                                    state.selectedMenuId
                                      ?.toString()
                                      ?.toLowerCase() ===
                                    menuItem?.menuItemId?.toLowerCase()
                                      ? 'activemenu'
                                      : 'inactivemenu'
                                  }`}
                                  primary={menuItem?.menuItem}
                                />
                              </ListItem>
                            </div>
                          ) : null}
                        </>
                      )
                    )
                  }
                })}
                <ListItem
                  component={Link}
                  className="item-height drawer__mainmenu_item"
                >
                  <ListItemText className="navsectionMain" primary="" />
                </ListItem>
              </div>
            </div>
          </List>
        </div>
        <Divider />
      </MUIDrawer>

      {/* {isMenuBarExpanded ? (
        <div className="staticMenus">
          <div className="outeranchor" id="supportTicket">
            <ListItem
              title="Support Request"
              dataTestId="supportTicket"
              key="supportTicket"
              onClick={() => {
                setSupportMenuSelected(() => true)
              }}
              className={`item-height drawer__mainmenu_item`}
            >
              <ListItemIcon className={'activemenu'}>
                <img
                  className="img-logo"
                  src={
                    supportMenuSelected
                      ? SupportMenuIconActive
                      : SupportMenuIconInActive
                  }
                />
              </ListItemIcon>
              <ListItemText
                className={`Menuitemstyle ${
                  supportMenuSelected ? 'activemenu' : 'inactivemenu'
                }`}
                primary="Support Request"
              />
            </ListItem>
          </div>
        </div>
      ) : (
        <div className="staticMenusIcon">
          <div className="outeranchor" id="supportTicket">
            <ListItem
              title="Support Request"
              dataTestId="supportTicket"
              key="supportTicket"
              onClick={() => {
                setSupportMenuSelected(() => true)
              }}
              className={`item-height drawer__mainmenu_item`}
            >
              <ListItemIcon className={'activemenu'}>
                <img
                  className="img-logo"
                  src={
                    supportMenuSelected
                      ? SupportMenuIconActive
                      : SupportMenuIconInActive
                  }
                />
              </ListItemIcon>
            </ListItem>
          </div>
        </div>
      )} */}

      {/*{supportMenuSelected && (
        <HaloSupportTicket onChangedPopup={onPopupWindowChangeChandler} />
      )}*/}
    </div>
  )
}

export default React.forwardRef(MenuLayout)
