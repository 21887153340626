/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
// Returning function component due to usage of recoil
import React, { useEffect } from 'react'
import { Cancel as CancelIcon } from '@mui/icons-material'
import { useSetRecoilState } from 'recoil'
import { LoaderOnIndexJsAtom } from '../../recoil/atoms/atoms'
import './ErrorBoundary.css'

const ErrorComponent = ({ errorInfo, error }) => {
  const setLoaderOnIndexJs = useSetRecoilState(LoaderOnIndexJsAtom)
  useEffect(() => {
    // hiding loader from index.js when reached any valid path page.
    setLoaderOnIndexJs(false)
  })

  return errorInfo ? (
    <div className="errorboundary">
      <div className="errorboundary__container">
        <span className="errorboundary__logo" />
        <span className="errorboundary__header">
          <span className="errorboundary__erroricon">
            <CancelIcon />
          </span>
          <span className="errorboundary__title">Something went wrong</span>
        </span>
        <span className="errorboundary__message">{error && error.message}</span>
        <details
          open
          className="errorboundary__details"
          style={{ whiteSpace: 'pre-wrap' }}
        >
          <div className="errorboundary__summary">
            <summary>{error && error.toString()}</summary>
            <p>{errorInfo.componentStack}</p>
          </div>
        </details>
      </div>
    </div>
  ) : null
}

export default ErrorComponent
