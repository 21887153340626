/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  In this files we configured router and menubar details
  #MenuLayout     : In this menulayout we mapped all our 
  #BrowserRouter  : BrowserRouter is a router implementation that uses the HTML5 history API (pushstate, replacestate, and popstate events) to keep your UI in sync with the URL.
                    It is the parent component used to store all other components.
  #RecoilRoot     : Recoil used to manage the global state value(s)
*/
// System defined variables
import React, { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ConfirmProvider } from 'material-ui-confirm'
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  useMsal,
} from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import jwtDecode from 'jwt-decode'

// Custom defined variables
import { loginRequest } from '../configs/msalAuthConfigs'
import RoutesFile from '../views/routes/Routes'
import BackdropSpinner from '../views/components/base/backdropSpinner/BackdropSpinner'
import Toast from '../views/components/base/toast/Toast'
import MenuLayout from '../views/components/custom/menuLayout/MenuLayout'
import ErrorBoundary from '../views/components/custom/errorBoundary/ErrorBoundary'
// Loading component is used to pass as loading component for msalAuthentication
import TitleBar from '../views/pages/titleBar/TitleBar'
import {
  showSplashScreen,
  userTokenDetails,
  //authenticationData,
  toastMessage,
  mappedUserRoles,
  assignedContractRoles,
  assignedDistinctRoles,
  userDetail,
  runtimeTokenExpiredAtom,
  userWentInactiveAtom,
  errorCodes,
  gotInitialSessionResponseOfAuthenticationAtom,
  axiosConfiguration,
} from '../views/components/recoil/atoms/atoms'
import UserPermission from '../views/pages/userPermission/UserPermission'
import apiEndPoints from '../../src/models/api/apiEndpoints'
import { fetchDataSelector } from '../views/components/recoil/selectors/selectors'
import utils from '../../src/utils/utils'
import { authenticationData } from '../views/components/recoil/atoms/atomsWithLocalStorage'
import IdleStateDetector from '../views/components/custom/idleStateDetector/IdleStateDetector'
import ResponseErrorCode from '../views/pages/responseErrorCode/ResponseErrorCode'
import './App.css'
function App({ msalInstance, loginHintUserName }) {
  const setAuthenticationResult = useSetRecoilState(authenticationData)
  const setMappedUserRoles = useSetRecoilState(mappedUserRoles)
  const setAssignedContractRoles = useSetRecoilState(assignedContractRoles)
  const setAssignedDistinctRoles = useSetRecoilState(assignedDistinctRoles)
  const setLoggedInUserDetail = useSetRecoilState(userDetail)
  const authenticatedUserData = useRecoilValue(authenticationData)
  const getCoreData = useRecoilValue(fetchDataSelector)
  const [runtimeTokenExpired, setRuntimeTokenExpired] = useRecoilState(
    runtimeTokenExpiredAtom
  )
  const gotInitialSessionResponseOfAuthentication = useRecoilValue(
    gotInitialSessionResponseOfAuthenticationAtom
  )
  const [userPermissionCheckCall, setUserPermissionCheckCall] = useState(false)
  const [permittedUser, setPermittedUser] = useState(null)
  const [accountPermissionHint, setAccountPermissionHint] = useState(
    'Insufficient Permissions'
  )
  const showToastMessage = useSetRecoilState(toastMessage)
  // For authenticated user Splash screen will be shown once
  const setShowSplashScreen = useSetRecoilState(showSplashScreen)
  const setUserTokenDetails = useSetRecoilState(userTokenDetails)
  const setUserWentInactive = useSetRecoilState(userWentInactiveAtom)
  const responseErrorCodes = useRecoilValue(errorCodes)
  const [refreshLoginApi, setRefreshLoginApi] = useState(false)
  const configVariable = useRecoilValue(axiosConfiguration)
  // Commenting below, due to instead of isAuthenticated from msal using local isAuthenticatedSilentFlag to check whether user has logged in or not
  // const isAuthenticated = useIsAuthenticated()
  const { instance } = useMsal()

  React.useEffect(() => {
    // added event listsner function to set show splash screen to false before refresh and the same will persis and splash screen will not be shown after page refresh
    window?.addEventListener('beforeunload', () => {
      setShowSplashScreen(false)
    })
  }, [])
  const availableRoleValidation = (roleData) => {
    if (typeof roleData === 'object') {
      roleData?.forEach((roleName) => {
        if (!utils?.userRoleCheck?.includes(roleName)) {
          return false
        }
      })
    } else {
      if (!utils?.userRoleCheck?.includes(roleData)) {
        return false
      }
    }
    return true
  }

  async function fetchUserTokenDetails() {
    Promise.all([
      await getCoreData(
        apiEndPoints?.userPermissionCheck?.method,
        `${apiEndPoints?.userPermissionCheck?.url}`,
        '',
        { Authorization: 'Bearer' + ' ' + authenticatedUserData?.idToken },
        null,
        true,
        'usePermission'
      ),
    ]).then(([response]) => {
      if (response?.status === utils?.apiResponseCode?.successCode) {
        var token = response?.data?.token
        var decoded = jwtDecode(token)
        setLoggedInUserDetail(decoded)
        // will get distinct roles and roles for assigned each contract from this decode
        if (availableRoleValidation(decoded?.role)) {
          // initially set distinct roles to mappedUserRole. Will change this based on contract once clicked any contract widget.
          if (typeof decoded?.role === 'string') {
            setMappedUserRoles([decoded?.role])
            setAssignedDistinctRoles([decoded?.role])
          } else {
            setMappedUserRoles(decoded?.role)
            setAssignedDistinctRoles(decoded?.role)
          }
          // will have the JSON of assigned contracts with contractId,name and role
          setAssignedContractRoles(decoded?.contract)

          setUserTokenDetails(response?.data)
          setPermittedUser(true)
        } else {
          setAccountPermissionHint('Insufficient Permissions')
          setPermittedUser(false)
        }
      } else if (response?.data?.validationErrors?.length > 0) {
        if (
          response?.data?.validationErrors[0]?.errorMessage
            .toLocaleLowerCase()
            .includes('insufficient')
        ) {
          setAccountPermissionHint(
            utils?.userPermissionMessage?.insufficientPermission
          )
        } else if (
          response?.data?.validationErrors[0]?.errorMessage
            .toLocaleLowerCase()
            .includes('account')
        ) {
          setAccountPermissionHint(
            utils?.userPermissionMessage?.accountInactive
          )
        } else {
          setAccountPermissionHint(
            response?.data?.validationErrors[0]?.errorMessage
          )
        }
        setPermittedUser(false)
      } else if (response?.data) {
        setPermittedUser(false)
        showToastMessage({
          message: response.data?.errors,
          status: 'error',
        })
      }
      //setPermittedUser(true)
      setUserPermissionCheckCall(true)
      setRefreshLoginApi(false)
    })
  }
  //User permission check and validation
  React.useEffect(() => {
    if (
      authenticatedUserData &&
      gotInitialSessionResponseOfAuthentication &&
      !userPermissionCheckCall &&
      !runtimeTokenExpired
    ) {
      fetchUserTokenDetails()
    } else if (runtimeTokenExpired) {
      setUserPermissionCheckCall(true)
    }
  }, [
    authenticatedUserData,
    gotInitialSessionResponseOfAuthentication,
    runtimeTokenExpired,
  ])
  // ignoring this due to refresh time will get from token
  /* istanbul ignore next */
  React.useEffect(() => {
    if (refreshLoginApi) {
      fetchUserTokenDetails()
    }
  }, [refreshLoginApi])

  // ignoring this due to refresh time will get from token
  /* istanbul ignore next */
  const getNewTokenForLoggedInUser = () => {
    const accessTokenRequest = {
      scopes: ['openid'],
      account: authenticatedUserData?.account,
      forceRefresh: true,
      tokenQueryParameters: { ...authenticatedUserData },
    }

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((response) => {
        setAuthenticationResult(response)
        setRefreshLoginApi(true)
      })
      .catch(() => {})
  }

  // ignoring this due to refresh time will get from token
  /* istanbul ignore next */
  const getWhenToRefreshTokenInMillSeconds = () => {
    const expiresOn = new Date(authenticatedUserData?.idTokenClaims?.exp * 1000) //authenticatedUserData?.idTokenClaims?.exp - this will have timeStamp. So multiple * 1000
    const now = new Date(utils?.returnCurrentDateTimeInUTC())

    const diffInMinutes = (expiresOn - now) / 1000 / 60

    let executeAfter = 0
    // if (diffInMinutes >= utils?.refreshTokenMinutesEarlier) {
    executeAfter = (diffInMinutes - utils?.refreshTokenMinutesEarlier) * 60000
    // }
    return executeAfter
  }

  // ignoring this due to refresh time will get from token
  /* istanbul ignore next */
  React.useEffect(() => {
    if (authenticatedUserData) {
      const executeAfter = getWhenToRefreshTokenInMillSeconds()
      setTimeout(() => {
        let currentUserInactiveStatus = false
        setUserWentInactive((currentVal) => {
          currentUserInactiveStatus = currentVal
          return currentVal
        })
        if (!currentUserInactiveStatus) {
          getNewTokenForLoggedInUser()
        } else {
          setRuntimeTokenExpired(true)
        }
      }, [executeAfter])
    }
  }, [authenticatedUserData])

  // ignoring this due to refresh time will get from token
  /* istanbul ignore next */
  React.useEffect(() => {
    if (runtimeTokenExpired) {
      setAccountPermissionHint(utils?.tokenExpiredMessage)
    }
  }, [runtimeTokenExpired])

  const bodyStyles = document.body.style

  bodyStyles.setProperty('--carddatatitlefontSize', '19px')
  bodyStyles.setProperty('--header-FontSize', '28px')
  bodyStyles.setProperty('--sub-header-FontSize', '24px')
  bodyStyles.setProperty('--label-FontSize', '16px')
  bodyStyles.setProperty('--heading-FontSize', '32px')
  bodyStyles.setProperty('--subHeading-FontSize', '21px')
  bodyStyles.setProperty('--caseSubHeading-FontSize', '18px')
  bodyStyles.setProperty('--mainheading-FontSize', '24px')
  bodyStyles.setProperty('--listHeader-FontSize', '12px')
  bodyStyles.setProperty('--selectedSubmenu-fontColor', '#333333')
  bodyStyles.setProperty('--inputadornmenttextheight', '13px')
  bodyStyles.setProperty('--additionalTabSideHead', '18px')
  bodyStyles.setProperty('--pcnHeaderFontSize', '18px')
  bodyStyles.setProperty('--headerFontSize', '18px')
  //button--start
  bodyStyles.setProperty('--buttonIconSize', '12px')
  bodyStyles.setProperty('--buttonFontSize', '15px')
  bodyStyles.setProperty('--buttonFontWeight', '500')
  bodyStyles.setProperty('--buttonIconTextGap', '15px')
  bodyStyles.setProperty('--buttonTopBottomPadding', '11px')
  bodyStyles.setProperty('--buttonRightLeftPadding', '22px')
  bodyStyles.setProperty('--buttonBorderRadius', '4px')
  bodyStyles.setProperty('--userCountBand-bg-color', '#E9F2FF')
  bodyStyles.setProperty('--hyperlinkFont-color', 'blue')
  //button-end
  //fieldAlert start
  bodyStyles.setProperty('--fieldAlertFontSize', '14px')
  //fieldAlert end
  //inputControl - start
  bodyStyles.setProperty('--inputControlTextPadding', '10px')
  bodyStyles.setProperty('--inputCalenderIconSize', '24px')
  bodyStyles.setProperty('--inputDropDownIconSize', '16px')
  bodyStyles.setProperty('--placeHolderColor', '#B6B6B6')
  bodyStyles.setProperty('--inputControlBorderColor', '#B6B6B6')
  bodyStyles.setProperty('--inputControlBorderSize', '1px')
  // need to follow entire base controls
  bodyStyles.setProperty('--inputControlDisabledTextColor', '#898989')
  bodyStyles.setProperty('--inputControlDisabledBgColor', '#F3F3F3')
  bodyStyles.setProperty('--inputbackgroundColor', '#FBFBFB')

  //inputControl - end
  //Dialogue - start
  bodyStyles.setProperty('--dialogTitleFontSize', '16px')
  bodyStyles.setProperty('--dialogTitleFontWeight', '700')
  bodyStyles.setProperty('--dialogContentFontSize', '16px')
  bodyStyles.setProperty('--dialogContentFontWeight', '400')
  bodyStyles.setProperty('--dialogBorderRadius', '10px')
  bodyStyles.setProperty('--dialogIconFontSize', '16px')
  //Dialogue - end
  //toast - start
  bodyStyles.setProperty('--errorToastColor', '#ef5350')
  bodyStyles.setProperty('--successToastColor', '#1F3C88')
  //toast - end
  //switch - start
  bodyStyles.setProperty('--unStyledSwitchColor', '#1F3C88')
  bodyStyles.setProperty('--unStyledSwitchDisabledColor', '#1F3C88')
  //switch - end
  //list - start
  bodyStyles.setProperty('--listHeaderFontWeight', '600')
  bodyStyles.setProperty('--listHeaderFontColor', '#000000')
  bodyStyles.setProperty('--listHeaderFontSize', '16px')
  //list - end
  //contractCard - start
  bodyStyles.setProperty('--contractCardTitleFontSize', '24px')
  bodyStyles.setProperty('--contractCardSubHeaderFontWeight', '350')
  bodyStyles.setProperty('--contractCardSubHeaderFontColor', '#9D9D9D')
  bodyStyles.setProperty('--contractCardTitleFontColor', '#3A3A3A')
  bodyStyles.setProperty('--contractCardCountTitleFontColor', '#808080')
  bodyStyles.setProperty('--contractCardCountTitleFontSize', '19px')
  bodyStyles.setProperty('--contractCardCountFontSize', '24px')
  bodyStyles.setProperty('--contratcCardAvatarSize', '72px')
  bodyStyles.setProperty('--contractCardLogoHeight', '51.55px')
  bodyStyles.setProperty('--contractCardLogoWidth', '36px')
  //contractCard - end
  //caseOverView - start
  bodyStyles.setProperty('--gridTitleFontSize', '20px')
  bodyStyles.setProperty('--gridTitleFontColor', '#535353')
  bodyStyles.setProperty('--gridTitleFontWeight', '600')
  bodyStyles.setProperty('--countTitleFontColor', '#808080')
  bodyStyles.setProperty('--countValueFontColor', '#333333')
  bodyStyles.setProperty('--countValueFontSize', '32px')
  //caseOverview - end
  //tooltip - start
  bodyStyles.setProperty('--tooltipBackgroundColor', '#838383') //got this color from show&tell #e1dbdb //this color from figma - 838383
  bodyStyles.setProperty('--tooltipFontColor', '#FFFFFF') // changing from #FFFFFF to #000000 for visibility
  bodyStyles.setProperty('--tooltipFontSize', '14px')
  bodyStyles.setProperty('--tooltipBorderRadius', '4px')

  //tooltip - end
  // muiIcon size - start
  bodyStyles.setProperty('--muiIconSize', '12px')
  // muiIcon size - end
  // AccordionTitle - start
  bodyStyles.setProperty('--accordionTitleFontSize', '12px')
  // AccordionTitle - end
  // ActionButton - start
  bodyStyles.setProperty('--ActionButtonTitleFontSize', '15px')
  bodyStyles.setProperty('--ActionButtonIconFontSize', '22px')
  bodyStyles.setProperty('--ActionButtonDescriptionFontSize', '14px')
  // ActionButton - end
  // ActionConfirmation - start
  bodyStyles.setProperty('--ActionConfirmationDataFontSize', '14px')
  bodyStyles.setProperty('--ActionCancellationReasonLabelFontColor', '#5A5A5A')
  // ActionConfirmation - end
  // button - start
  bodyStyles.setProperty('--buttonHeight', '40px')
  // button - end
  //mediaViewer - start
  bodyStyles.setProperty('--attachmentViewerBgColor', '#d3d3d3')
  //mediaViewer - end
  /* */

  bodyStyles.setProperty('--accordionDivHeight', '0px')
  bodyStyles.setProperty('--default-FontName', 'Segoe UI')
  // bodyStyles.setProperty('--default-FontName', `'Open Sans',sans-serif`)
  bodyStyles.setProperty('--default-FontColour', 'black')
  bodyStyles.setProperty('--secondary-FontColour', '#1F3C88')
  bodyStyles.setProperty('--default-FontWeight', '400')
  bodyStyles.setProperty('--default-FontSize', '16px')
  bodyStyles.setProperty('--default-BellbuttonFontSize', '10px')
  bodyStyles.setProperty('--default-BellbuttonHeight', '12px')
  bodyStyles.setProperty('--default-BellbuttonWidth', '12px')
  bodyStyles.setProperty('--default-NotificationContentHeadingFontSize', '18px')
  bodyStyles.setProperty('--default-NotificationDateFontSize', '14px')
  bodyStyles.setProperty(
    '--default-NotificationContentUnReadRowColor',
    '#eef3ff'
  )
  bodyStyles.setProperty(
    '--default-NotificationContentRowBorderColor',
    '#e9e9e9'
  )
  bodyStyles.setProperty('--default-NotificationContentReadColor', '#333333')
  bodyStyles.setProperty('--default-NotificationContentUnReadColor', '#000000')
  bodyStyles.setProperty('--label-FontName', 'Segoe UI')
  bodyStyles.setProperty('--label-FontColour', 'Black')
  bodyStyles.setProperty('--label-FontWeight', '400')
  bodyStyles.setProperty('--button-color', '#1F3C88')
  bodyStyles.setProperty('--button-secondary-color', '#ffffff')
  bodyStyles.setProperty('--button-disabled-color', '#B6B6B6')
  bodyStyles.setProperty('--templateHeaderSelectedFontColor', '#1F3C88')
  bodyStyles.setProperty('--button-secondary-border-color', '#1F3C88')
  bodyStyles.setProperty('--brandingPaymentURLBGColor', '#EDEDED')
  bodyStyles.setProperty('--brandingActiveMenuBackgroundColor', '#E6E6E6')
  bodyStyles.setProperty('--gridbgcolor', '#f5f5f5')
  bodyStyles.setProperty('--appcontent-bg-color', '#EEEEEE')
  bodyStyles.setProperty('--appcontent-bg-layer-color', '#F9F9F9')
  bodyStyles.setProperty('--font-letter-space', '0px')
  bodyStyles.setProperty('--text-line-height', '1.25vw')
  bodyStyles.setProperty('--textahrlignment', null)
  bodyStyles.setProperty('--footertextahrlignment', 'center')
  bodyStyles.setProperty('--cursorurl', null)
  bodyStyles.setProperty('--cursorname', null)
  bodyStyles.setProperty('--required-color', 'red')
  bodyStyles.setProperty('--ToolbarTextColour', 'Black')
  bodyStyles.setProperty('--footer-default-fontcolor', '#828282')
  bodyStyles.setProperty('--submenufontcolour', '#838383')
  bodyStyles.setProperty('--controlborder-colour', null)
  bodyStyles.setProperty('--header-FontWeight', '600')
  bodyStyles.setProperty('--heading-FontColour', 'Black')
  bodyStyles.setProperty('--subHeading-FontColour', '#0054a6')
  bodyStyles.setProperty('--listHeader-FontColour', '#798093')
  bodyStyles.setProperty('--ListHeaderText-Colour', 'black')
  bodyStyles.setProperty('--TitleBar-TextColour', 'White')
  bodyStyles.setProperty('--cardfontColour', '#808080')
  bodyStyles.setProperty('--ToolbarBackgroundColour', 'White')
  bodyStyles.setProperty('--navbar-color', '#F8F8FF')
  bodyStyles.setProperty('--footer-color', '#F3F3F6')
  bodyStyles.setProperty('--navbar-group-color', '#F3F3F3')
  bodyStyles.setProperty('--titlebar-color', '#F3F3F6')
  bodyStyles.setProperty('--TitleBar-IconColour', '#1F3C88')
  bodyStyles.setProperty('--sub-header-FontWeight', '600')
  bodyStyles.setProperty('--cardavatarbgcolor', '#F1F5FF')
  bodyStyles.setProperty('--carddatatitlebordercolor', '#ECECEC')
  bodyStyles.setProperty('--disableBgColor', '#D9D9D9')
  bodyStyles.setProperty('--actionButtonBgColor', '#E9F2FF')
  bodyStyles.setProperty('--searchBarControlBorder-colour', '#B6B6B6')
  bodyStyles.setProperty('--attachmentTextColor', '#545454')
  bodyStyles.setProperty('--confirmationTextColor', '#5a5a5a')
  bodyStyles.setProperty('--paymentStatusSuccess', '#29821a')
  bodyStyles.setProperty('--paymentStatusFailure', '#da3131')
  bodyStyles.setProperty('--copyIconMouseDownColor', '#ccc9c9')
  bodyStyles.setProperty('--noRecordFoundFontColor', '#b6b6b6')
  bodyStyles.setProperty('--mandatorySymbol', '#ff0000')
  bodyStyles.setProperty('--greenColour', '#278832')
  bodyStyles.setProperty('--whiteColour', 'white')
  bodyStyles.setProperty('--blackColour', 'black')
  bodyStyles.setProperty('--inputControlBackgroundColor', '#ffffff')
  bodyStyles.setProperty('--control-Radius', '6px')
  bodyStyles.setProperty('--attachmentIcon-Border', '#B4C4EE')
  bodyStyles.setProperty('--line-BorderColor', '#ececec')
  bodyStyles.setProperty('--tab-White', '#eef3ff')
  bodyStyles.setProperty('--readOnlyColor', '#8F9DC3')
  bodyStyles.setProperty('--menuLayoutBoxShadow', '#888888')
  bodyStyles.setProperty('--disableMenuColor', '#bbb9b9')
  bodyStyles.setProperty('--tagsBorderColor', '#CAC5C5')
  bodyStyles.setProperty('--messageModalTitleBar', '#e3e3e3')
  bodyStyles.setProperty('--muicheckedHover', '#0000000a')
  bodyStyles.setProperty('--tagsBGColor', '#cfd8e2')
  bodyStyles.setProperty('--gridCellIconWidth', '24px')
  bodyStyles.setProperty('--gridFileErrorCount', '#7b818e')
  bodyStyles.setProperty('--containerBorderRadius', '8px')
  bodyStyles.setProperty('--remainingIndicateTextColor', '#ababab')
  bodyStyles.setProperty('--createdPcnFontSize', '26px')
  bodyStyles.setProperty('--emailControler', '#cfd8e2')
  bodyStyles.setProperty('--statusInitiated-background', '#e6edff')
  bodyStyles.setProperty('--statusCompleted-background', '#c4edc8')
  bodyStyles.setProperty('--statusCompleted-fontcolor', '#219360')
  bodyStyles.setProperty('--tagspopBgColor', '#999')
  bodyStyles.setProperty('--messagepopupscrollbarBgColor', '#aeaaaa57')
  bodyStyles.setProperty('--messagepopupscrollbarColor', '#ffffff57')
  bodyStyles.setProperty('--messagepopupBorderColor', '#e3e3e3')
  bodyStyles.setProperty('--popupBgColor', '#00000080')
  bodyStyles.setProperty('--disableSwitchTrackBgColor', '#1f3c8880')
  bodyStyles.setProperty('--scrollBarBgColor', '#aeaaaa57')
  bodyStyles.setProperty('--scrollBarBorderColor', '#ffffff57')
  bodyStyles.setProperty('--additionalTabBorderColor', '#bcbcbc')
  bodyStyles.setProperty('--inputLableColor', '#646464')
  bodyStyles.setProperty('--popupBackgroundColor', '#00000080')
  bodyStyles.setProperty('--fpnDisabledColor', '#8E9DC3')
  bodyStyles.setProperty('--popupLabelColor', '#606060')
  bodyStyles.setProperty('--popupStageColor', '#3eb54b')
  bodyStyles.setProperty('--inputBackground', '#e6e6e6')
  bodyStyles.setProperty('--attachmentFiletype-Heading', '350')
  bodyStyles.setProperty('--attachmentFiletype-Heading-Font-Size', '13px')
  bodyStyles.setProperty('--ceoTrackTitleColor', '#262D33')
  bodyStyles.setProperty('--priorityFieldBGColor', '#F3F5FC')
  bodyStyles.setProperty('--conditionBorderColor', '#97AEE7')
  bodyStyles.setProperty('--popupTItleColor', '#243B6A')
  bodyStyles.setProperty('--actionButtonBackground', '#F3F5FC')
  bodyStyles.setProperty('--grayBgColor', '#F2F2F2')
  bodyStyles.setProperty('--blueTextColor', '#0C7FE1')

  const AppInsightsKey = configVariable?.VariableSettings?.AppInsightsKey
  localStorage.setItem('AppInsightsKey', AppInsightsKey)
  return (
    <ErrorBoundary>
      <ConfirmProvider>
        <BackdropSpinner />
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={{
            ...loginRequest,
            loginHint: loginHintUserName,
          }}
        >
          <AuthenticatedTemplate>
            <>
              {responseErrorCodes &&
              responseErrorCodes?.showErrorPage === true ? (
                <ResponseErrorCode
                  responseErrorCode={responseErrorCodes?.errorCode}
                />
              ) : !userPermissionCheckCall ? (
                <></>
              ) : permittedUser && !runtimeTokenExpired ? (
                <>
                  <IdleStateDetector
                    delay={utils?.applicationInactiveTimeLapse}
                  />
                  <Toast />
                  <BrowserRouter>
                    <TitleBar msalInstance={msalInstance} />
                    <MenuLayout />
                    <div className="bodypage">
                      <div className="body-data-page">
                        <RoutesFile />
                      </div>
                    </div>
                  </BrowserRouter>
                </>
              ) : (
                (permittedUser !== null || runtimeTokenExpired) && (
                  <UserPermission
                    accountPermissionHint={accountPermissionHint}
                    msalInstance={msalInstance}
                  />
                )
              )}
            </>
          </AuthenticatedTemplate>
        </MsalAuthenticationTemplate>
      </ConfirmProvider>
    </ErrorBoundary>
  )
}

export default App
