/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

// System Defined variables
import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVert from '@material-ui/icons/MoreVert'
import Fade from '@material-ui/core/Fade'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { useRecoilValue } from 'recoil'

// Custom Defined variables
import EditIcon from '../../../../../src/assets/images/EditIcon.svg'
import DeleteIcon from '../../../../../src/assets/images/DeleteIcon.svg'
import PreviewIcon from '../../../../../src/assets/static/preview.svg'
import ApproveIcon from '../../../../../src/assets/static/approveIcon.svg'
import ReRunIcon from '../../../../../src/assets/static/reRunIcon.svg'
import PCNDownloadIcon from '../../../../../src/assets/static/pcndownloadicon.svg'
import SendEmailIcon from '../../../../../src/assets/static/sendemailicon.svg'

import './MoreVertIcon.css'
import utils from '../../../../utils/utils'
import { selectedContractDetails } from '../../recoil/atoms/atoms'

const MoreVertIcon = ({ data, RowActions, rowIndex, className }) => {
  const [anchorEl, setAnchorEl] = React.useState(false)
  const selectedContract = useRecoilValue(selectedContractDetails)
  const contractType = selectedContract?.contractType?.toString()?.toLowerCase()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(false)
  }
  function MenuListHandler(action, menuIcon, menuTitle, className) {
    return (
      <MenuItem
        className={`moreverticonmenuitem ${className ? className : ''}`}
        data-testid={'ic_moreVertIcon'}
        key={action?.ActionName}
        onClick={() => {
          action.ActionHandler(data, rowIndex)
          handleClose()
        }}
      >
        <img
          className={'moreoverImaget'}
          src={menuIcon}
          width={menuTitle === 'Preview' ? 17 : 'auto'}
          height={menuTitle === 'Preview' ? 17 : 'auto'}
        />
        &nbsp; &nbsp;
        <Typography variant="inherit" className="moreoverText">
          {menuTitle}
        </Typography>
      </MenuItem>
    )
  }
  return (
    <>
      <IconButton
        className="morevert-icon"
        disableRipple
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={
          (data?.addmode && data?.addmode) ||
          data?.source === utils?.dvlaMismatchStaticData?.Source
          // (data?.isCancelReasonReference && data?.isCancelReasonReference)
        }
        // data-testid="morevert-icon"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
        className={className}
        // anchorOrigin={{ horizontal: 'right' }}
      >
        {RowActions.map((action) => {
          const listName = action?.ActionName?.toString()?.toLowerCase()
          const listStatus = data?.status?.toString()?.toLowerCase()
          return (
            <div>
              {listName === 'edit'
                ? MenuListHandler(action, EditIcon, action?.ActionName)
                : listName === 'delete' &&
                  (contractType === utils?.contractTypes?.pcnName ||
                    (contractType === utils?.contractTypes?.fpnName &&
                      data?.isKeeperAddress === undefined) ||
                    (contractType === utils?.contractTypes?.fpnName &&
                      data?.isKeeperAddress === false) ||
                    contractType === undefined)
                ? MenuListHandler(action, DeleteIcon, action?.ActionName)
                : listName === 'preview'
                ? MenuListHandler(action, PreviewIcon, action?.ActionName)
                : listName === 'approve' &&
                  data.isApproveAllowed === true &&
                  listStatus === 'pending approval'
                ? MenuListHandler(action, ApproveIcon, action?.ActionName)
                : listName === 'rerun' && listStatus === 'failure'
                ? MenuListHandler(action, ReRunIcon, action?.ActionName)
                : listName === 'download'
                ? MenuListHandler(
                    action,
                    PCNDownloadIcon,
                    action?.ActionName,
                    data?.reservationStatus?.toString()?.toLowerCase() ===
                      'initiated'
                      ? 'disabled Download'
                      : 'enabled Download'
                  )
                : listName === 'send email'
                ? MenuListHandler(
                    action,
                    SendEmailIcon,
                    action?.ActionName,
                    data?.reservationStatus?.toString()?.toLowerCase() ===
                      'initiated'
                      ? 'disabled SendEmail'
                      : 'enabled SendEmail'
                  )
                : null}
            </div>
          )
        })}
      </Menu>
    </>
  )
}

MoreVertIcon.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  RowActions: PropTypes.arrayOf(PropTypes.object),
  rowIndex: PropTypes.number,
}
MoreVertIcon.defaultProps = {
  data: {},
  RowActions: [],
  rowIndex: null,
}
export default MoreVertIcon
