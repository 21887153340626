/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/*
  This Multiline Text Field can be used to store larger amounts of text
*/
// System defined variables
import React, { useEffect, useRef } from 'react'
import TextareaAutosize from '@mui/base/TextareaAutosize'
import PropTypes from 'prop-types'

// Custom defined variables
import '../../../../../src/Main.css'
import './MultiLineText.css'
import utils from '../../../../utils/utils'

function EmptyTextarea({
  inputLength,
  minRowsCount,
  maxRowsCount,
  onChangeHandler,
  required,
  fieldValue,
  value,
  id,
  name,
  className,
  isDisablemaxlength,
  helperText,
  onBlurHandler,
  placeHolder,
  showcount,
  dataTestId,
  disabled,
  setForwardRef,
  errorMeaaage,
  customTextFor,
}) {
  const inputRef = useRef(null)
  const changeHandlerFun = (e) => {
    const params = {
      e,
      id: fieldValue,
      value: e.target.value !== '' ? e.target.value : null,
    }

    if (onChangeHandler) onChangeHandler(e, params)
  }
  function onBlurFun(e) {
    const params = {
      e,
      id: fieldValue,
      value: e.target.value !== '' ? e.target.value : null,
    }

    if (onBlurHandler) onBlurHandler(e, params)
  }

  useEffect(() => {
    if (setForwardRef) setForwardRef(inputRef)
  }, [])

  return (
    <div className="Text-field-container">
      <div
        className={`${className} multilinetextbox ${
          helperText?.length > 0 ? 'outlineerror' : null
        }`}
      >
        <TextareaAutosize
          id={id}
          name={name}
          className="multiLineTextArea"
          data-testid={dataTestId || 'MultilineText'}
          aria-label="empty textarea"
          placeholder={placeHolder ? placeHolder : ''}
          maxLength={isDisablemaxlength ? null : inputLength || 100}
          maxRows={maxRowsCount}
          minRows={minRowsCount}
          onChange={changeHandlerFun}
          required={required}
          value={value || ''}
          onBlur={onBlurFun}
          disabled={disabled ? disabled : false}
          ref={inputRef}
        />
        <div className="messageContainer">
          <span className="ErrorText">{helperText}</span>
          {errorMeaaage ? (
            <span className="errorMessageText">{errorMeaaage}</span>
          ) : null}
          <span
            data-testid="input"
            className={`messageContainer ${
              showcount === false ? ' is-display' : ''
            }`}
          >
            {customTextFor === utils?.manualCase?.stepperName?.additionalInfo
              ? `${value?.length | 0} out of ${inputLength}`
              : `${inputLength - (value?.length | 0)} Remaining`}
          </span>
        </div>
      </div>
    </div>
  )
}

EmptyTextarea.propTypes = {
  inputLength: PropTypes.string,
  maxRowsCount: PropTypes.string,
  changeHandler: PropTypes.func,
  placeHolder: PropTypes.string,
}

EmptyTextarea.defaultProps = {
  inputLength: '',
  maxRowsCount: '',
}

export default EmptyTextarea
