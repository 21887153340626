// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

.multilinetextbox textarea {
  resize: vertical;
  outline: none;
  overflow-y: scroll;
  overflow: auto;
  margin-top: 4px;
  background-color: var(--gridbgcolor);
  border-radius: 4px;
  box-sizing: border-box;
  font-size: var(--default-FontSize);
  color: var(--default-FontColour);
  font-weight: var(--default-FontWeight);
  font-family: var(--default-FontName);
  padding: 10px;
}

.multilinetextbox .ControlLabelDiv {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.ControlLabelDiv {
  margin-top: 55px;
}

.messageContainer {
  display: flex;
  justify-content: space-between;
  display: block;
  float: right;
  margin-top: -7px;
}

.messageContainer.is-display {
  display: none;
  opacity: 0.7;
}

.input {
  font-size: var(--default-FontSize);
  color: var(--default-FontColour);
  font-weight: var(--default-FontWeight);
  font-family: var(--default-FontName);
  /* color: #535353; */
}

.outlineerror #txt_note {
  border: 1px solid red;
  border-radius: 4px;
}

.messageContainer .ErrorText,
.messageContainer .errorMessageText {
  color: red !important;
  font-size: var(--default-FontSize);
  color: var(--default-FontColour);
  font-weight: var(--default-FontWeight);
  font-family: var(--default-FontName);
}

/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*# sourceMappingURL=Main.css.map */
`, "",{"version":3,"sources":["webpack://./src/_Mixin.scss","webpack://./src/views/components/base/multilineText/_MultilineText.scss","webpack://./src/Main.css","webpack://./src/Main.scss"],"names":[],"mappings":"AAAA;;;CAAA;;ACAA;EAAA,gBAAA;EAAA,aAAA;EAKA,kBAAA;EACE,cAAA;EACA,eAAA;EACA,oCAAA;EACA,kBAAA;EACA,sBAAA;EACA,kCAAA;EACA,gCAAA;EACA,sCAAA;EDPA,oCAAA;EACA,aAAA;AEYF;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EDLrB,YAAA;ACOA;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EDNhC,cAAA;EACE,YAAA;EACA,gBAAA;ACQF;;AAEA;EACE,aAAa;EACb,YAAY;ADPd;;ACUA;EACE,kCAAkC;EAClC,gCAAgC;EDRlC,sCAAA;EDhCE,oCAAA;EACA,oBAAA;AE2CF;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;ADXpB;;ACcA;;EAEE,qBAAqB;EDZvB,kCAAA;EACE,gCAAA;ED1CA,sCAAA;EACA,oCAAA;AEyDF;;AAEA;;;CClEA;;AAAA,mCAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
