// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
.radioGroupControl .MuiFormControlLabel-root {
  display: flex;
  flex-direction: row;
}

.radioGroupControl .MuiSvgIcon-root {
  color: #1f3c88;
}

.radioGroupControl .MuiFormControlLabel-label {
  font-family: var(--label-FontName);
  font-size: var(--label-FontSize);
  font-weight: var(--label-FontWeight);
  color: var(--label-FontColor);
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/radioGroupControl/RadioGroupControl.css"],"names":[],"mappings":"AAAA;;;CAGC;AACD;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kCAAkC;EAClC,gCAAgC;EAChC,oCAAoC;EACpC,6BAA6B;AAC/B","sourcesContent":["/*\nThis computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), \nis the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.\n*/\n.radioGroupControl .MuiFormControlLabel-root {\n  display: flex;\n  flex-direction: row;\n}\n\n.radioGroupControl .MuiSvgIcon-root {\n  color: #1f3c88;\n}\n\n.radioGroupControl .MuiFormControlLabel-label {\n  font-family: var(--label-FontName);\n  font-size: var(--label-FontSize);\n  font-weight: var(--label-FontWeight);\n  color: var(--label-FontColor);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
