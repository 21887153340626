/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  This textbox component was used to handle the data.
*/

// System defined variables
import React, { useState, useRef, useEffect } from 'react' //useEffect
import { TextField } from '@mui/material' //IconButton
import InputAdornment from '@mui/material/InputAdornment'
import PropTypes from 'prop-types'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import _ from 'lodash'

// Custom defined variables
import HelpText from '../helpText/HelpText'
import getValidator from '../validator/Validator'
import mapsLocation from '../../../../assets/static/location_icon.svg'
import './TextBox.css'
import filterIcon from '../../../../assets/static/filterIcon.svg'

/* type,className,variant */
function TextBox({
  value,
  id,
  cssClass,
  Screen,
  length,
  onChangeHandler,
  onBlurHandler,
  field,
  fieldErrorMessage,
  outlineError,
  isFocused,
  required,
  restrictCopy,
  restrictPaste,
  autoComplete,
  endadronmentonclick,
  enableTitle,
  setForwardRef,
  subLabel = null,
  ...rest
}) {
  const [validatorMessage, setValidatorMessage] = useState(null)
  const [isErrorClass, setErroClass] = useState('false')
  const inputRef = useRef(null)
  outlineError?.splice(1)

  useEffect(() => {
    if (setForwardRef) setForwardRef(inputRef)
  }, [])

  const controlLabel = (
    <div className="controlLabelDiv">
      {subLabel ? (
        <div className="withSubTextSection">
          <span className="controlLabel">{field?.fieldLabel}</span>
          <span className="subText">{subLabel}</span>
        </div>
      ) : (
        <span className="controlLabel">{field?.fieldLabel}</span>
      )}

      <span>{outlineError && outlineError}</span>
      <span>{field?.helpText && <HelpText longText={field?.helpText} />}</span>
      {required || field?.validation?.isRequired?.toLowerCase() === 'true' ? (
        <span className="Required-color"> *</span>
      ) : (
        ''
      )}
    </div>
  )

  function onChange(event) {
    let validationIsVaild = true
    const textboxdata = event?.target?.value
    if (
      field &&
      field?.validation &&
      field?.validation?.validationCondition &&
      field?.validation?.validationCondition !== ''
    ) {
      const validatorfunction = getValidator(
        field?.validation?.validationCondition
      )

      let isVaild = ''
      isVaild = validatorfunction(textboxdata)

      if (isVaild !== '') {
        setErroClass('true')
        if (field?.validation?.validationText !== '') {
          isVaild = field?.validation?.validationText
        }
        setValidatorMessage(isVaild)
        validationIsVaild = false
      } else {
        setErroClass('false')
        setValidatorMessage('')
        validationIsVaild = true
      }
    }
    const params = {
      event,
      id: field?.fieldValue,
      value:
        field?.Type === 'number'
          ? event?.target?.value !== '' && event?.target?.value !== ' '
            ? Number(event?.target?.value)
            : ''
          : event?.target?.value,
      isValidData: validationIsVaild,
    }
    if (onChangeHandler) onChangeHandler(event, params)
  }

  function onBlur(event) {
    const params = {
      event,
      id: field?.fieldValue,
      value:
        field?.Type === 'number'
          ? event?.target?.value !== ''
            ? Number(event?.target?.value)
            : ''
          : event?.target?.value,
    }

    if (onBlurHandler) onBlurHandler(event, params)
  }

  const onCleartextBoxValue = () => {
    const params = {
      id: field?.fieldValue,
      value: '',
    }

    if (onChangeHandler) onChangeHandler(null, params)
  }

  const handleKeyDown = (event) => {
    if (
      (event?.keyCode === 107 ||
        event?.keyCode === 69 ||
        event?.keyCode === 187 ||
        event?.keyCode === 189 ||
        event?.keyCode === 109 ||
        event?.keyCode === 110 ||
        event?.keyCode === 190) &&
      event?.target?.type?.toString().toLowerCase() === 'number'
    ) {
      event?.preventDefault()
    }
  }
  function onCopy(event) {
    if (restrictCopy) return restrictCopy(event)
  }
  function onPaste(event) {
    if (restrictPaste) return restrictPaste(event)
  }

  return (
    <div className="textbox">
      <div className="text-field-container">
        <TextField
          style={{
            width: '100%',
          }}
          id={Screen ? `txt_${Screen + (field?.id ? field?.id : id)}` : id}
          title={enableTitle ? `${value}` : ''}
          multiline={field?.multiline ? field?.multiline : false}
          minRows={field?.rows ? field?.rows : null}
          label={controlLabel}
          placeholder={field?.placeholder}
          onKeyDown={handleKeyDown}
          variant="standard"
          type={
            field?.fieldValue?.toLowerCase() === 'Password' ||
            field?.fieldValue?.toLowerCase() === 'confirmPassword'
              ? 'Password'
              : field?.type || 'text'
          }
          value={value || value === 0 ? value : ''}
          className={`${field?.className} ${cssClass}`}
          inputProps={{
            'data-testid': rest?.dataTestId ? rest?.dataTestId : 'textField',
            spellCheck: true,
            min:
              field?.hasOwnProperty('minValue') &&
              field?.minValue !== '' &&
              field?.minValue !== null &&
              field?.minValue !== undefined &&
              !_.isUndefined(field?.minValue)
                ? field?.minValue
                : null,
            max:
              field?.hasOwnProperty('maxValue') &&
              field?.maxValue !== '' &&
              field?.maxValue !== null &&
              field?.maxValue !== undefined &&
              !_.isUndefined(field?.maxValue)
                ? field?.maxValue
                : null,
            maxLength: length,
            className: field?.className || '',

            onDrop: (event) => {
              if (field?.disableDroppable) event.preventDefault()
            },
          }}
          InputProps={{
            inputRef: inputRef,
            endAdornment: (
              <>
                {field?.showendadornement ? (
                  <InputAdornment position="end">
                    <img
                      src={mapsLocation}
                      alt="Location"
                      style={{ cursor: 'pointer' }}
                      onClick={endadronmentonclick}
                    />
                  </InputAdornment>
                ) : (field?.inputAdornmentObj?.adornmenttype
                    ?.toString()
                    ?.toLowerCase() === 'filter' ||
                    field?.inputAdornmentObj?.adornmenttype
                      ?.toString()
                      ?.toLowerCase() === 'clearinput') &&
                  value?.toString()?.length > 0 ? (
                  <CloseOutlinedIcon
                    onClick={onCleartextBoxValue}
                    fontSize="small"
                  />
                ) : null}
              </>
            ),
            startAdornment: (
              <>
                {field?.inputAdornmentObj?.showAdornmentValue ? (
                  <InputAdornment position="start">
                    {field?.inputAdornmentObj?.adornmenttype
                      ?.toString()
                      ?.toLowerCase() === 'filter' ? (
                      <img
                        src={filterIcon}
                        alt="Filter"
                        width={19.5}
                        height={17.75}
                      />
                    ) : (
                      <span
                        className={`InputAdornmentText ${
                          field?.inputAdornmentObj?.adornmenttype === 'currency'
                            ? 'currencySymbol'
                            : ''
                        }`}
                      >
                        {field?.inputAdornmentObj?.inputAdornmentValue}
                      </span>
                    )}
                  </InputAdornment>
                ) : null}
              </>
            ),
          }}
          helperText={field?.fieldAlert}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={field && field.defaultValue}
          error={isErrorClass !== 'false'}
          onChange={(event) => {
            onChange(event)
          }}
          onBlur={(event) => {
            onBlur(event)
          }}
          disabled={field?.disabled}
          size="small"
          autoFocus={isFocused}
          onCopy={(event) => onCopy(event)}
          onPaste={(event) => onPaste(event)}
          autoComplete={autoComplete}
          // {...rest}
        />
        {fieldErrorMessage && <span>{fieldErrorMessage}</span>}
      </div>
      {validatorMessage && (
        <span className="error">
          <br />
          {validatorMessage}
        </span>
      )}
    </div>
  )
}

TextBox.propTypes = {
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  cssClass: PropTypes.string,
  className: PropTypes.string,
  onChangeHandler: PropTypes.func,
  onBlurHandler: PropTypes.func,
  value: PropTypes.any,
  id: PropTypes.string,
  error: PropTypes.bool,
  type: PropTypes.string,
  required: PropTypes.bool,
  length: PropTypes.string,
  Screen: PropTypes.string,
  isFocused: PropTypes.bool,
}

TextBox.defaultProps = {
  cssClass: '',
  className: '',
  onChangeHandler: () => null,
  onBlurHandler: () => null,
  isFocused: false,
  value: '',
  id: '',
  error: false,
  type: '',
  required: false,
  length: '',
  Screen: '',
}

export default TextBox
