/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
import React from 'react'
import { Avatar } from '@mui/material'

import './AvatarIcon.css'

function AvatarIcon({
  imgSource = '/broken-image.jpg',
  altText,
  className,
  children,
}) {
  return (
    <div className="avataricon">
      <Avatar
        className={`avataricon__avatar-image ${className}`}
        id="userIcon"
        alt={altText}
        src={imgSource}
        children={children}
      />
    </div>
  )
}

export default AvatarIcon
