// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/* In this file, we write css for MultilineTextBox  */

.multiLineTextArea::placeholder {
  font-family: var(--default-FontName);
  font-size: var(--label-FontSize);
  font-weight: var(--label-FontWeight);
  color: var(--placeHolderColor);
  opacity: 1;
  transition: unset;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/multilineText/MultiLineText.css"],"names":[],"mappings":"AAAA;;;CAGC;;AAED,qDAAqD;;AAErD;EACE,oCAAoC;EACpC,gCAAgC;EAChC,oCAAoC;EACpC,8BAA8B;EAC9B,UAAU;EACV,iBAAiB;AACnB","sourcesContent":["/*\nThis computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), \nis the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.\n*/\n\n/* In this file, we write css for MultilineTextBox  */\n\n.multiLineTextArea::placeholder {\n  font-family: var(--default-FontName);\n  font-size: var(--label-FontSize);\n  font-weight: var(--label-FontWeight);\n  color: var(--placeHolderColor);\n  opacity: 1;\n  transition: unset;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
