/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  It contains recoil's atoms files to manage the state value(s)
  atom  : This is an recoil's default library to manage the global state values, This atom is an function and it recieve parameter as object with containing key default values.
  key   : This is an unique key to access this atom function from component.
*/

//import { SignalCellularNull } from '@mui/icons-material'
import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
  key: 'recoil-persist', // this key is using to store data in storage
  storage: localStorage, // changing from local-storage to session storage for login redirection (once it works fine in all scenario then need to  ove this session-storage-atom to normal atoms file)
})

const authenticationData = atom({
  key: 'authenticationData',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const msalPolicyNameAtom = atom({
  key: 'msalPolicyNameAtom',
  default: '',
  effects_UNSTABLE: [persistAtom],
})
export { authenticationData, msalPolicyNameAtom }
