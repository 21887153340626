/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  This CheckboxControl component was used to handle the data to include or not from pages or other components.
*/
import React from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'
import propTypes from 'prop-types'
import './CheckboxControl.css'

function CheckboxControl({ field, onChangeHandler }) {
  const onChange = (event) => {
    if (onChangeHandler) {
      onChangeHandler(event, {
        id: field?.fieldValue,
        value: event?.target?.checked,
      })
    }
  }
  const checkboxControl = () => (
    <Checkbox
      id={field?.id || 'cb_checkbox'}
      data-testid={field?.dataTestId ? field?.dataTestId : 'cb_checkbox'}
      checked={field?.value}
      color="default"
      disabled={field?.disabled || false}
      disableRipple
      onChange={onChange}
      required={field?.required}
      defaultChecked={field?.defaultChecked}
      className="checkbox"
    />
  )
  return (
    <div className="checkboxControl">
      {field?.fieldLabel ? (
        <FormControlLabel
          control={checkboxControl()}
          className={field?.className}
          label={field?.fieldLabel}
        />
      ) : (
        checkboxControl()
      )}
    </div>
  )
}
export default CheckboxControl

CheckboxControl.propTypes = {
  field: propTypes.objectOf(propTypes.any),
  onChangeHandler: () => null,
}

CheckboxControl.defaultProps = {
  field: {},
  onChangeHandler: () => {},
}
