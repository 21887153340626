/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
// System defined variables
import React, { useState } from 'react'
import { Search } from 'semantic-ui-react' //Grid
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

// Custom defined variables.
import SearchIcon from '../../../../assets/images/Search.svg'
import ReactModal from '../../base/modal/ReactModal'
import APIEndPoints from '../../../../models/api/apiEndpoints'
import { fetchDataSelector } from '../../recoil/selectors/selectors'
import {
  toastMessage,
  selectedCaseDetails,
  contactAddressstore,
  madeChanges,
  additionalOptionsDisabled,
  showLoading,
  selectedContractDetails,
  selectLinkedCaseDetails,
  searchListFormGridData,
} from '../../recoil/atoms/atoms'

import utils from '../../../../utils/utils'

import './SearchBar.css'

function SearchBar({ SearchName }) {
  // onClick
  const initialState = {
    isLoading: false,
    result: [],
    value: '',
    dataSource: [],
    searchName: SearchName,
    errMsg: '',
    modalOpen: false,
    searchtext: null,
  }

  const navigate = useNavigate()
  const [state, setState] = React.useState(initialState)
  const [searchTearm, setsearchTearm] = React.useState(state.value)
  const getCoreData = useRecoilValue(fetchDataSelector)
  const [hasChanges, setChangesMade] = useRecoilState(madeChanges)
  const showToastMessage = useSetRecoilState(toastMessage)
  const [searclick, setSearchClick] = useState({ searchstate: false })
  const setSelectedcaseDetails = useSetRecoilState(selectedCaseDetails)
  const showLoader = useSetRecoilState(showLoading)
  const setAddContactDetails = useSetRecoilState(contactAddressstore)
  const setAdditionalOptionsDisabled = useSetRecoilState(
    additionalOptionsDisabled
  )
  const selectedContract = useRecoilValue(selectedContractDetails)
  const [selectLinkedCaseDetailsData, setSelectLinkedCaseDetails] =
    useRecoilState(selectLinkedCaseDetails)
  const [selectSearchListFormGridData, setSearchListFormGridData] =
    useRecoilState(searchListFormGridData)

  const handleResultSelect = (e, { result }) => {
    state?.dataSource?.length > 0
      ? state?.dataSource?.forEach((item) => {
          const data = item[Object.keys(item)]
          const newdata = data?.results?.find(
            (item1) => item1.id === result?.id
          )
          if (typeof newdata !== 'undefined') {
            //searchdata = data
            return true
          }
        })
      : ''
  }

  const handleSearchChange = async (e, { value }) => {
    setState({
      isLoading: true,
      result: [],
      value: value,
      searchName: value,
      errMsg: '',
    })
    setsearchTearm(value)
  }
  //searchName
  const { isLoading, value, result, errMsg } = state

  //This function active for only enter key
  function listenEnter(e) {
    const searchValue = e.target.value
    if (e.key?.toString()?.toLowerCase() === 'enter') {
      //onClick(searchValue)
      if (
        searchTearm === '' ||
        searchTearm?.toString()?.length === 0 ||
        searchValue?.toString()?.toLowerCase() === 'undefined'
      ) {
        showToastMessage({
          message: utils?.fpnBasedChanges?.invalidGlobalSearch,
          status: utils?.toastStatus?.error,
        })
      } else {
        showLoader(true)
        setTimeout(() => {
          handleSearchResult()
        }, 1000)
      }
    }
  }

  const handleSearchResult = () => {
    if (
      searchTearm === null ||
      searchTearm === '' ||
      searchTearm?.toString()?.length === 0 ||
      _.isUndefined(searchTearm)
    ) {
      showToastMessage({
        message: utils?.fpnBasedChanges?.invalidGlobalSearch,
        status: utils?.toastStatus?.error,
      })
    } else {
      setSearchClick((prevstate) => ({ ...prevstate, searchstate: true }))
    }
  }

  const onCleartextBoxValue = () => {
    setState((prevData) => ({ ...prevData, value: '', searchName: '' }))
    setsearchTearm('')
  }

  React.useEffect(() => {
    /* istanbul ignore next */
    if (searchTearm !== '') {
      const getSearchData = async () => {
        const filteredResults = []
        const searchData = await getCoreData(
          APIEndPoints.getSearchBarData.method,
          `${APIEndPoints.getSearchBarData.url}`,
          {
            contractId: selectedContract?.contractId || 0,
            searchText: searchTearm,
          }
        )

        if (searchData?.status === utils?.apiResponseCode?.successCode) {
          setsearchTearm(null)
          onCleartextBoxValue()
          //below the useEffect if any select linked case tab store that case in below the state and when search click menu item will reset atoms value
          if (selectLinkedCaseDetailsData?.length > 0) {
            setSelectLinkedCaseDetails([])
          }
          if (selectSearchListFormGridData?.length > 0) {
            setSearchListFormGridData([])
          }
          if (searchData && searchData?.data && searchData?.data?.isVRM) {
            setState((Prev) => ({
              ...Prev,
              modalOpen: true,
              isLoading: false,
              result: filteredResults,
              dataSource: searchData && searchData?.data?.caseDetails,
              searchtext: searchTearm,
            }))
            showLoader(false)
          } else {
            if (searchData?.data?.caseDetails?.length > 0)
              setSelectedcaseDetails(searchData?.data?.caseDetails[0])
            else setSelectedcaseDetails([])
            setAddContactDetails(false)
            setChangesMade(false)
            setAdditionalOptionsDisabled(true)
            navigate('/pcnpage')
            showLoader(false)
          }
        } else if (
          searchData?.status === utils?.apiResponseCode?.internalServerError
        ) {
          showToastMessage({
            message:
              (searchData?.data?.validationErrors?.length > 0 &&
                searchData?.data?.validationErrors[0]?.errorMessage) ||
              searchData?.data?.message,
            status: 'error',
          })
          showLoader(false)
        } else if (searchData?.status === utils?.apiResponseCode?.badRequest) {
          showToastMessage({
            message:
              (searchData?.data?.validationErrors?.length > 0 &&
                searchData?.data?.validationErrors[0]?.errorMessage) ||
              searchData?.data?.message,
            status: 'error',
          })
          showLoader(false)
        } else {
          showToastMessage({
            message:
              searchData?.data?.validationErrors?.length > 0 &&
              searchData?.data?.validationErrors[0]?.errorMessage,
            status: 'error',
          })
          showLoader(false)
        }
        showLoader(false)
      }

      if (hasChanges) {
        const gobackres = utils?.confirmwindow(
          'Please save your data before switching to another case.'
        )
        if (gobackres) {
          showLoader(true)
          setTimeout(() => {
            getSearchData()
          }, 1000)
        } else {
          showLoader(false)
        }
      } else {
        showLoader(true)
        setTimeout(() => {
          getSearchData()
        }, 1000)
      }
    }
  }, [searclick])

  const modalclose = () => {
    setState((Prev) => ({
      ...Prev,
      modalOpen: false,
    }))
  }

  return (
    <div className="searchIcon">
      <Search
        className={
          result?.length === 0 && errMsg === ''
            ? 'childern__hidden'
            : 'childern__visible'
        }
        data-testid="searchbarInput"
        category
        //categoryLayoutRenderer={categoryLayoutRenderer}
        //categoryRenderer={categoryRenderer}
        loading={isLoading}
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        //resultRenderer={resultRenderer}
        results={result}
        value={value || state?.searchName}
        onKeyDown={listenEnter}
        input="text"
        placeholder={utils?.fpnBasedChanges?.globalSearch}
        noResultsMessage={errMsg}
        icon={
          <div className="searchiconmaindiv">
            {value?.toString()?.length > 0 ||
            state?.searchName?.toString()?.length > 0 ? (
              <CloseOutlinedIcon
                onClick={onCleartextBoxValue}
                fontSize="medium"
                data-testid="closeIcon"
              />
            ) : null}
            <img
              width="16"
              height="16"
              className="searchiconimage"
              src={SearchIcon}
              onClick={handleSearchResult}
              data-testid="iconSearch"
            />
          </div>
        }
        fluid
      />

      {state?.modalOpen && state?.dataSource?.length > 0 ? (
        <ReactModal
          searchData={state}
          modalOpen={state?.modalOpen}
          setmodalOpen={modalclose}
          modaltype="SearchBar"
        />
      ) : null}
    </div>
  )
}

export default SearchBar
