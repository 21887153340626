/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/*
  This HelpText component is used to show tooltip at any component or page
  longText  : This can be any html string or text content which will be parsed and shown as tooltip when hover on help icon
*/
import React from 'react'
import HelpIcon from '@mui/icons-material/Help'
import parse from 'html-react-parser'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'

import './HelpText.css'

function HelpText({ longText = '' }) {
  // parse and renders helptext only if there is helptext content to show
  return longText ? (
    <div className="helpTextIcon">
      <Tooltip
        title={longText && <div data-testid="LongText">{parse(longText)}</div>}
        arrow
      >
        <HelpIcon className="tooltip" data-testid="OpenButton" />
      </Tooltip>
    </div>
  ) : null
}

HelpText.defaultProps = {
  longText: '',
}
HelpText.propTypes = {
  longText: PropTypes.string,
}

export default HelpText
