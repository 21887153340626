/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

// When the Domain of the given username from sign-in page doesn't map with any user-flow then this page will be redirected
/* istanbul ignore file */
// System defined variables
import React from 'react'
import { useSetRecoilState } from 'recoil'

// Custom defined variables
import waveDesignFooter from '../../src/assets/static/wavedesignfooter.svg'
import marstonGroup from '../../src/assets/static/marstongroup.svg'
import inActiveLogo from '../../src/assets/static//inactivelogo.svg'
import Button from '../../src/views/components/base/button/Button'
import { currentUsernameAtom } from '../../src/views/components/recoil/atoms/atoms'
import './InvalidDomainPage.css'
import utils from '../utils/utils'

const InvalidDomainPage = () => {
  const setCurrentUsername = useSetRecoilState(currentUsernameAtom)

  function backToHome() {
    setCurrentUsername('')
  }

  let renderComponent = (
    <div className="invalidDomain">
      <div className="invalidDomain__pageImageGroup">
        <div className="invalidDomain__marstonGroupImage">
          <img className="marstonGroupImage" src={marstonGroup} />
        </div>
        <div className="invalidDomain__image404">
          <img className="imageAvator" src={inActiveLogo} />
        </div>
        <div className="invalidDomain__userPermissionInsufficientString">
          {utils?.userPermissionMessage?.invalidUser}
        </div>
        <div className="invalidDomain__userPermissionAccount">
          {utils?.userPermissionMessage?.accountPermissionAction}
        </div>
        <div className="invalidDomain__backButton">
          {' '}
          <Button
            Field={{
              fieldValue: 'Return to login',
              fieldLabel: 'Return to login',
              variant: 'primary',
            }}
            className="saveButton"
            onClickHandler={() => backToHome()}
            dataTestId={'returnToLoginButton'}
          />
        </div>
      </div>

      <div className="invalidDomain__footerImage">
        <img className="footerImage" src={waveDesignFooter} />
      </div>
    </div>
  )
  return renderComponent
}

export default InvalidDomainPage
