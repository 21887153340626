// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
.tooltip {
  height: 16px !important;
  width: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.MuiTooltip-arrow {
  color: #3f51b5;
}

.tooltip.MuiSvgIcon-root {
  color: #999999;
  width: 1.125rem;
  display: flex;
}

.helpTextIcon {
  cursor: pointer;
  pointer-events: all;
  height: 14px;
  width: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/helpText/HelpText.css"],"names":[],"mappings":"AAAA;;;CAGC;AACD;EACE,uBAAuB;EACvB,sBAAsB;EACtB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,aAAa;AACf;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,YAAY;EACZ,WAAW;AACb","sourcesContent":["/*\nThis computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), \nis the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.\n*/\n.tooltip {\n  height: 16px !important;\n  width: 16px !important;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\n.MuiTooltip-arrow {\n  color: #3f51b5;\n}\n\n.tooltip.MuiSvgIcon-root {\n  color: #999999;\n  width: 1.125rem;\n  display: flex;\n}\n\n.helpTextIcon {\n  cursor: pointer;\n  pointer-events: all;\n  height: 14px;\n  width: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
