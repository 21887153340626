// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
.avataricon {
  display: flex;
  cursor: pointer;
}

.avataricon__avatar-image {
  width: 2.0833vw;
  height: 2.08333vh;
}

.avataricon .MuiAvatar-colorDefault {
  background-color: #1f3c88;
  color: #ffffff;
  font-size: 1.625rem;
  font-family: var(--default-FontName);
  /* changed from Segoe UI to branding */
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/avataricon/AvatarIcon.css"],"names":[],"mappings":"AAAA;;;CAGC;AACD;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,mBAAmB;EACnB,oCAAoC;EACpC,sCAAsC;AACxC","sourcesContent":["/*\nThis computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), \nis the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.\n*/\n.avataricon {\n  display: flex;\n  cursor: pointer;\n}\n\n.avataricon__avatar-image {\n  width: 2.0833vw;\n  height: 2.08333vh;\n}\n\n.avataricon .MuiAvatar-colorDefault {\n  background-color: #1f3c88;\n  color: #ffffff;\n  font-size: 1.625rem;\n  font-family: var(--default-FontName);\n  /* changed from Segoe UI to branding */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
