// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

.moreverticonmenuitem .MuiTypography-root {
  margin: 0;
}

.MuiPopover-root.templatesPage {
  margin-left: -110px;
}

.moreverticonmenuitem.disabled.SendEmail,
.moreverticonmenuitem.disabled.Download {
  pointer-events: none;
  opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/moreVertIcon/MoreVertIcon.css"],"names":[],"mappings":"AAAA;;;CAGC;;AAED;EACE,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,oBAAoB;EACpB,YAAY;AACd","sourcesContent":["/*\nThis computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), \nis the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.\n*/\n\n.moreverticonmenuitem .MuiTypography-root {\n  margin: 0;\n}\n\n.MuiPopover-root.templatesPage {\n  margin-left: -110px;\n}\n\n.moreverticonmenuitem.disabled.SendEmail,\n.moreverticonmenuitem.disabled.Download {\n  pointer-events: none;\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
