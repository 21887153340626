// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
.splashDiv {
  position: relative;
  z-index: 2012;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: white;
}

.splashDiv .splashImage {
  height: 15vh;
  margin: auto;
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/custom/splashComponent/SplashComponent.css"],"names":[],"mappings":"AAAA;;;CAGC;AACD;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,cAAc;AAChB","sourcesContent":["/*\nThis computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), \nis the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.\n*/\n.splashDiv {\n  position: relative;\n  z-index: 2012;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  background-color: white;\n}\n\n.splashDiv .splashImage {\n  height: 15vh;\n  margin: auto;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
