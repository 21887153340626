/**
 * @file        selectors.js
 * @description This file is used to manage the function for update the state value(s)
 *              It contains recoil's selectors files to manage the function for update the state value(s)               
 *              selector : This is an recoil's default library to manage the function to update the global state values, 
 *                         This selector is an function and it recieve parameter as object with containing key, setter and getter function to update and retur values.
 *              key      : This is an unique key to access this atom function from component.
 *              get      : This is a function that evaluates the value for the derived state,                 
 *                         It may return either a value directly, an asynchronous Promise , a Loadable , or another atom or selector representing the same type.
 *              set      : Recoil manages atom and selector state changes to know when to notify components subscribing to that selector to re-render.
 *                         If an object value of a selector is mutated directly it may bypass this and avoid properly notifying subscribing components.
 * @version     0.1.0
 * @copyright   This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
                is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd.
 * @license     All rights reserved.
 */

import { selector } from 'recoil' //selectorFamily
import axios from 'axios'
import { trackPromise } from 'react-promise-tracker'
import {
  axiosConfiguration,
  userTokenDetails,
  runtimeTokenExpiredAtom,
  errorCodes,
} from '../atoms/atoms' //selectedUser, CaseOfUsers
import utils from '../../../../utils/utils'

const fetchDataSelector = selector({
  key: 'fetchDataSelector',
  get: ({ get, getCallback }) => {
    let statusCode = ''
    const config = get(axiosConfiguration)
    const authorizationTokenValue = get(userTokenDetails)
    if (config && Object.keys(config).length) {
      const fetchData = async (
        method,
        url,
        postData,
        header,
        requestParams,
        enableTrackPromise = true,
        domainName
      ) => {
        let baseURLDomain = 'BackOfficeAPIURL'

        if (domainName === 'usePermission') {
          baseURLDomain = 'PermissionDataAPIURL'
        } else if (domainName?.toString()?.toLowerCase() === 'purge') {
          baseURLDomain = 'PurgeRestAPIURL'
        } else if (domainName === 'haloIntegration') {
          baseURLDomain = 'HALOSiteURL'
        } else if (domainName === 'clampandRemoval') {
          baseURLDomain = 'BackOfficeIntegrationServiceAPIURL'
        } else if (domainName === 'liveTracking') {
          baseURLDomain = 'CEOLiveTrackingAPIURL'
        }

        /* if (domainName === 'windowsservice')
          baseURLDomain = 'BackOfficeWindowsAPIURL' */
        const headerObject = {
          'Content-type': header?.contentType ?? 'application/json',
          Authorization: `Bearer ${
            header?.authorization ?? authorizationTokenValue?.token
          }`,
        }
        const axiosInstance = axios.create({
          baseURL: config?.VariableSettings[baseURLDomain] ?? '',
          headers: {
            ...headerObject,
          },
        })
        let requestHeaders = header
        //const useIdValue = ''
        //const useNameValue = ''
        // if (signInRequired === 'false') {
        //   requestHeaders = {
        //     ...requestHeaders,
        //     UserType: `Guest`,
        //   }
        // }
        if (!(header && header?.FunctionKey)) {
          requestHeaders = {
            ...requestHeaders,
          }
        }
        const updateTokenExpiredAtom = getCallback(
          async ({ set /* snapshot */ }) => {
            /* const currentValue = await snapshot.getPromise(
                      runtimeTokenExpiredAtom
                    )
                    //instead of get we use snapshot to read atom's value
                     */

            set(runtimeTokenExpiredAtom, true) // when unauthorized error got from API at runtime then update this atom to true -> which redirect to tokenExpired screen in App.js
          }
        )
        const errorCodePage = getCallback(async ({ set }) => {
          set(errorCodes, { errorCode: statusCode, showErrorPage: true }) // when error got from API at runtime
        })
        const getData = async () => {
          const response = await axiosInstance({
            method,
            url,
            data: postData,
            headers: requestHeaders,
            params: requestParams,
          })
            .then((responseData) => {
              return responseData
            })
            .catch((err) => {
              if (
                err?.response?.status === utils?.apiResponseCode?.unauthorized // code of unauthorized
              ) {
                updateTokenExpiredAtom()
              }
              // commented due to confirmation pending to move errorPage implementation
              // else if (
              //   ['404', '504', '503', '500', '400'].includes(
              //     err?.response?.status?.toString()
              //   )
              // ) {
              //   statusCode = err?.response?.status
              //   errorCodePage()
              // }
              return err?.response || err
            })
          return response
        }
        if (enableTrackPromise) {
          const response = await trackPromise(getData())
          return response
        }
        const response = getData()

        return response
      }
      return fetchData
    }
    return utils?.emptyFunction
  },
})

export { fetchDataSelector }
