/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/* Added this custom renderer to have ellipsis and middle alignment for grid-cell data*/
/* istanbul ignore file */
/* ignoring due to usage of recoil */

// System defined variables
import React, {
  useEffect,
  memo,
  useImperativeHandle,
  useRef,
  forwardRef,
  useState,
} from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Tooltip, Radio } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ReactSVG } from 'react-svg'
import { DateTime } from 'luxon'
import axios from 'axios'

// Custom defined variables.
import utils from '../../../../utils/utils'
import activeCircle from '../../../../assets/static/activeCircle.svg'
import eyeIcon from '../../../../assets/static/eyeIcon.svg'
import batchProcessIcon from '../../../../assets/static/batchProcessingIcon.svg'
import inactiveCircle from '../../../../assets/static/inactiveCircle.svg'
import DeleteIconFill from '../../../../assets/images/DeleteIconFill.svg'
import DownloadFileIcon from '../../../../assets/static/downloadFileIcon.svg'
import TrashFill from '../../../../assets/images/TrashFill.svg'
import TrashFillGrey from '../../../../assets/images/TrashFillGrey.svg'
import {
  currencyAtom,
  showLoading,
  timezoneAtom,
  toastMessage,
} from '../../recoil/atoms/atoms'
import RadioGroupControl from '../radioGroupControl/RadioGroupControl'
import Button from '../button/Button'
import CheckboxControl from '../checkboxControl/CheckboxControl'
import jpgIcon from '../../../../assets/static/imageIcon.svg' // commented for reference
import pdfIcon from '../../../../assets/static/pdfIcon.svg' // commented for reference
import jpgIconCR from '../../../../assets/static/jpgIconCR.svg'
import pdfIconCR from '../../../../assets/static/pdfIconCR.svg'
import docIcon from '../../../../assets/static/docIcon.svg'
import pngIcon from '../../../../assets/static/pngIcon.svg' // commented for reference
import InfectedVirus from '../../../../assets/images/InfectedVirus.svg'
import ScanFail from '../../../../assets/images/ScanFail.svg'

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace'
const KEY_F2 = 'F2'
const KEY_ENTER = 'Enter'
const KEY_TAB = 'Tab'

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        arrow: () => ({
          color: 'var(--tooltipBackgroundColor) !important',
        }),
        tooltip: {
          fontFamily: 'var(--default-FontName) !important',
          fontSize: 'var(--tooltipFontSize) !important',
          fontWeight: 'var(--default-FontWeight) !important',
          color: 'var(--tooltipFontColor) !important',
          backgroundColor: 'var(--tooltipBackgroundColor) !important',
          margin: '0px !important',
          marginBottom: '10px !important',
          borderRadius: 'var(--tooltipBorderRadius) !important',
        },
      },
    },
  },
})
const customIconHeaderTooltipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        arrow: () => ({
          color: 'var(--tooltipBackgroundColor) !important',
        }),
        tooltip: {
          fontFamily: 'var(--default-FontName) !important',
          fontSize: 'var(--tooltipFontSize) !important',
          fontWeight: 'var(--default-FontWeight) !important',
          color: 'var(--tooltipFontColor) !important',
          backgroundColor: 'var(--tooltipBackgroundColor) !important',
          margin: '0px !important',
          marginBottom: '10px !important',
          borderRadius: 'var(--tooltipBorderRadius) !important',
        },
      },
    },
  },
})

//enableTitleOnHover
export const customTextRenderer = (
  params = true,
  errorText = false,
  truckId = false,
  jobId = false
) => {
  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    if (truckId) {
      return (
        <div className="gridCellFlexBox">
          <span
            className={`gridCellDisplayData ${
              errorText && Object.keys(errorText)?.length > 0 ? 'errorText' : ''
            }`}
            title={params?.data[params?.colDef?.field]}
          >
            {params?.data[params?.colDef?.field] &&
              `T${params?.data[params?.colDef?.field]}`}
          </span>
        </div>
      )
    } else if (jobId) {
      let digitFormat = ''
      let fieldLength = params?.data[params?.colDef?.field]
        ?.toString()
        ?.toLowerCase()?.length
      if (fieldLength === 1) {
        digitFormat = '00'
      } else if (fieldLength === 2) {
        digitFormat = '0'
      }
      return (
        <div className="gridCellFlexBox">
          <span
            className={`gridCellDisplayData ${
              errorText && Object.keys(errorText)?.length > 0 ? 'errorText' : ''
            }`}
            title={params?.data[params?.colDef?.field]}
          >
            {`${digitFormat}${params?.data[params?.colDef?.field]}`}
          </span>
        </div>
      )
    } else {
      return (
        <div className="gridCellFlexBox">
          <span
            className={`gridCellDisplayData ${
              errorText && Object.keys(errorText)?.length > 0 ? 'errorText' : ''
            }`}
            // Disable the existing validation of enableTitleOnHover due display the tooltip to all grid cells.
            // title={
            //   enableTitleOnHover === true
            //     ? params?.data[params?.colDef?.field]
            //     : ''
            // }
            title={params?.data[params?.colDef?.field]}
          >
            {params?.data[params?.colDef?.field]}
          </span>
        </div>
      )
    }
  }
}

// Boolean value render function
export const customBooleanTextRenderer = (params = true) => {
  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className="gridCellFlexBox">
        <span
          className="gridCellDisplayData"
          title={params?.data[params?.colDef?.field]?.toString()}
        >
          {params?.data[params?.colDef?.field]?.toString()}
        </span>
      </div>
    )
  }
}

export const customIndicateRenderer = (params, truValue = true) => {
  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className="gridCellFlexBox">
        <img
          className="statusIndicator"
          src={
            params?.data[params?.colDef?.field] === truValue
              ? activeCircle
              : inactiveCircle
          }
        />
      </div>
    )
  }
}

export const customEyeIconIndicateRenderer = (
  showDownloadPopupPage,
  params,
  //truValue = true,
  justifyCentre
) => {
  if (params?.data && Object.keys(params?.data)?.length > 0) {
    return (
      <div
        className={`gridCellFlexBox enableCursor ${
          justifyCentre ? 'justifyCentre' : ''
        }`}
      >
        <img
          data-testId={`view${
            params?.data?.hasOwnProperty('batchReference')
              ? params?.data['batchReference']
              : ''
          }`}
          id="viewBatchFile"
          className="statusIndicator eyeIcon"
          onClick={() => showDownloadPopupPage(params?.data)}
          src={params?.data?.isPrintLocal ? batchProcessIcon : eyeIcon}
        />
      </div>
    )
  }
}

export const fileDownloadIconRender = (params, fileDownloaderFunction) => {
  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className="gridCellFlexBox">
        <ReactSVG
          className="fileDownloader reactSvgDiv"
          src={DownloadFileIcon}
          onClick={() =>
            fileDownloaderFunction(
              params?.data?.fileLocation,
              params?.data?.fileName
            )
          }
        />
      </div>
    )
  }
}

export const customOptionsRenderer = (
  onChangeMessageType,
  params
  //actionDataParams
  //truValue = true
) => {
  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className="gridCellFlexBox">
        <RadioGroupControl
          field={{
            fieldValue: params?.data[params?.colDef?.field],
            id: 'rec_actionType',
            dataTestId: 'rec_actionType',
            dataSource: [
              { name: 'Cancel Case', id: 'cancelCase' },
              { name: 'Progress', id: 'progress' },
            ],
            valueField: 'id',
            textField: 'name',
            rowDataParams: params,
          }}
          onChangeHandler={onChangeMessageType}
        />
      </div>
    )
  }
}

export const customSOptionsRenderer = (
  onChangeMessageType,
  params,
  selectValue,
  contractType
) => {
  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className="gridCellFlexBox">
        <Radio
          checked={
            selectValue &&
            selectValue.toString() ===
              params?.data[params?.colDef?.field]?.toString()
          }
          onClick={() =>
            onChangeMessageType(
              params?.data[params?.colDef?.field]?.toString(),
              params
            )
          }
          value={params?.data[params?.colDef?.field]?.toString()}
          name="radio-buttons"
          disabled={contractType === utils?.contractTypes?.fpnName}
          className={
            contractType === utils?.contractTypes?.fpnName
              ? 'fpnDisabledRadioBox'
              : ''
          }
        />
      </div>
    )
  }
}

export const customDateRenderer = (
  params,
  timezoneType
  //enableTitleOnHover = true
) => {
  if (
    params?.data?.hasOwnProperty(params?.colDef?.field) &&
    params?.data[params?.colDef?.field]
  ) {
    let dataValue = ''
    //  checking if the date value contains time 'T' then need to get UTC of it and show date.
    if (!params?.data[params?.colDef?.field].includes('T')) {
      const timezoneData = useRecoilValue(timezoneAtom)
      let ianaTimezone = ''
      if (timezoneType === 'contract') {
        ianaTimezone = timezoneData?.selectedContract?.timezoneId
      } else if (timezoneType === 'case') {
        ianaTimezone = timezoneData?.searchedCase?.timezoneId
      } else {
        ianaTimezone = timezoneData?.default?.timezoneId
      }
      const convertedDateTime = utils?.convertUtcDateTimeToTimezoneDate(
        ianaTimezone,
        params?.data[params?.colDef?.field]
      )
      dataValue = convertedDateTime
    } else {
      dataValue = DateTime.fromISO(
        params?.data[params?.colDef?.field]
      ).toFormat(utils?.commonDateTimeFormat('Date'))
    }

    return (
      <div className="gridCellFlexBox">
        <span
          className="gridCellDisplayData"
          // Disable the existing validation of enableTitleOnHover due display the tooltip to all grid cells.
          // title={
          //   enableTitleOnHover === true
          //     ? params?.data[params?.colDef?.field]
          //     : ''
          // }
          title={dataValue}
        >
          {dataValue}
        </span>
      </div>
    )
  }
}
export const customDateTimeRenderer = (
  params,
  timezoneType
  //enableTitleOnHover = true
) => {
  if (
    params?.data?.hasOwnProperty(params?.colDef?.field) &&
    params?.data[params?.colDef?.field]
  ) {
    const timezoneData = useRecoilValue(timezoneAtom)
    let ianaTimezone = ''
    if (timezoneType === 'contract') {
      ianaTimezone = timezoneData?.selectedContract?.timezoneId
    } else if (timezoneType === 'case') {
      ianaTimezone = timezoneData?.searchedCase?.timezoneId
    } else {
      ianaTimezone = timezoneData?.default?.timezoneId
    }
    const convertedDateTime = utils?.convertUtcDateTimeToTimezoneDateTime(
      ianaTimezone,
      params?.data[params?.colDef?.field]
    )
    const dataValue = convertedDateTime
    return (
      <div className="gridCellFlexBox">
        <span
          className="gridCellDisplayData"
          // Disable the existing validation of enableTitleOnHover due display the tooltip to all grid cells.
          // title={
          //   enableTitleOnHover === true
          //     ? params?.data[params?.colDef?.field]
          //     : ''
          // }
          title={dataValue}
        >
          {dataValue}
        </span>
      </div>
    )
  }
}
//enableTitleOnHover
export const customTimeRenderer = (params = true) => {
  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className="gridCellFlexBox">
        <span
          className="gridCellDisplayData"
          // Disable the existing validation of enableTitleOnHover due display the tooltip to all grid cells.
          // title={
          //   enableTitleOnHover === true
          //     ? params?.data[params?.colDef?.field]
          //     : ''
          // }
          title={params?.data[params?.colDef?.field]}
        >
          {params?.data[params?.colDef?.field]}
        </span>
      </div>
    )
  }
}
export const customFormattedNumberRenderer = (
  params
  //enableTitleOnHover = true
) => {
  if (
    params?.data?.hasOwnProperty(params?.colDef?.field) &&
    params?.data[params?.colDef?.field]
  ) {
    const dataValue = utils?.setDecimalValue(
      params?.data[params?.colDef?.field],
      2
    )
    if (params?.data?.hasOwnProperty(params?.colDef?.field))
      return (
        <div className="gridCellFlexBox">
          <span
            className="gridCellDisplayData"
            // Disable the existing validation of enableTitleOnHover due display the tooltip to all grid cells.
            // title={
            //   enableTitleOnHover === true
            //     ? params?.data[params?.colDef?.field]
            //     : ''
            // }
            title={dataValue}
          >
            {dataValue}
          </span>
        </div>
      )
  }
}
// used in payment Tab to show the amount in red color when type = refund/transfer
export const customPaymentAmountRenderer = (
  params
  //enableTitleOnHover = true
) => {
  const currencyData = useRecoilValue(currencyAtom)

  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    let dataValue = utils?.setDecimalValue(
      params?.data[params?.colDef?.field],
      2
    )
    if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
      const currencySymbol =
        currencyData?.searchedCase?.currencySymbol === ''
          ? currencyData?.selectedContract?.currencySymbol
          : currencyData?.searchedCase?.currencySymbol

      return (
        <div className={`gridCellFlexBox `}>
          <span
            className={`gridCellDisplayData`}
            // Disable the existing validation of enableTitleOnHover due display the tooltip to all grid cells.
            // title={enableTitleOnHover === true ? dataValue : ''}
            title={`${currencySymbol} ${dataValue}`}
          >
            <span>{`${currencySymbol} `}</span>
            <span className={`${dataValue < 0 ? 'highlightPayment' : ''}`}>
              {`${dataValue}`}
            </span>
          </span>
        </div>
      )
    }
  }
}
// used in payment Tab to show the amount in red color when type = refund/transfer
export const customAmountWithCurrencyRenderer = (
  params
  //enableTitleOnHover = true
) => {
  const currencyData = useRecoilValue(currencyAtom)
  if (
    params?.data?.hasOwnProperty(params?.colDef?.field) &&
    params?.data[params?.colDef?.field]
  ) {
    let dataValue = utils?.setDecimalValue(
      params?.data[params?.colDef?.field],
      2
    )
    if (params?.data?.hasOwnProperty(params?.colDef?.field))
      return (
        <div className={`gridCellFlexBox `}>
          <span
            className={`gridCellDisplayData`}
            // Disable the existing validation of enableTitleOnHover due display the tooltip to all grid cells.
            // title={enableTitleOnHover === true ? dataValue : ''}
            title={`${currencyData?.searchedCase?.currencySymbol} ${dataValue}`}
          >
            <span className={``}>
              {`${currencyData?.searchedCase?.currencySymbol} ${dataValue}`}
            </span>
          </span>
        </div>
      )
  }
}

// Delete Icon for each row

export const deleteIconProvider = (params, onDelete) => {
  // Checking action === false (due to newly added row doesn't contain action key. action will return only from backend)
  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className="gridCellFlexBox">
        <ReactSVG
          className={`statusIndicator reactSvgDiv ${
            params?.data?.action === false ? 'disableAction' : ''
          }`}
          src={DeleteIconFill}
          onClick={
            params?.data?.action === false ? null : () => onDelete(params)
          }
        />
        {/* <img
          className="statusIndicator"
          src={DeleteIconFill}
          onClick={() => onDelete(params)}
        /> */}
      </div>
    )
  }
}
//Ignoring this due to usage of ag-grid cell click
/* istanbul ignore next */
export const manualDiscountListDeleteIcon = (
  params,
  onDelete,
  disabledFieldName,
  disabledValue,
  readOnlyUser
) => {
  // Checking action === false (due to newly added row doesn't contain action key. action will return only from backend)
  if (params?.data?.hasOwnProperty(disabledFieldName)) {
    const getIcon = () => {
      let icon = TrashFill
      if (disabledFieldName || readOnlyUser) {
        if (params?.data[disabledFieldName] === disabledValue || readOnlyUser) {
          icon = TrashFillGrey
        } else {
          icon = TrashFill
        }
      }
      return icon
    }
    const getDisabledAction = () => {
      let disabled = false
      if (disabledFieldName || readOnlyUser) {
        if (params?.data[disabledFieldName] === disabledValue || readOnlyUser) {
          disabled = true
        }
      }
      return disabled
    }
    return (
      <div className="gridCellFlexBox">
        <ReactSVG
          className={`statusIndicator reactSvgDiv ${
            getDisabledAction() ? 'disableAction' : ''
          }`}
          src={getIcon()}
          onClick={getDisabledAction() ? () => {} : () => onDelete(params)}
        />
      </div>
    )
  }
}

export const textRenderWithCustomTooltip = (
  params,
  data, //adding this data due to ag-grid passing empty object as 2nd argument
  position = 'top-end'
) => {
  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className="gridCellFlexBox">
        <div className="gridCellDisplayData">
          <ThemeProvider theme={theme}>
            <Tooltip
              placement={position}
              title={params?.data[params?.colDef?.field]}
              arrow
            >
              <span className="cellDisplayText">
                {params?.data[params?.colDef?.field]}
              </span>
            </Tooltip>
          </ThemeProvider>
        </div>
      </div>
    )
  }
}

export const CustomCellRendererLinkClass = (params, clickData) => {
  return (
    <div className="gridCellFlexBox">
      {' '}
      <a
        className="gridLinkclass gridCellDisplayData"
        onClick={() => clickData(params)}
        title={params?.data?.caseNumber}
      >
        {params?.data?.caseNumber}
      </a>
    </div>
  )
}

export const customFormattedNumberRendererWithoutDecimal = (
  params
  //enableTitleOnHover = true
) => {
  if (
    params?.data?.hasOwnProperty(params?.colDef?.field) &&
    params?.data[params?.colDef?.field]
  ) {
    const dataValue = params?.data[params?.colDef?.field]
    if (params?.data?.hasOwnProperty(params?.colDef?.field))
      return (
        <div className="gridCellFlexBox">
          <span
            className="gridCellDisplayData"
            // Disable the existing validation of enableTitleOnHover due display the tooltip to all grid cells.
            // title={
            //   enableTitleOnHover === true
            //     ? params?.data[params?.colDef?.field]
            //     : ''
            // }
            title={dataValue}
          >
            {dataValue}
          </span>
        </div>
      )
  }
}
export const customPaymentStatusRenderer = (params = true) => {
  let paymentStatus = ''
  const decidingPaymentStatus = () => {
    if (
      params?.data[params?.colDef?.field]?.toString()?.toLowerCase() ===
      'success'
    ) {
      paymentStatus = true
    } else if (
      params?.data[params?.colDef?.field]?.toString()?.toLowerCase() ===
        'failed' ||
      params?.data[params?.colDef?.field]?.toString()?.toLowerCase() ===
        'failure'
    ) {
      paymentStatus = false
    }
  }
  if (params?.data?.status) {
    decidingPaymentStatus()
  }

  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className="gridCellFlexBox">
        <span
          className={`gridCellDisplayData paymentStatusClass ${
            paymentStatus === true
              ? 'paymentStatusSuccess'
              : paymentStatus === false
              ? 'paymentStatusFailure'
              : 'leaveItEmpty'
          }`}
          title={params?.data[params?.colDef?.field]}
        >
          {paymentStatus === false
            ? 'Failed'
            : paymentStatus === true
            ? 'Success'
            : ''}
        </span>
      </div>
    )
  }
}

export const customOcrActionRenderer = (
  params,
  agObject,
  justifyCentre,
  viewHandler,
  assignHandler,
  deleteHandler
) => {
  return (
    <div className={`gridCellFlexBox ${justifyCentre ? 'justifyCentre' : ''}`}>
      <span
        className={`gridCellDisplayData
        }`}
        title=""
      >
        {params?.data?.source?.toString()?.toLowerCase() ===
        utils?.sourceTextMap?.ocrError?.key ? (
          <div className="ocrActions">
            <Button
              Field={{
                fieldValue: 'btnView',
                fieldLabel: 'View',
                type: 'secondary',
                disabled: false,
              }}
              className=""
              onClickHandler={() => viewHandler(params)}
              dataTestId="btnView"
            />
            <Button
              Field={{
                fieldValue: 'btnAssign',
                fieldLabel: 'Assign',
                type: 'secondary',
                disabled: false,
              }}
              className=""
              onClickHandler={() => assignHandler(params)}
              dataTestId="btnAssign"
            />
            <Button
              Field={{
                fieldValue: 'btnDelete',
                fieldLabel: 'Delete',
                type: 'secondary',
                disabled: false,
              }}
              className=""
              onClickHandler={() => deleteHandler(params)}
              dataTestId="btnDelete"
            />
          </div>
        ) : null}
      </span>
    </div>
  )
}

export const customUnassignedCaseNumberRenderer = (params = true) => {
  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className="gridCellFlexBox">
        <span
          className="gridCellDisplayData"
          title={params?.data[params?.colDef?.field]}
        >
          {params?.data[params?.colDef?.field]
            ? params?.data[params?.colDef?.field]
            : params?.data?.source?.toString()?.toLowerCase() ===
              utils?.sourceTextMap?.ocrError?.key
            ? '-'
            : params?.data[params?.colDef?.field]}
        </span>
      </div>
    )
  }
}

export const numericEditor = memo(
  forwardRef((props, ref) => {
    const createInitialState = () => {
      let startValue
      let highlightAllOnFocus = true
      const eventKey = props.eventKey

      if (eventKey === KEY_BACKSPACE) {
        // if backspace or delete pressed, we clear the cell
        startValue = ''
      } else if (eventKey && eventKey.length === 1) {
        // if a letter was pressed, we start with the letter
        startValue = eventKey
        highlightAllOnFocus = false
      } else {
        // otherwise we start with the current value
        startValue = props.value
        if (props.eventKey === KEY_F2) {
          highlightAllOnFocus = false
        }
      }

      return {
        value: startValue,
        highlightAllOnFocus,
      }
    }

    const initialState = createInitialState()
    const [value, setValue] = useState(initialState.value)
    const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(
      initialState.highlightAllOnFocus
    )
    const refInput = useRef(null)

    // focus on the input
    useEffect(() => {
      // get ref from React component
      const eInput = refInput.current
      eInput.focus()
      if (highlightAllOnFocus) {
        eInput.select()

        setHighlightAllOnFocus(false)
      } else {
        // when we started editing, we want the caret at the end, not the start.
        // this comes into play in two scenarios:
        //   a) when user hits F2
        //   b) when user hits a printable character
        const length = eInput.value ? eInput.value.length : 0
        if (length > 0) {
          eInput.setSelectionRange(length, length)
        }
      }
    }, [])

    /* Utility Methods */
    const cancelBeforeStart =
      props.eventKey &&
      props.eventKey.length === 1 &&
      '1234567890'.indexOf(props.eventKey) < 0

    const isLeftOrRight = (event) => {
      return ['ArrowLeft', 'ArrowLeft'].indexOf(event.key) > -1
    }

    const isCharNumeric = (charStr) => {
      return !!/\d/.test(charStr)
    }

    const isNumericKey = (event) => {
      const charStr = event.key
      return isCharNumeric(charStr)
    }

    const isBackspace = (event) => {
      return event.key === KEY_BACKSPACE
    }

    const finishedEditingPressed = (event) => {
      const key = event.key
      return key === KEY_ENTER || key === KEY_TAB
    }

    const onKeyDown = (event) => {
      if (isLeftOrRight(event) || isBackspace(event)) {
        event.stopPropagation()
        return
      }

      if (!finishedEditingPressed(event) && !isNumericKey(event)) {
        if (event.preventDefault) event.preventDefault()
      }

      if (finishedEditingPressed(event)) {
        props.stopEditing()
      }
    }

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return value === '' || value == null ? null : parseInt(value)
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return cancelBeforeStart
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          // will reject the number if it greater than 1,000,000
          // not very practical, but demonstrates the method.
          const finalValue = this.getValue()
          return finalValue != null && finalValue > 1000000
        },
      }
    })

    return (
      <input
        ref={refInput}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onKeyDown={(event) => onKeyDown(event)}
        className="numeric-input"
      />
    )
  })
)

export const customHeaderHoverOnIconRenderer = (
  headerName,
  hoverText,
  icon
) => {
  if (icon)
    return (
      <div className="gridCellFlexBox customHeader">
        <div className="headerWithIcon">
          <p>{headerName}</p>
          <ThemeProvider theme={customIconHeaderTooltipTheme}>
            <Tooltip title={hoverText} placement="top" arrow={true}>
              <div>
                <ReactSVG
                  className="headerIcon reactSvgDiv"
                  src={icon}
                  onClick={() => {}}
                />
              </div>
            </Tooltip>
          </ThemeProvider>
        </div>
      </div>
    )
}

export const customCheckBoxHeader = (
  params,
  onChangeHandlerHeaderCheckbox,
  headerCheckedFun
) => {
  return (
    <div className="gridCellFlexBox">
      <CheckboxControl
        field={{
          value: headerCheckedFun(),
        }}
        onChangeHandler={onChangeHandlerHeaderCheckbox}
      />
    </div>
  )
}
export const customCheckBoxRenderer = (params, onChangeHandlerCheckbox) => {
  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className="gridCellFlexBox">
        <CheckboxControl
          field={{
            id: params?.data?.id,
            value: params?.node?.selected ? true : false,
          }}
          onChangeHandler={(event) =>
            onChangeHandlerCheckbox(event, params, params?.data?.npsCaseId)
          }
        />
      </div>
    )
  }
}

export const customCellIconRenderer = (
  params,
  icon,
  onClickHandler,
  iconClassName
) => {
  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className="gridCellFlexBox">
        <ReactSVG
          className={`customCellIcon reactSvgDiv ${
            iconClassName ? iconClassName : ''
          }`}
          src={icon}
          onClick={() => onClickHandler(params)}
        />
      </div>
    )
  }
}
// eyeIconProviderOnclick for each an row
export const eyeIconProviderOnclick = (params, onClick) => {
  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className={`gridCellFlexBox enableCursor justifyCentre`}>
        <img
          data-testId={'eyeIcon_Onclick'}
          id="eyeIcon_Onclick"
          className="statusIndicator eyeIcon"
          onClick={() => onClick(params)}
          src={eyeIcon}
        />
      </div>
    )
  }
}

export const downloadableFileNameWithIconRenderer = (
  params,
  data //adding this data due to ag-grid passing empty object as 2nd argument
) => {
  const setShowLoading = useSetRecoilState(showLoading)
  const showToastMessage = useSetRecoilState(toastMessage)

  let fileFormat = params?.data?.fileName
    .split('.')[1]
    ?.toString()
    ?.toLowerCase()
  const jpgIconFileFOrmats = ['jpg', 'jpeg', 'bmp', 'heic', 'tif', 'tiff']
  const pngIconFileFOrmats = ['png']
  const pdfIconFileFOrmats = ['pdf']
  const docIconFileFOrmats = ['doc', 'docx']

  const getIconForFile = (fileFormat) => {
    return jpgIconFileFOrmats?.includes(fileFormat)
      ? jpgIconCR
      : pngIconFileFOrmats?.includes(fileFormat)
      ? jpgIconCR
      : pdfIconFileFOrmats?.includes(fileFormat)
      ? pdfIconCR
      : docIconFileFOrmats?.includes(fileFormat)
      ? docIcon
      : null
  }
  async function onDownloadHandler(fileURL, fileName, data) {
    if (data?.fileUploadStatus !== 2 && data?.fileUploadStatus !== 4) {
      //The createObjectURL(creating new blob from blobURL) - done this to download the file with required filename.
      setShowLoading(true)
      await axios({
        url: fileURL,
        method: 'GET',
        responseType: 'blob',
      })
        .then((response) => {
          let createdBlobUrl = window.URL.createObjectURL(response?.data)
          downloadFile(createdBlobUrl, fileName)
        })
        .catch((error) => {
          showToastMessage({
            message: error?.response?.statusText,
            status: utils?.toastStatus?.error,
          })
        })
        .finally(() => {
          setShowLoading(false)
        })
    }
  }
  const downloadFile = (blobUrl, fileName) => {
    const aTag = document.createElement('a')
    aTag.href = blobUrl
    aTag.setAttribute('download', fileName)
    document.body.appendChild(aTag)
    aTag.click()
    aTag.remove()
  }

  const icon = getIconForFile(fileFormat)

  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className="gridCellFlexBox">
        <div
          className="gridCellDisplayData attachmentFileNameDiv"
          onDoubleClick={() =>
            onDownloadHandler(
              params?.data?.fileLocation,
              params?.data?.fileName,
              params?.data
            )
          }
        >
          <img
            className="fileFormatImg attachmentFIleIconSize"
            src={icon}
            // height={19}
            // width={14}
            alt="preview"
          />
          <span
            className={`fileName ${
              params?.data?.fileUploadStatus === 2 ||
              params?.data?.fileUploadStatus === 4
                ? ''
                : 'downloadable'
            } cellDisplayText`}
          >
            {params?.data?.fileName}
          </span>
          {params?.data?.fileUploadStatus === 2 ? (
            <Tooltip
              title={utils?.infectedVirusMessage}
              arrow
              placement="top"
              className="infectedVirusMessage"
            >
              <img
                data-testId="InfectedVirus "
                className="InfectedVirus-icon attachmentFIleIconSize"
                src={InfectedVirus}
              />
            </Tooltip>
          ) : (
            ''
          )}
          {params?.data?.fileUploadStatus === 4 ? (
            <Tooltip
              placement="top"
              arrow
              title={utils?.scanFailed}
              className="scanFailedMessage attachmentFIleIconSize"
            >
              <img
                data-testId="ScanFail"
                className="ScanFail-icon"
                src={ScanFail}
              />
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}
export const customHyperlinkTextRenderer = (params, data, onClickHandler) => {
  if (params?.data?.hasOwnProperty(params?.colDef?.field)) {
    return (
      <div className="gridCellFlexBox">
        <span
          className="gridCellDisplayData hyperlink primaryColor"
          title={params?.data[params?.colDef?.field]?.toString()}
          onClick={() => {
            onClickHandler(params)
          }}
        >
          {params?.data[params?.colDef?.field]?.toString()}
        </span>
      </div>
    )
  }
}
