/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  This RadioGroupControl component was used to handle the data to include or not from pages or other components.
*/
import React from 'react'
import {
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from '@mui/material'
import propTypes from 'prop-types'
import './RadioGroupControl.css'

function RadioGroupControl({ field, onChangeHandler, defaultValue }) {
  const onChange = (event) => {
    if (onChangeHandler) {
      onChangeHandler(event, {
        id: field?.fieldValue,
        value: event?.target?.value,
      })
    }
  }
  const renderRadioGroupControl = () => (
    <RadioGroup
      id={field?.id || 'cb_radioGroupControl'}
      data-testid={
        field?.dataTestId ? field?.dataTestId : 'cb_radioGroupControl'
      }
      className="radioGroupControl"
      color="default"
      disabled={field?.disabled || false}
      onChange={onChange}
      row
      value={defaultValue}
    >
      {field?.dataSource?.map((optionData, index) => (
        <FormControlLabel
          id={field?.textField + index}
          value={optionData[field?.valueField]}
          checked={
            field?.rowDataParams
              ? field?.rowDataParams?.data?.actionValues[
                  optionData[field?.valueField]
                ]
              : optionData['optionSelected']
          }
          control={<Radio size="small" disableRipple />}
          label={
            optionData.hasOwnProperty([field?.textField])
              ? optionData[field?.textField]
              : optionData[field?.valueField] || ''
          }
        />
      ))}
    </RadioGroup>
  )
  return (
    <div className="RadioGroupControl">
      {field?.fieldLabel ? (
        <FormControlLabel
          control={renderRadioGroupControl()}
          className={field?.className}
          label={field?.fieldLabel}
        />
      ) : (
        renderRadioGroupControl()
      )}
    </div>
  )
}
export default RadioGroupControl

RadioGroupControl.propTypes = {
  field: propTypes.objectOf(propTypes.any),
  onChangeHandler: () => null,
}

RadioGroupControl.defaultProps = {
  field: {},
  onChangeHandler: () => {},
}
