// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  In this files, we write styles for LoadingComponent component
*/

.backdrop {
  color: var(--primary-color);
}

.backdrop.MuiBackdrop-root {
  z-index: 2009 !important;
}

.loadingControl {
  height: 100%;
  width: 100%;
  z-index: 2010;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/custom/loadingComponent/LoadingComponent.css"],"names":[],"mappings":"AAAA;;;CAGC;;AAED;;CAEC;;AAED;EACE,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;AACf","sourcesContent":["/*\nThis computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), \nis the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.\n*/\n\n/*\n  In this files, we write styles for LoadingComponent component\n*/\n\n.backdrop {\n  color: var(--primary-color);\n}\n\n.backdrop.MuiBackdrop-root {\n  z-index: 2009 !important;\n}\n\n.loadingControl {\n  height: 100%;\n  width: 100%;\n  z-index: 2010;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
