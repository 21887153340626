/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  This is the App starting file of the application.
  #RecoilRoot : Recoil used to manage the global state value(s)
  StrictMode  : StrictMode is a tool for highlighting potential problems in an application. Like Fragment , 
                StrictMode does not render any visible UI. It activates additional checks and warnings for its descendants.
*/
/* istanbul ignore file */
// System defined variables
import React from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'
// Custom defined variables
import ConfigLoader from './views/components/ConfigLoader.js'
import LoadingComponent from './views/components/custom/loadingComponent/LoadingComponent.js'
import { msalConfig } from './configs/msalAuthConfigs.js'
import SignInBridge from './views/pages/landingScreen/SignInBridge.js'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>

  <RecoilRoot>
    <LoadingComponent />
    <ConfigLoader
      ready={(props) => {
        const authorityURL = `${props?.VariableSettings?.B2CAuthority}`
        // assign value msalconfig properties from variableSettings
        msalConfig.auth.redirectUri = props?.VariableSettings?.BackOfficeUIURL
        msalConfig.auth.clientId = props?.VariableSettings?.B2CClientID
        msalConfig.auth.authority = authorityURL
        msalConfig.auth.knownAuthorities = [authorityURL]
        return <SignInBridge appConfig={props} msalConfig={msalConfig} />
      }}
    />
  </RecoilRoot>
  // </React.StrictMode>
)
