// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  In this files, we write styles for BackdropSpinner component
*/

.backdrop {
  color: var(--primary-color);
}

.backdrop.MuiBackdrop-root {
  z-index: 2009 !important;
}

.spinnerAll {
  display: grid;
  align-items: center;
  gap: 30px;
  text-align: center;
}

.spinnerText {
  /* background-color: #f5f5f5; */
  font-size: larger;
  font-weight: var(--header-FontWeight);
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/backdropSpinner/BackdropSpinner.css"],"names":[],"mappings":"AAAA;;;CAGC;;AAED;;CAEC;;AAED;EACE,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,iBAAiB;EACjB,qCAAqC;AACvC","sourcesContent":["/*\nThis computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), \nis the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.\n*/\n\n/*\n  In this files, we write styles for BackdropSpinner component\n*/\n\n.backdrop {\n  color: var(--primary-color);\n}\n\n.backdrop.MuiBackdrop-root {\n  z-index: 2009 !important;\n}\n\n.spinnerAll {\n  display: grid;\n  align-items: center;\n  gap: 30px;\n  text-align: center;\n}\n\n.spinnerText {\n  /* background-color: #f5f5f5; */\n  font-size: larger;\n  font-weight: var(--header-FontWeight);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
