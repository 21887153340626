/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/*
this page used to let the user to sign in the page
 */
// System defined variables
import React, { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { ReactSVG } from 'react-svg'

// Custom defined variables
import utils from '../../../utils/utils.js'
import {
  loggingOutAtom,
  LoaderOnIndexJsAtom,
  currentUsernameAtom,
} from '../../components/recoil/atoms/atoms.js'
import { msalPolicyNameAtom } from '../../components/recoil/atoms/atomsWithLocalStorage'
import TextBox from '../../components/base/textBox/TextBox.js'
import usernameIcon from '../../../assets/static/usernameIcon.svg'
import SignInImage from '../../../assets/images/signInImg.svg'
import MartsonLogo from '../../../assets/static/marstongroup.svg'
import './SignIn.css'

function SignInPage() {
  const loggingOut = useRecoilValue(loggingOutAtom)
  const setMsalPolicyName = useSetRecoilState(msalPolicyNameAtom)
  const setLoaderOnIndexJs = useSetRecoilState(LoaderOnIndexJsAtom)
  const [username, setUsername] = useState('')
  const setCurrentUsername = useSetRecoilState(currentUsernameAtom)
  useEffect(() => {
    setMsalPolicyName(null)
    setLoaderOnIndexJs(true)
  }, [])
  function loginRedirect() {
    setCurrentUsername(username)
  }

  const onChangeHandler = (event, params) => {
    const { id, value } = params
    if (id?.toString()?.toLowerCase() === 'username') {
      setUsername(value)
    }
  }
  return !loggingOut ? (
    <div
      className="signInPage"
      style={{ backgroundImage: `url(${SignInImage})` }}
      data-testid="signInPage"
    >
      <div className="signInContainer">
        <img
          src={MartsonLogo}
          alt="martsonLogo"
          className="martsonLogo"
          data-testid="martsonLogo"
        />
        <div className="signInSection">
          <div className="usernameTextBoxDiv">
            <TextBox
              id="txt_username"
              field={{
                fieldLabel: '',
                fieldValue: 'username',
                className: `colWiseControl`,
                disabled: false,
                placeholder: 'Enter username or email',
                inputAdornmentObj: {
                  showAdornmentValue: true,
                  inputAdornmentValue: (
                    <ReactSVG
                      className="reactSvgDiv usernameIcon"
                      src={usernameIcon}
                    />
                  ),
                },
              }}
              required={true}
              dataTestId="username"
              value={username}
              onChangeHandler={onChangeHandler}
              onBlurHandler={() => {}}
            />
          </div>

          <div
            className={`signInBtn ${
              utils?.isValidEmailAddress(username) ? 'enabled' : 'disabled'
            }`}
            data-testid="signInBtn"
            onClick={() => loginRedirect()}
          >
            <p className="">Sign in</p>
          </div>
        </div>

        <div className="signInFooter">
          <p className="signInFooterText">Powered by Marston Holdings</p>
        </div>
      </div>
    </div>
  ) : null
  // deleting LoadingComponent - due to a loader will be always shown from index.js till reaching any valid page render.
}
export default SignInPage
