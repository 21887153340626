/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/*
this page used to let the user to sign in the page or App.js when already signed-in through SSO
 */
/* istanbul ignore file */
// System defined variables
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
// Custom defined variables
import {
  showSplashScreen,
  axiosConfiguration,
  msalConfigAtom,
  gotInitialSessionResponseOfAuthenticationAtom,
  currentUsernameAtom,
} from '../../components/recoil/atoms/atoms'
import { msalPolicyNameAtom } from '../../components/recoil/atoms/atomsWithLocalStorage'
import { authenticationData } from '../../components/recoil/atoms/atomsWithLocalStorage'
import SplashScreen from '../splashScreen/SplashScreen'
import SignInPage from './SignInPage.js'
import { PublicClientApplication } from '@azure/msal-browser'
import GetUserFlow from '../../../msalProviderScreen/GetUserFlow.js'

const SignInBridge = ({ appConfig, msalConfig }) => {
  const setMsalConfigData = useSetRecoilState(msalConfigAtom)
  const [authenticationResult, setAuthenticationResult] =
    useRecoilState(authenticationData)
  const [msalPolicyName, setMsalPolicyName] = useRecoilState(msalPolicyNameAtom)
  const setGotInitialSessionResponseOfAuthentication = useSetRecoilState(
    gotInitialSessionResponseOfAuthenticationAtom
  )
  const enableSplashScreen = useRecoilValue(showSplashScreen)
  const setAxiosConfig = useSetRecoilState(axiosConfiguration)
  const [currentUsername, setCurrentUsername] =
    useRecoilState(currentUsernameAtom)
  const [invalidateAuthentication, setInvalidateAuthentication] =
    useState(false)
  const [pageLoadedFromCache, setPageLoadedFromCache] = useState(false)
  const [msalInstanceState, setMsalInstanceState] = useState(null)

  useEffect(() => {
    setMsalConfigData({
      ...msalConfig,
    })
  }, [msalConfig])
  useEffect(() => {
    // this is to wait for 10 seconds to get the response- coding this to test only in branch
    setTimeout(() => {
      setInvalidateAuthentication((prev) => !prev)
    }, 3000)
    setAxiosConfig({ ...appConfig })
  }, [])

  useEffect(() => {
    if (msalPolicyName) {
      const devPolicyName = msalPolicyName
      const msalInstance = new PublicClientApplication({
        ...msalConfig,
        auth: {
          ...msalConfig?.auth,
          authority: `${msalConfig?.auth?.authority}/${devPolicyName}`,
          knownAuthorities: [`${msalConfig?.auth?.authority}/${devPolicyName}`],
          signInPolicy: `${devPolicyName}`,
        },
      })
      msalInstance.handleRedirectPromise().then((response) => {
        // once loggedin / logged out -> msal return promise here with authenticate data

        if (response !== null) {
          // setting showSplashScreen to true at initial session and when login successfull
          setAuthenticationResult(response)
          setGotInitialSessionResponseOfAuthentication(true)
          // need to finalize the splashscreen
          // setShowSplashScreen(false)
        }
      })

      setMsalInstanceState(msalInstance)
    } else {
      // purposely checking for null - because its default value is empty. Which resets to empty when logout. But this has to be executed when forcefully making it null.
      if (msalPolicyName === null) setMsalInstanceState(null)
      setMsalPolicyName('')
    }
  }, [msalPolicyName])

  const getUsernameFromExistingAuthentication = () => {
    let existingUsername = ''
    if (authenticationResult) {
      if (authenticationResult?.account?.username) {
        existingUsername = authenticationResult?.account?.username
      } else if (authenticationResult?.account?.idTokenClaims?.emails) {
        // for local user-flow it is configured as emails
        if (
          Array.isArray(authenticationResult?.account?.idTokenClaims?.emails) &&
          authenticationResult?.account?.idTokenClaims?.emails?.length > 0
        ) {
          existingUsername =
            authenticationResult?.account?.idTokenClaims?.emails[0]
        } else {
          existingUsername =
            authenticationResult?.account?.idTokenClaims?.emails
        }
      } else if (authenticationResult?.account?.idTokenClaims?.email) {
        // for ad user-flow it is configured as email
        if (
          Array.isArray(authenticationResult?.account?.idTokenClaims?.email) &&
          authenticationResult?.account?.idTokenClaims?.email?.length > 0
        ) {
          existingUsername =
            authenticationResult?.account?.idTokenClaims?.email[0]
        } else {
          existingUsername = authenticationResult?.account?.idTokenClaims?.email
        }
      }
    }
    return existingUsername
  }

  // adding this pageshow event to handle on navigation to application with back/forward cache which holds the state variables data as it is when the component modified it last.
  // here the invalidateAuthentication/pageLoadFromCache is set to its opposite when the page is loaded from cache. So, it will re-render the page to get the updated data.
  // If the page is not loaded from cache then usual flow - initially invalidateAuthentication is false and will be true after timeout
  window.addEventListener('pageshow', (event) => {
    if (event?.persisted) {
      setCurrentUsername('')
      setInvalidateAuthentication((prev) => !prev) // this will remain true when gets from cache at initial
      setPageLoadedFromCache((prev) => !prev) // this will remain true when gets from cache at initial
      // once this update is done then will be re-renders the page
    }
  })
  return (
    (invalidateAuthentication || pageLoadedFromCache) && (
      <>
        {enableSplashScreen ? <SplashScreen /> : null}

        {getUsernameFromExistingAuthentication() || currentUsername ? (
          <GetUserFlow
            msalInstance={msalInstanceState}
            userName={
              authenticationResult?.account?.username || currentUsername
            }
          />
        ) : (
          <SignInPage msalInstance={msalInstanceState} />
        )}
      </>
    )
  )
}
export default SignInBridge
