/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/* istanbul ignore file */
import React, { useEffect, useState } from 'react'
import imageIcon400 from '../../../../src/assets/images/imageicon400.svg'
import imageIcon404 from '../../../../src/assets/images/imageicon404.svg'
import imageIcon500 from '../../../../src/assets/images/imageicon500.svg'
import imageIcon503 from '../../../../src/assets/images/imageicon503.svg'
import imageIcon504 from '../../../../src/assets/images/imageicon504.svg'
import imageSomethingWentWrong from '../../../../src/assets/images/somethingWentWrong.svg'
import waveDesignFooter from '../../../../src/assets/static/wavedesignfooter.svg'
import Button from '../../components/base/button/Button'
import {
  axiosConfiguration,
  selectedContractDetails,
  LoaderOnIndexJsAtom,
  showLoading,
} from '../../components/recoil/atoms/atoms'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import './ResponseErrorCode.css'

const ResponseErrorCode = ({ responseErrorCode }) => {
  const configVariable = useRecoilValue(axiosConfiguration)
  const setSelectedContractDetails = useSetRecoilState(selectedContractDetails)
  const setLoaderOnIndexJs = useSetRecoilState(LoaderOnIndexJsAtom)
  const setShowLoading = useSetRecoilState(showLoading)

  const [responseMessage, setResponseMessage] = useState({})
  let objResponseMessage = {}

  let errorCode = responseErrorCode

  if (errorCode === 404) {
    objResponseMessage = {
      errorCode: '404',
      errorCodeDescription: 'Page Not Found',
      errorDescription:
        'The resource requested could not be found on this server!',
      errorImage: imageIcon404,
    }
  } else if (errorCode === 504) {
    objResponseMessage = {
      errorCode: '504',
      errorCodeDescription: 'Gateway Timeout',
      errorDescription:
        'Gateway timeout occurred. The server is unreachable. Retry the request',
      errorImage: imageIcon504,
    }
  } else if (errorCode === 503) {
    objResponseMessage = {
      errorCode: '503',
      errorCodeDescription: 'Service Unavailable',
      errorDescription:
        'The service you requested in not available at this time. Try again later',
      errorImage: imageIcon503,
    }
  } else if (errorCode === 500) {
    objResponseMessage = {
      errorCode: '500',
      errorCodeDescription: 'Internal Server Error',
      errorDescription:
        'The server encountered an internal error or misconfiguration and was unable to complete your request.',
      errorImage: imageIcon500,
    }
  } else if (errorCode === 400) {
    objResponseMessage = {
      errorCode: '400',
      errorCodeDescription: 'Bad Request Error',
      errorDescription:
        'You have issued a malformed or illegal request. This feature is not applicable in this environment.',
      errorImage: imageIcon400,
    }
  } else {
    objResponseMessage = {
      errorCode: 'other',
      errorCodeDescription: 'Something went wrong',
      errorDescription:
        'The application has encountered an unknown error. The technical team have been automatically notified.',
      errorImage: imageSomethingWentWrong,
    }
  }

  useEffect(() => {
    setResponseMessage(() => objResponseMessage)
  }, [responseErrorCode])

  useEffect(() => {
    // hiding loader from index.js when reached userPermissionError page
    setLoaderOnIndexJs(false)
    // reset the loader, when got error code at using custom loader for any api
    setShowLoading(false)
  }, [])

  function backToHome() {
    setSelectedContractDetails('')
    localStorage.setItem('selectedsubmenu', '')
    window.location.href = configVariable?.VariableSettings?.BackOfficeUIURL
    removeCss()
  }
  function removeCss() {
    if (
      document.querySelectorAll('.bodypage')[0] &&
      document.querySelectorAll('.titleBar-root')[0]
    ) {
      document.querySelectorAll('.bodypage')[0].style.removeProperty('display')
      document
        .querySelectorAll('.drawer .MuiDrawer-paper')[0]
        .style.removeProperty('display')
      document
        .querySelectorAll('.titleBar-root')[0]
        .style.removeProperty('display')
    }
  }

  let renderComponent = (
    <div className="responsePageNotFound">
      <div className="responsePageNotFound__pageImageGroup">
        <div className="pageNotFound__marstonGroupImage">
          {/* <img className="marstonGroupImage" src={marstonGroup} /> */}
        </div>
        {responseMessage && responseMessage?.errorImage && (
          <>
            <div className="divErrorImage">
              <img className="Image404" src={responseMessage?.errorImage} />
            </div>
            <div className="divErrorCodeDescription">
              {responseMessage?.errorCodeDescription}
            </div>
            <div className="divErrorDescription">
              {responseMessage?.errorDescription}
            </div>
            <div className="divBackButton">
              {' '}
              <Button
                Field={{
                  fieldValue: 'Backtohome',
                  fieldLabel: 'Back to home',
                  variant: 'primary',
                }}
                className="saveButton"
                onClickHandler={() => {
                  backToHome()
                }}
              />
            </div>
          </>
        )}
      </div>

      <div className="responsePageNotFound__footerImage ">
        <img className="footerImage" src={waveDesignFooter} />
      </div>
    </div>
  )

  return renderComponent
}

export default ResponseErrorCode
