/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/* this page used for company log and search component and user details and log option showing */
// System defined variables
import React, { useState, forwardRef, useEffect } from 'react'
import { useSetRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import LogoutIcon from '@mui/icons-material/Logout'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { DialogActions } from '@mui/material'
import { DialogTitle } from '@material-ui/core'
import { useLocation, useNavigate } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
// Custom defined variables
import { ConfirmPopupRendererObject } from '../../components/custom/confirmPopupRenderer/ConfirmPopupRenderer'
//commented this due to difference in image with import and assets and used assets image
//import LogoutIcon from '@mui/icons-material/Logout'
import AvatarIcon from '../../components/base/avataricon/AvatarIcon'
import SearchBar from '../../components/custom/searchBar/SearchBar'
import nPSLogo from '../../../assets/static/Marston_NPS.svg'
import companyLogo from '../../../assets/static/companyLogo.svg'
import {
  NotificationDetails,
  axiosConfiguration,
  selectedContractDetails,
  mappedUserRoles,
  madeChanges,
  penalityChargeNoticeDetail,
  showSplashScreen,
  prefixSetupData,
  contractSetupData,
  contraventionSetupData,
  selectedCaseDetails,
  selectCaseDetailNavigationDetails,
  orgSetupData,
  contactAddressData,
  selectedMenuData,
  selectOrgNavigationDetails,
  faqSetupData,
  templateData,
  currencyAtom,
  runtimeTokenExpiredAtom,
  selectedContactAddress,
  tribunalAppealData,
  userDetail,
  userWentInactiveAtom,
  loggingOutAtom,
  isSidebarExpanded,
} from '../../components/recoil/atoms/atoms'
import {
  authenticationData,
  msalPolicyNameAtom,
} from '../../components/recoil/atoms/atomsWithLocalStorage'
import BellNotification from '../../components/base/bellNotification/BellNotification'
import TitleBarUser from '../../../assets/images/TitleBarUser.svg'
import Button from '../../components/base/button/Button'
import utils from '../../../utils/utils'
import './TitleBar.css'

function TitleBar({ msalInstance }) {
  const setNotifyDetails = useSetRecoilState(NotificationDetails)
  const [dataLogoutWarning, setDataLogoutWarning] = useState(false)
  const AuthenticationResult = useRecoilValue(authenticationData)
  const msalPolicyName = useRecoilValue(msalPolicyNameAtom)
  const config = useRecoilValue(axiosConfiguration)
  const userRolesData = useRecoilValue(mappedUserRoles)
  const hasChanges = useRecoilValue(madeChanges)
  const userData = useRecoilValue(userDetail)
  const location = useLocation()
  const navigate = useNavigate()
  const confirmAlert = useConfirm()
  const NotificationRecords = []
  let inHomePage = false
  if (location.pathname === '/') inHomePage = true
  useEffect(() => {
    setNotifyDetails(NotificationRecords)
  }, [])

  const resetpenalityChargeNoticeDetail = useResetRecoilState(
    penalityChargeNoticeDetail
  )
  const resetAuthenticationData = useResetRecoilState(authenticationData)
  const resetShowSplashScreen = useResetRecoilState(showSplashScreen)
  const resetPrefixSetupData = useResetRecoilState(prefixSetupData)
  const resetcontractSetupData = useResetRecoilState(contractSetupData)

  const resetcontraventionSetupData = useResetRecoilState(
    contraventionSetupData
  )
  const resetSelectedCaseDetails = useResetRecoilState(selectedCaseDetails)
  const resetSelectCaseDetailNavigationDetails = useResetRecoilState(
    selectCaseDetailNavigationDetails
  )
  const resetSelectedContractDetails = useResetRecoilState(
    selectedContractDetails
  )
  const resetOrgSetupData = useResetRecoilState(orgSetupData)

  const resetContactAddressData = useResetRecoilState(contactAddressData)
  // const resetUserTokenDetails = useResetRecoilState(userTokenDetails)
  const resetSelectedMenuData = useResetRecoilState(selectedMenuData)
  const resetSelectOrgNavigationDetails = useResetRecoilState(
    selectOrgNavigationDetails
  )
  // const resetMappedUserRoles = useResetRecoilState(mappedUserRoles)
  // const resetAssignedContractRoles = useResetRecoilState(assignedContractRoles)
  // const resetAssignedDistinctRoles = useResetRecoilState(assignedDistinctRoles)
  const resetFaqSetupData = useResetRecoilState(faqSetupData)
  const resetTemplateData = useResetRecoilState(templateData)
  const resetSelectedContactAddress = useResetRecoilState(
    selectedContactAddress
  )
  const resetTribunalAppealData = useResetRecoilState(tribunalAppealData)
  const resetCurrencyAtom = useResetRecoilState(currencyAtom)
  const resetRuntimeTokenExpiredAtom = useResetRecoilState(
    runtimeTokenExpiredAtom
  )
  const resetUserWentInactiveAtom = useResetRecoilState(userWentInactiveAtom)
  const setLoggingOut = useSetRecoilState(loggingOutAtom)
  const setSidebarExpanded = useSetRecoilState(isSidebarExpanded)
  // reset only persisted atoms here
  function resetAllAtom() {
    resetpenalityChargeNoticeDetail()
    resetAuthenticationData()
    resetShowSplashScreen()
    resetPrefixSetupData()
    resetcontractSetupData()
    resetcontraventionSetupData()
    resetSelectedCaseDetails()
    resetSelectCaseDetailNavigationDetails()
    resetSelectedContractDetails()
    resetSelectedContractDetails()
    resetOrgSetupData()
    resetContactAddressData()
    // resetUserTokenDetails()
    resetSelectedMenuData()
    resetSelectOrgNavigationDetails()
    // resetMappedUserRoles()
    // resetMappedUserRoles() //notfoundissue
    // resetAssignedContractRoles()
    // resetAssignedDistinctRoles()
    resetFaqSetupData()
    resetTemplateData()
    resetSelectedContactAddress()
    resetTribunalAppealData()
    resetCurrencyAtom()
    resetRuntimeTokenExpiredAtom()
    resetUserWentInactiveAtom()
    setSidebarExpanded()
  }

  const [userLabel, setUserLabel] = useState({})

  // Logged User Profile update
  useEffect(() => {
    const nameStringArray = userData?.userName?.toString()?.split(/ (.*)/s, 2)
    setUserLabel(() => {
      return {
        firstName: nameStringArray[0],
        lastName: nameStringArray[1],
      }
    })
  }, [userData])

  // function to get the first letters of logged in user name
  /* istanbul ignore next */

  //used usememo to get the avatarIconText only on logged in user data change
  // const avatarIconText = useMemo(() => {
  //   return getAvatarIconText()
  // }, [authenticationData])

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    !anchorEl ? setAnchorEl(event.currentTarget) : setAnchorEl(null)
  }

  //confirmation pop open when click LogOut
  function onLogoutClickHandler() {
    setDataLogoutWarning(true)
  }
  const onViewAccount = () => {
    const url = 'https://myaccount.microsoft.com/?ref=MeControl'
    window.open(url, '_blank')
  }

  //when signOut click AD account signOut functionality
  const signOut = () => {
    // reset only persisted atoms here
    setLoggingOut(true)
    resetAllAtom()

    utils?.signOutTheUser(
      msalInstance,
      config,
      AuthenticationResult,
      msalPolicyName
    )
  }

  // Ignoring this due to usage of using MuiUseConfirm which return promiseError on testing
  /* istanbul ignore next */
  const checkAllowNavigation = async () => {
    let returnValue = true
    returnValue = await confirmAlert({
      ...ConfirmPopupRendererObject(null, utils?.dataSaveWarnMessage),
    })
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })

    return returnValue
  }
  const onClickLogoHandler = async () => {
    // Ignoring this due to usage of using MuiUseConfirm which return promiseError on testing
    /* istanbul ignore next */
    if (hasChanges) {
      if (await checkAllowNavigation()) {
        navigate('/')
      }
    } else navigate('/')
  }

  return (
    <div className={`titleBar-root ${inHomePage ? '' : 'removeLeftPadding'}`}>
      <div
        className={`titleBar-title ${inHomePage ? '' : 'companyLogoDiv'}`}
        data-testid={`${inHomePage ? '' : 'companyLogoDiv'}`}
        onClick={inHomePage ? () => {} : onClickLogoHandler}
      >
        {inHomePage ? (
          <img
            data-testid="marstonLogo"
            className="img-logo"
            src={nPSLogo}
            loading="lazy"
          />
        ) : (
          <img
            data-testid="marstonLogo"
            className="img-logo"
            src={companyLogo}
            loading="lazy"
          />
        )}
      </div>
      <div className={`titleBar-Search`}>
        {userRolesData?.filter(
          (roleName) =>
            roleName?.toString()?.toLowerCase() === 'superadmin' ||
            roleName?.toString()?.toLowerCase() === 'bomanager' ||
            roleName?.toString()?.toLowerCase() === 'qcbouser' ||
            roleName?.toString()?.toLowerCase() === 'bouser' ||
            roleName?.toString()?.toLowerCase() === 'noticeprocessing' ||
            roleName?.toString()?.toLowerCase() === 'client' ||
            roleName?.toString()?.toLowerCase() === 'readonly' ||
            roleName?.toString()?.toLowerCase() === 'clientreadonly' ||
            roleName?.toString()?.toLowerCase() === 'ceomanager' ||
            roleName?.toString()?.toLowerCase() === 'poundoperator' ||
            roleName?.toString()?.toLowerCase() === 'poundmanager'
        )?.length > 0 ? (
          <SearchBar />
        ) : null}
      </div>
      <div className="userHeader">
        <div className="titleBar-icon">
          <div className="notificationDiv">
            <BellNotification />
          </div>

          <div className="titleBarUser-avatar">
            {Object?.keys(userLabel)?.length > 0 ? (
              <>
                <AvatarIcon
                  className="Main-Avatar"
                  altText={
                    userLabel?.firstName[0]?.toString()?.toUpperCase() +
                      userLabel?.lastName[0]?.toString()?.toUpperCase() || '  '
                  }
                  children={
                    userLabel?.firstName[0]?.toString()?.toUpperCase() +
                      userLabel?.lastName[0]?.toString()?.toUpperCase() || '  '
                  }
                />
                <div
                  className="usernamesection"
                  title={userLabel?.firstName + ' ' + userLabel?.lastName || ''}
                >
                  {userLabel?.firstName + ' ' + userLabel?.lastName || ''}
                </div>
              </>
            ) : null}
            <div
              onClick={handleClick}
              className="titleBarUserDropDownDiv"
              data-testid="test_userIconDropDown"
            >
              <img className="titleBarUserDropDown" src={TitleBarUser} />
            </div>
            <Menu
              anchorEl={anchorEl}
              id="userDropDownMenu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              autoFocus={false}
              transitionDuration={500}
            >
              <div onClick={onViewAccount}>
                <MenuItem onClick={handleClose}>
                  <VisibilityIcon className="titleBarUser" />
                  <div className="innerDiv">View Account</div>
                </MenuItem>
              </div>
              <div onClick={onLogoutClickHandler}>
                <MenuItem>
                  <LogoutIcon className="titleBarUser" />
                  <div className="innerDiv">Logout</div>
                </MenuItem>
              </div>
            </Menu>
          </div>
        </div>
      </div>
      {/* when LogOut click confirmation popup open */}
      <Dialog
        id="dialog_dataLogOutWarning"
        open={dataLogoutWarning}
        className="dataLogOutWarning"
      >
        <DialogTitle>Logout</DialogTitle>
        <DialogContent>
          <div className="logoutString">
            <div>Are you sure want to logout?</div>
            <div>It&apos;s a good idea to close all browser windows.</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            Field={{
              fieldValue: 'No',
              fieldLabel: 'No',
              variant: 'secondary',
            }}
            className="saveButton"
            onClickHandler={() => {
              setDataLogoutWarning(false)
            }}
          />
          <Button
            Field={{
              fieldValue: 'Yes',
              fieldLabel: 'Yes',
              variant: 'primary',
            }}
            className="saveButton"
            onClickHandler={() => {
              signOut()
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default forwardRef(TitleBar)
