/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
// ignoring due to reverting b2c
/* istanbul ignore file */
// System defined variables
import React, { useEffect } from 'react'
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil' //useResetRecoilState

// Custom defined variables
import waveDesignFooter from '../../../../src/assets/static/wavedesignfooter.svg'
import marstonGroup from '../../../../src/assets/static/marstongroup.svg'
import inActiveLogo from '../../../../src/assets/static//inactivelogo.svg'
import Button from '../../components/base/button/Button'
import {
  LoaderOnIndexJsAtom,
  runtimeTokenExpiredAtom,
  loggingOutAtom,
  currentUsernameAtom,
  axiosConfiguration,
} from '../../components/recoil/atoms/atoms'
import {
  authenticationData,
  msalPolicyNameAtom,
} from '../../components/recoil/atoms/atomsWithLocalStorage'
import utils from '../../../utils/utils'
import './UserPermission.css'

const UserPermission = ({ accountPermissionHint, msalInstance }) => {
  const authenticationResult = useRecoilValue(authenticationData)
  const msalPolicyName = useRecoilValue(msalPolicyNameAtom)
  const config = useRecoilValue(axiosConfiguration)
  const resetAuthenticatedUserData = useResetRecoilState(authenticationData)
  const resetRuntimeTokenExpired = useResetRecoilState(runtimeTokenExpiredAtom)
  const setLoaderOnIndexJs = useSetRecoilState(LoaderOnIndexJsAtom)
  const setLoggingOut = useSetRecoilState(loggingOutAtom)
  const setCurrentUsername = useSetRecoilState(currentUsernameAtom)

  function backToHome() {
    setLoggingOut(true)
    resetAuthenticatedUserData()
    setCurrentUsername('')
    resetRuntimeTokenExpired()
    utils?.signOutTheUser(
      msalInstance,
      config,
      authenticationResult,
      msalPolicyName
    )
  }
  useEffect(() => {
    // hiding loader from index.js when reached userPermissionError page
    setLoaderOnIndexJs(false)
  }, [])

  let renderComponent = (
    <div className="userPermission">
      <div className="userPermission__pageImageGroup">
        <div className="userPermission__marstonGroupImage">
          <img className="marstonGroupImage" src={marstonGroup} />
        </div>
        <div className="userPermission__image404">
          <img className="imageavator" src={inActiveLogo} />
        </div>
        <div className="userPermission__userPermissioninsuffstring">
          {accountPermissionHint}
        </div>
        <div className="userPermission__userPermissionaccount">
          {utils?.userPermissionMessage?.accountPermissionAction}
        </div>
        <div className="userPermission__backButton">
          {' '}
          <Button
            Field={{
              fieldValue: 'Return to login',
              fieldLabel: 'Return to login',
              variant: 'primary',
            }}
            className="saveButton"
            onClickHandler={() => backToHome()}
            dataTestId={'returnToLoginButton'}
          />
        </div>
      </div>

      <div className="userPermission__footerImage">
        <img className="footerImage" src={waveDesignFooter} />
      </div>
    </div>
  )
  return renderComponent
}

export default UserPermission
